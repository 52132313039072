import {Evaluation} from './Evaluation';
import {User} from './User';
import {SentenceConcept} from './SentenceConcept';
import {IEvaluationReviewDto} from '../dtos';

export class EvaluationReview {
    public id?: number;
    public evaluation?: Evaluation;
    public evaluationId?: number;
    public reviewer?: User;
    public reviewerId?: number;
    public quality?: number;
    public sentenceConcepts?: SentenceConcept[];

    public static fromDto = (d: IEvaluationReviewDto): EvaluationReview => {
        const er = new EvaluationReview();
        er.id = d.id;
        er.evaluation = d.evaluation ? Evaluation.fromDto(d.evaluation) : undefined;
        er.evaluationId = d.evaluation ? d.evaluation.id : d.evaluationId;
        er.quality = d.quality;
        er.reviewer = d.reviewer ? User.fromDto(d.reviewer) : undefined;
        er.reviewerId = d.reviewer ? d.reviewer.id : d.reviewerId;
        er.sentenceConcepts = d.sentenceConcepts ? d.sentenceConcepts.map(SentenceConcept.fromDto) : undefined;
        return er;
    };

    public toDto(): IEvaluationReviewDto {
        return {
            id: this.id,
            quality: this.quality,
            evaluationId: this.evaluation ? this.evaluation.id : this.evaluationId,
            evaluation: this.evaluation ? this.evaluation.toDto() : undefined,
            reviewer: this.reviewer ? this.reviewer.toDto() : undefined,
            reviewerId: this.reviewer ? this.reviewer.id : this.reviewerId,
            sentenceConcepts: this.sentenceConcepts ? this.sentenceConcepts.map(x => x.toDto()) : undefined,
        };
    }
}
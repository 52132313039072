import {UserProject} from '../../model/UserProject';
import {User} from '../../model';
import { Student } from '../../model/Student';
import {Team} from '../../model/Team';

export class StudentInfo {
    // public name: string = 'Name';
    // public login: string = 'Login';
    // public email: string = 'email@email.email';
    // public password: string = '****';
    public userProject: UserProject;

    public get user(): User {
        return this.userProject.user!;
    }

    public get student(): Student {
        return this.user.student!;
    }

    public get team(): Team | undefined {
        const tss = this.student.teamStudents;
        if (!tss || tss.length < 1) {
            return undefined;
        }
        return tss[0].team!;
    }

    public get teamName(): string {
        const t = this.team;
        return t ? t.name : '';
    }

    public constructor(userProject: UserProject) {
        this.userProject = userProject;
    }
}
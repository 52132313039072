import * as React from 'react';
import {Checkbox, createStyles, FormControlLabel, makeStyles, Theme} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {BooleanFieldStore} from './booleanFieldStore';

export interface ICheckboxFieldViewProps {
    field: BooleanFieldStore;
}

const useStyles = makeStyles((_: Theme) => createStyles({
    checkBox: {

    }
}));

export const CheckboxFieldView: React.FC<ICheckboxFieldViewProps> = observer(props => {
    const classes = useStyles(props);

    const {field} = props;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        field.setValue(e.target.checked);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    className={classes.checkBox}
                    checked={field.value}
                    onChange={onChange}
                    value={field.label}
                    disabled={field.disabled}
                />
            }
            label={field.label}
        />

    );
});
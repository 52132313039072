import * as React from 'react';
import {createStyles, makeStyles, Theme, useMediaQuery} from '@material-ui/core';
import {observer} from 'mobx-react';
import { AnnotationPalette } from '../../common';

export interface IConceptsLegendProps {
    concepts: string[];
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: theme.spacing(1)
    },
    rootMobile: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(1)
    },
    concept: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: theme.spacing(2),
    },
    conceptIcon: {
        marginRight: theme.spacing(1),
        height: 20,
        width: 20,
    },
    grid: {}
}));

export const ConceptsLegend: React.FC<IConceptsLegendProps> = observer(props => {
    const {concepts} = props;
    const classes = useStyles(props);

    const isMobile = !useMediaQuery('(min-width:1000px)');
    const className = isMobile ? classes.rootMobile : classes.root;

    const renderConcept = (concept: string, conceptIndex: number) => {
        return (
            <div key={`Concept${conceptIndex}`} className={classes.concept}>
                <div
                    className={classes.conceptIcon}
                    style={AnnotationPalette.getButtonStyle(conceptIndex)}
                />
                <div>{concept}</div>
            </div>
        );
    };

    return (
        <div className={className}>
            {concepts.map((concept, conceptIndex) => renderConcept(concept, conceptIndex))}
        </div>
    );
});
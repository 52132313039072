import {Student} from './Student';
import {IEvaluationDto} from '../dtos';
import {Project} from './Project';
import {Sentence} from './Sentence';
import {EvaluationReview} from './EvaluationReview';
import { tokenize } from '../common';

export class Evaluation {
    public id?: number;
    public evaluator?: Student;
    public evaluatorId?: number;
    public evaluated?: Student;
    public evaluatedId?: number;
    public grade?: number;
    public time?: Date;
    public project?: Project;
    public projectId?: number;
    public sentences?: Sentence[];
    public evaluationReviews?: EvaluationReview[];

    public get justificationText(): string {
        return this.sentences ? this.sentences.map(s => s.text).join(' ') : '';
    }

    public setJustificationText = (text: string) => {
        if (!text || text === '') {
            this.sentences = undefined;
        } else {
            const sentenceTexts = tokenize(text);
            this.sentences = sentenceTexts.map((txt, i) => {
                const s = new Sentence();
                s.text = txt;
                s.position = i;
                s.evaluationId = this.id;
                s.evaluation = this;
                return s;
            });
        }
    };

    public static fromDto = (d: IEvaluationDto): Evaluation => {
        const e = new Evaluation();
        e.id = d.id;
        e.evaluator = d.evaluator ? Student.fromDto(d.evaluator) : undefined;
        e.evaluatorId = d.evaluator ? d.evaluator.id : d.evaluatorId;
        e.evaluated = d.evaluated ? Student.fromDto(d.evaluated) : undefined;
        e.evaluatedId = d.evaluated ? d.evaluated.id : d.evaluatedId;
        e.grade = d.grade;
        e.time = d.time;
        e.project = d.project ? Project.fromDto(d.project) : undefined;
        e.projectId = d.project ? d.project.id : d.projectId;
        e.sentences = d.sentences ? d.sentences.map(Sentence.fromDto) : undefined;
        e.evaluationReviews = d.evaluationReviews ? d.evaluationReviews.map(EvaluationReview.fromDto) : undefined;
        return e;
    };

    public toDto = (): IEvaluationDto => {
        return {
            id: this.id,
            evaluated: this.evaluated ? this.evaluated.toDto() : undefined,
            evaluatorId: this.evaluator ? this.evaluator.id : this.evaluatorId,
            evaluator: this.evaluator ? this.evaluator.toDto() : undefined,
            evaluatedId: this.evaluated ? this.evaluated.id : this.evaluatedId,
            grade: this.grade,
            time: this.time,
            project: this.project ? this.project.toDto() : undefined,
            projectId: this.project ? this.project.id : this.projectId,
            sentences: this.sentences ? this.sentences.map(s => s.toDto()) : undefined,
            evaluationReviews: this.evaluationReviews ? this.evaluationReviews.map(x => x.toDto()) : undefined,
        };
    };

    public get evaluatorLogin(): string {
        return this.evaluator?.user?.login || 'unknown';
    }

    public get evaluatedLogin(): string {
        return this.evaluated?.user?.login || 'unknown';
    }

    public shallowClone = (): Evaluation => {
        const c = new Evaluation();
        c.id = this.id;
        c.evaluator = this.evaluator;
        c.evaluatorId = this.evaluatorId;
        c.evaluated = this.evaluated;
        c.evaluatedId = this.evaluatedId;
        c.grade = this.grade;
        c.time = this.time;
        c.project = this.project;
        c.projectId = this.projectId;
        c.sentences = this.sentences;
        c.evaluationReviews = this.evaluationReviews;
        return c;
    }
}
import React from 'react';
import {AppBar, createStyles, makeStyles, Theme, Toolbar, Typography, Card, CardContent, Grid} from '@material-ui/core';
import {observer} from 'mobx-react';
import {UserAppBarButton} from '../auth/UserAppBarButton';
import {BackButton, CheckboxFieldView, Loading, SnackMessage} from '../../common';
import {useStore} from '../../storeProvider';

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        maxWidth: 800,
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    flex: {flex: 1},
    cardContentGrid: {}
}));

export interface IProjectResultsConfigScreenProps {

}

export const ProjectResultsConfigScreen: React.FC<IProjectResultsConfigScreenProps> = observer(props => {
    const classes = useStyles(props);
    const {projectResultsConfigScreenStore: store} = useStore();

    const onBack = () => {
        store.goBack();
    };

    return (
        <div>
            <AppBar position='static' color='primary'>
                <Toolbar>
                    <BackButton onClick={onBack}/>
                    <Typography variant='h6' color='inherit' className={classes.flex}>
                        Results Screen Config
                    </Typography>
                    <UserAppBarButton/>
                </Toolbar>
            </AppBar>
            <Card className={classes.card}>
                <CardContent>
                    <Grid className={classes.cardContentGrid} container spacing={2}>
                        <Grid item xs={12}>
                            <CheckboxFieldView field={store.showAbsoluteGrades}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <SnackMessage snackMessageStore={store.snack}/>
            <Loading isOpen={store.isBusy}/>
        </div>
    );
});
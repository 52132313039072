import React from 'react';
import {observer} from 'mobx-react';
import {DataTable} from '../../common';
import {useStore} from '../../storeProvider';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Paper} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
    tablePaper: {
    }
}));

export const StudentsTable: React.FC<{}> = observer(props => {
    const classes = useStyles(props);

    const {studentsScreenStore} = useStore();
    return (
        <Paper className={classes.tablePaper}>
            <DataTable store={studentsScreenStore.studentsTableStore}/>
        </Paper>
    );
});
import {action, computed, decorate, observable} from 'mobx';
import {Project} from '../../model/Project';
import {RootStore} from '../../RootStore';
import {HomeApi} from './HomeApi';
import {SnackMessageStore, SnackType} from '../../common';
import { ProjectCardStore } from './ProjectCardStore';

export class HomeScreenStore {
    private _projectCards = observable.array<ProjectCardStore>([]);
    private _isBusy = observable.box<boolean>(false);
    public readonly snackMessage: SnackMessageStore;

    constructor(public rootStore: RootStore, private readonly homeApi: HomeApi, snackMessage: SnackMessageStore) {
        this.snackMessage = snackMessage;
    }

    public get isBusy(): boolean {
        return this._isBusy.get();
    }

    public get projectCards(): ProjectCardStore[] {
        return this._projectCards;
    }

    public goToProject = (project: Project) => {
        this.rootStore.routerStore.push(`/project-editor/${project.id}`);
    };

    public goToResults = (project: Project) => {
        this.rootStore.routerStore.push(`/results/${project.id}`);
    };

    public goToMyResults = (project: Project) => {
        this.rootStore.routerStore.push(`/my-results/${project.id}`);
    };

    public goToStudents = (project: Project) => {
        this.rootStore.routerStore.push(`/students/${project.id}`);
    };

    public goToInstructors = (project: Project) => {
        this.rootStore.routerStore.push(`/instructors/${project.id}`);
    };

    public goToAnalytics = (project: Project) => {
        this.rootStore.routerStore.push(`/analytics/${project.id}`);
    };

    public goToEvaluation = (project: Project) => {
        this.rootStore.routerStore.push(`/evaluation/${project.id}`);
    };

    public fetchHomeData = async (): Promise<void> => {
        this._isBusy.set(true);
        try {
            const reply = await this.homeApi.fetchHomeData({});
            const projects = reply.projects;
            this._projectCards.replace(projects.map(ProjectCardStore.fromDto));
            this.snackMessage.open(`Projects loaded`, SnackType.Success);
        } catch (e) {
            this.snackMessage.open(e.message, SnackType.Error);
        } finally {
            this._isBusy.set(false);
        }
    };

    public createProject = () => {
        this.rootStore.projectEditorScreenStore.setId(undefined);
        this.rootStore.routerStore.push('/project-editor');
    }
}

decorate(HomeScreenStore, {
    projectCards: computed,
    goToResults: action,
    goToProject: action,
    goToStudents: action,
    goToInstructors: action,
    goToAnalytics: action,
    goToEvaluation: action,
    fetchHomeData: action,
    isBusy: computed,
    createProject: action,
    snackMessage: observable,
});
import {action, computed, decorate, observable} from 'mobx';
import {BooleanFieldStore} from '../fields/booleanFieldStore';

export interface IConfirmationOption {
    name: string;
    defaultValue: boolean;
}

export interface ConfirmationResult {
    isConfirmed: boolean,
    options?: boolean[],
}

export class ConfirmationDialogStore {
    private _isOpen = observable.box<boolean>(false);
    private _title = observable.box<string>('');
    private _contentText = observable.box<string>('');
    private _promise?: Promise<ConfirmationResult>;
    private _resolve?: (value: ConfirmationResult) => void;
    private _optionFields = observable.array<BooleanFieldStore>();

    public constructor(title: string, contentText: string, options?: IConfirmationOption[]) {
        this._title.set(title);
        this._contentText.set(contentText);
        if (options && options.length > 0) {
            const optionFields = options.map(x => {
                const f = new BooleanFieldStore(x.name, x.defaultValue);
                return f;
            });
            this._optionFields.replace(optionFields);
        }
    }

    public setTitle = (title: string) => {
        this._title.set(title);
    };

    public setContentText = (contentText: string) => {
        this._contentText.set(contentText);
    };

    public get title(): string {
        return this._title.get();
    }

    public get contentText(): string {
        return this._contentText.get();
    }

    public get isOpen(): boolean {
        return this._isOpen.get();
    }

    public get optionFields(): BooleanFieldStore[] {
        return this._optionFields;
    }

    public open = async (): Promise<ConfirmationResult> => {
        if (this.isOpen) {
            return this._promise!;
        }
        this._promise = new Promise((resolver) => {
            this._resolve = resolver;
        });
        this._isOpen.set(true);
        return this._promise;
    };

    public close = (reply?: boolean) => {
        if (!this.isOpen) {
            return;
        }
        this._resolve!({
            isConfirmed: !!reply,
            options: this._optionFields.length > 0 ? this._optionFields.map(f => f.value) : undefined,
        });
        this._resolve = undefined;
        this._promise = undefined;
        this._isOpen.set(false);
    };
}

decorate(ConfirmationDialogStore, {
    setTitle: action,
    setContentText: action,
    title: computed,
    contentText: computed,
    isOpen: computed,
    open: action,
    close: action,
    optionFields: computed,
});
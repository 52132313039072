import * as React from 'react';
import {Button, Card, CardActions, CardContent, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {RouteComponentProps} from 'react-router';
import {Loading} from '../../common';
import {TextFieldView} from '../../common';
import {useStore} from '../../storeProvider';

const canCreateAccount = true;

export interface IAuthRouteParams {
    token?: string;
}

export interface ILoginScreenProps extends RouteComponentProps<IAuthRouteParams> {
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default
    },
    card: {},
    errorMessage: {
        color: theme.palette.error.main
    },
    loginTextField: {},
    passwordTextField: {},
    title: {},
    createAccount: {
        marginLeft: 'auto'
    }
}));

export const LoginScreen: React.FC<ILoginScreenProps> = observer(props => {
    const classes = useStyles(props);
    const {loginScreenStore} = useStore();
    const {loginFormStore} = loginScreenStore;

    const onPasswordKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !e.shiftKey && !e.altKey && !e.ctrlKey) {
            loginFormStore.signIn();
        }
    };
    const onCreateAccount = () => loginFormStore.createAccount();
    const onSignIn = () => loginFormStore.signIn(); // TODO useMemo or useCallback

    // useEffect(() => {
    //     const base64Token = props.match.params.token;
    //     if (base64Token != null) {
    //         authStore.signInWithToken(base64Token);
    //     }
    // }, [props.match.params.token]);

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant='h5' className={classes.title}>
                        Sign in to Grademe
                    </Typography>
                    <TextFieldView
                        field={loginFormStore.username}
                        textFieldProps={{
                            fullWidth: true,
                            autoFocus: true,
                            variant: 'outlined',
                            margin: 'normal',
                            type: 'text',
                            name: 'username'
                        }}
                    />
                    <TextFieldView
                        textFieldProps={{
                            type: 'password',
                            name: 'password',
                            variant: 'outlined',
                            margin: 'normal',
                            fullWidth: true,
                            InputLabelProps: {
                                shrink: true
                            },
                            inputProps: {
                                onKeyDown: onPasswordKeyDown
                            }
                        }}
                        field={loginFormStore.password}
                    />
                    <Typography
                        data-automation='ErrorMessage'
                        className={classes.errorMessage}
                    >
                        {loginFormStore.errorMessage}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onSignIn}
                    >
                        Sign In
                    </Button>
                    {canCreateAccount ? <Button
                        className={classes.createAccount}
                        data-automation='CreateAccount'
                        color='secondary'
                        onClick={onCreateAccount}
                    >
                        Create new account
                    </Button> : null}
                </CardActions>
            </Card>
            <Loading isOpen={loginFormStore.isBusy}/>
        </div>
    );
});

import React, {useEffect} from 'react';
import {AppBar, createStyles, IconButton, makeStyles, Theme, Toolbar, Typography, Card, CardContent, Grid, CardActions, Button} from '@material-ui/core';
import {observer} from 'mobx-react';
import {UserAppBarButton} from '../auth/UserAppBarButton';
import {useStore} from '../../storeProvider';
import {SnackMessage, TextFieldView} from '../../common';
import {Loading, BackButton} from '../../common';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    flex: {
        flex: 1,
    },
    addProjectButton: {},
    card: {
        maxWidth: 800,
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    cardContentGrid: {}
}));

export interface IProfileProps {

}

export const ProfileScreen: React.FC<IProfileProps> = observer(props => {
    const classes = useStyles(props);
    const {profileScreenStore, authStore} = useStore();

    useEffect(() => {
        if (authStore.isRehydrated) {
            profileScreenStore.fetchScreenData();
        }
    }, [authStore.isRehydrated, profileScreenStore]);

    const onCancel = () => {
        profileScreenStore.cancel();
    }

    const onSave = () => {
        profileScreenStore.saveProfile();
    };

    // TODO create a common component for cards with fields
    return (
        <div className={classes.root}>
            <AppBar position='static' color='primary'>
                <Toolbar>
                    <BackButton/>
                    <Typography variant='h6' color='inherit' className={classes.flex}>
                        User Profile
                    </Typography>
                    <UserAppBarButton/>
                </Toolbar>
            </AppBar>
            <Card className={classes.card}>
                <CardContent>
                    <Grid className={classes.cardContentGrid} container spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldView
                                field={profileScreenStore.login}
                                textFieldProps={{fullWidth: true}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldView
                                field={profileScreenStore.email}
                                textFieldProps={{fullWidth: true}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldView
                                field={profileScreenStore.password}
                                textFieldProps={{fullWidth: true}}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onSave}
                    >
                        Save
                    </Button>
                </CardActions>
            </Card>
            <SnackMessage snackMessageStore={profileScreenStore.snack}/>
            <Loading isOpen={profileScreenStore.isBusy}/>
        </div>
    );
});
import {Concept, Evaluation} from '../../model';
import {action, computed, decorate, observable} from 'mobx';
import {AnnotationTextModel, ISentenceWithConcepts} from '../../common';
import {MyResultsScreenStore} from './MyResultsScreenStore';

export class MyResultCardStore {
    private _evaluation = observable.box<Evaluation>();
    public textModel: AnnotationTextModel;

    private readonly _grade = observable.box<number | undefined>();
    private readonly _normalisedGrade = observable.box<number | undefined>();
    private readonly _quality = observable.box<number | undefined>();

    public constructor(
        private readonly screenStore: MyResultsScreenStore,
        evaluation: Evaluation,
        normalisedGrade: number | undefined
    ) {
        this.textModel = new AnnotationTextModel();
        this.setEvaluation(evaluation, normalisedGrade);
    }

    public get evaluation(): Evaluation {
        return this._evaluation.get();
    }

    public get quality(): number | undefined {
        return this._quality.get();
    }

    public get grade(): number | undefined {
        return this._grade.get();
    }

    public get concepts(): Concept[] {
        return this.screenStore.concepts;
    }

    public setEvaluation = (evaluation: Evaluation, normalisedGrade: number | undefined) => {
        this._evaluation.set(evaluation);
        // this._grade.set(evaluation.grade);
        this._grade.set(normalisedGrade);
        const ers = evaluation.evaluationReviews;
        const q = ers && ers.length === 1 ? ers[0].quality : undefined;
        this._quality.set(q);
        if (evaluation.sentences) {
            const sentencesWithConcepts: ISentenceWithConcepts[] = evaluation.sentences.map(s => ({
                text: s.text!,
                concepts: s.sentenceConcepts && s.sentenceConcepts.map(sc => sc.concept!.name!)
            }));
            this.textModel.loadSentences(sentencesWithConcepts);
        } else {
            this.textModel.setSentences([]);
        }
    };
}

decorate(MyResultCardStore, {
    evaluation: computed,
    setEvaluation: action,
    textModel: observable,
    concepts: computed,
    quality: computed,
    grade: computed,
});
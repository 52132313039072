import React from 'react';
import {RootStore} from './RootStore';
import {syncHistoryWithStore} from 'mobx-react-router';
import {createBrowserHistory} from 'history';

const storeContext = React.createContext<RootStore | null>(null);
const rootStore = new RootStore();
export const history = syncHistoryWithStore(createBrowserHistory(), rootStore.routerStore);

export const StoreProvider: React.FC<{}> = ({children}) => {
    return (
        <storeContext.Provider value={rootStore}>
            {children}
        </storeContext.Provider>
    );
};

export const useStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        throw new Error('useStore must be used within a StoreProvider');
    }
    return store;
};
import {ResultsRowData} from './ResultsRowData';
import {DataTableStore, ICellComponentProps, IColumnDefinition, RowDefinition, SelectionMode} from '../../../common';
import {NameCellRenderer} from './NameCellRenderer';
import {EvaluationCellRenderer, IEvaluationCellProps} from './EvaluationCellRenderer';
import _ from 'lodash'
import { defaultEvaluationColumnCount } from '../constants';
import {mapBy} from '../../../shared';
import {calcGroupContributions} from '../gradesCalculation';

// n starts with 0
export const makeGradeColumn = (n: number): IColumnDefinition<ResultsRowData, IEvaluationCellProps> => {
    // This is important, has to be 'grade' + teammate index.
    const columnKey = `grade${n + 1}`;
    const getCellValue = (row: RowDefinition<ResultsRowData>) => {
        const e = row.data.evaluationCells[n]?.evaluation;
        return e && e.grade;
    };
    const sortFunction = (a: RowDefinition<ResultsRowData>, b: RowDefinition<ResultsRowData>) => {
        const ea = a.data.evaluationCells[n]?.evaluation;
        const eb = b.data.evaluationCells[n]?.evaluation;
        const ga = ea && ea.grade;
        const gb = eb && eb.grade;
        if (!ga) {
            if (gb) {
                return -1;
            }
            return 0;
        }
        if (!gb) {
            return 1;
        }
        return ga - gb;
    };
    const cellPropsBuilder = (props: ICellComponentProps<ResultsRowData>): IEvaluationCellProps => {
        const evaluationCells = props.row.data.evaluationCells;
        return {
            rowIndex: props.rowIndex,
            columnIndex: props.columnIndex,
            className: props.className,
            onClick: props.onClick,
            cellStore: evaluationCells && evaluationCells.length > n ? evaluationCells[n] : undefined,
        };
    };
    return {
        key: columnKey,
        header: `G ${n + 1}`,
        getCellValue,
        cellComponent: EvaluationCellRenderer,
        cellPropsBuilder,
        sortFunction,
        isFocusable: true,
    };
};



const sortByIndex = (a: RowDefinition<ResultsRowData>, b: RowDefinition<ResultsRowData>) => {
    return (a.data.index || 0) - (b.data.index || 0);
}

const sortByTeam = (a: RowDefinition<ResultsRowData>, b: RowDefinition<ResultsRowData>) => {
    const teamDiff = a.data.teamName.localeCompare(b.data.teamName);
    if (teamDiff !== 0) {
        return teamDiff;
    }
    return sortByIndex(a, b);
}

export class ResultsTableStore extends DataTableStore<ResultsRowData> {
    constructor(evaluationColumnCount: number = defaultEvaluationColumnCount) {
        super(ResultsTableStore.createColumns(evaluationColumnCount), SelectionMode.Cell);
        this.sort(this.columns[1].key); // Sort by Team by default
    }

    public rebuildColumns = (evaluationColumnCount: number = defaultEvaluationColumnCount) => {
        this.setColumns(ResultsTableStore.createColumns(evaluationColumnCount));
    }

    private static createColumns = (evaluationColumnCount: number): Array<IColumnDefinition<ResultsRowData>> => {
        return [{
            key: 'name',
            header: 'Name',
            getCellValue: (row) => row.data.user.name,
            sortFunction: (a, b) => a.data.user.name.localeCompare(b.data.user.name),
            cellComponent: NameCellRenderer,
        }, {
            key: 'team',
            header: 'Team',
            getCellValue: row => row.data.teamName,
            sortFunction: sortByTeam,
        }, {
            key: 'index',
            header: 'Index',
            getCellValue: row => {
                const i = row.data.index;
                if (i !== undefined) {
                    return String(i + 1);
                }
                return '';
            },
            sortFunction: sortByIndex,
        }, ..._.range(evaluationColumnCount).map(x => makeGradeColumn(x)), {
            key: 'contribution',
            header: 'Contribution',
            getCellValue: row => row.data.contribution.valueText,
            sortFunction: (a, b) => (a.data.contribution.value || 0) - (b.data.contribution.value || 0),
            isFocusable: true,
        }, {
            key: 'reviews',
            header: 'Reviews',
            getCellValue: row => row.data.bonusForEvaluations.valueText,
            sortFunction: (a, b) => (a.data.bonusForEvaluations.value || 0) - (b.data.bonusForEvaluations.value || 0),
            isFocusable: true,
        }, {
            key: 'consistency',
            header: 'Consistency',
            getCellValue: row => row.data.consistency.valueText,
            sortFunction: (a, b) => (a.data.consistency.value || 0) - (b.data.consistency.value || 0),
            isFocusable: true,
        }  //, {
            //     key: 'teamScore',
            //     header: 'Team Score',
            //     getCellValue: row => 'TODO',
            //     sortFunction: () => 0,
            //     isFocusable: true,
            // }, {
            //     key: 'individualScore',
            //     header: 'Individual Score',
            //     getCellValue: row => 'TODO',
            //     sortFunction: () => 0,
            //     isFocusable: true,
            // }
        ];
    }
}

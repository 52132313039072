import * as React from 'react';
import moment from 'moment';
import {Card, CardContent, CardHeader, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {MyResultCardStore} from './MyResultCardStore';
import {AnnotationTextArea} from '../../common';
import {ConceptsLegend} from './ConceptsLegend';

export interface IMyResultCardProps {
    store: MyResultCardStore;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        margin: theme.spacing(2, 1)
    },
    description: {
        maxHeight: theme.spacing(11),
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    status: {},
    actions: {
        flexWrap: 'wrap'
    },
    created: {}
}));

export const MyResultCard: React.FC<IMyResultCardProps> = observer(props => {
    const classes = useStyles(props);
    const {store} = props;
    const {grade, evaluation, quality, textModel} = store;
    const teammateName = 'Teammate';
    const concepts = store.concepts.map(x => x.name!);
    const time = moment(evaluation.time).format('DD-MMM-YY HH:mm:SS');
    return (
        <Card className={classes.card}>
            <CardHeader
                title={teammateName}
                subheader={time}
            />
            <CardContent>
                <Typography>{`Grade: ${grade !== undefined ? grade.toFixed(2) : 'Not evaluated'}`}</Typography>
                <Typography>{`Quality: ${quality !== undefined ? quality : 'Not reviewed'}`}</Typography>
                <Typography>Justification:</Typography>
                <AnnotationTextArea
                    annotationText={textModel}
                    concepts={concepts}
                    disabled={true}
                />
                {textModel.sentences.length > 0 ? <ConceptsLegend concepts={concepts}/> : null}
            </CardContent>
        </Card>
    );
});
import React, {useMemo} from 'react';
import {Checkbox, createStyles, makeStyles, TableCell, TableRow, Theme} from '@material-ui/core';
import {observer} from 'mobx-react';
import {getAttribute} from '../../getAttribute';
import {DataTableStore} from '../model/DataTableStore';
import {DataTableCell} from './DataTableCell';

export interface IDataTableRowProps<T> {
    store: DataTableStore<T>;
    rowIndex: number;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    cursor: {
        backgroundColor: theme.palette.grey['300'],
    },
    alternateRow: {
        backgroundColor: theme.palette.grey['200'],
    }
}));

export const DataTableRow = observer(function <T>(props: IDataTableRowProps<T>) {
    const {store, rowIndex} = props;
    const classes = useStyles(props);
    const row = store.rows[rowIndex];

    const onRowSelect = (event: React.ChangeEvent, checked: boolean): void => {
        const dataRowIndex = getAttribute(event.target, 'data-row-index');
        const rowIndex = parseInt(dataRowIndex, 10);
        const row = store.rows[rowIndex];
        store.selectRow(row, rowIndex, checked);
    };

    const cellClassName = useMemo(() => row.alteration ? classes.alternateRow : undefined, [row, row.alteration, classes.alternateRow]);

    return (
        <TableRow data-row-index={rowIndex}>
            <TableCell
                padding='checkbox'
                className={cellClassName}
            >
                <Checkbox
                    data-automation='CheckRow'
                    checked={row.isSelected}
                    onChange={onRowSelect}
                />
            </TableCell>
            {store.columns.map((column, columnIndex) => {
                const key = `R${row.id}C${column.key}`;
                return (
                    <DataTableCell
                        key={key}
                        store={store}
                        rowIndex={rowIndex}
                        column={column}
                        columnIndex={columnIndex}
                    />
                );
            })}
        </TableRow>
    );
});
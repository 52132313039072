import {IAnalyticsDataReplyPayload, IAnalyticsDataRequestPayload} from '../../dtos';
import {ApiFactory} from '../../common';
import { Urls } from '../../shared';

export class AnalyticsApi {
    constructor(private readonly apiFactory: ApiFactory) {
    }

    public fetchScreenData = this.apiFactory.createApiFunc<IAnalyticsDataRequestPayload, IAnalyticsDataReplyPayload>(
        Urls.getAnalyticsData);
}
import * as React from 'react';
import {Button, Card, CardActions, CardContent, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import {observer} from 'mobx-react';
import {useStore} from '../../storeProvider';
import {TextFieldView} from '../../common';

export interface IAccountScreenProps {
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default
    },
    card: {},
    errorMessage: {
        color: theme.palette.error.main
    },
    loginTextField: {},
    passwordTextField: {},
    title: {},
    createAccount: {
        marginLeft: 'auto'
    }
}));


export const AccountScreen: React.FC<IAccountScreenProps> = observer(props => {
    const classes = useStyles(props);
    const {accountScreenStore} = useStore();

    const onCreateAccount = () => {
        accountScreenStore.createAccount();
    };

    const onCancel = () => {
        accountScreenStore.cancel();
    };

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant='h5' className={classes.title}>
                        Create new GradeMe account
                    </Typography>
                    <TextFieldView
                        field={accountScreenStore.username}
                        textFieldProps={{
                            fullWidth: true,
                            autoFocus: true,
                        }}
                    />
                    <TextFieldView
                        field={accountScreenStore.name}
                        textFieldProps={{
                            fullWidth: true,
                        }}/>
                    <TextFieldView
                        field={accountScreenStore.email}
                        textFieldProps={{
                            fullWidth: true,
                        }}
                    />
                    <TextFieldView
                        field={accountScreenStore.password}
                        textFieldProps={{
                            fullWidth: true,
                        }}
                    />
                    <Typography
                        data-automation='ErrorMessage'
                        className={classes.errorMessage}
                    >
                        {accountScreenStore.errorMessage}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        data-automation='Cancel'
                        color='primary'
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        data-automation='Create'
                        color='secondary'
                        onClick={onCreateAccount}
                    >
                        Create
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
});
import React, {useEffect, useMemo} from 'react';
import {AppBar, createStyles, makeStyles, Theme, Toolbar, Typography} from '@material-ui/core';
import {observer} from 'mobx-react';
import {UserAppBarButton} from '../auth/UserAppBarButton';
import {InstructorsTable} from './InstructorsTable';
import {useStore} from '../../storeProvider';
import {BackButton, Loading, SnackMessage} from '../../common';
import {TableToolbarButtons} from '../../common';
import {ConfirmationDialog} from '../../common';
import {RouteComponentProps} from 'react-router';
import {ImportDialog} from './ImportDialog';

const useStyles = makeStyles((theme: Theme) => createStyles({
    flex: {flex: 1},
}));

export interface IInstructorsScreenRouteParams {
    projectId: string;
}

export interface IInstructorsProps extends RouteComponentProps<IInstructorsScreenRouteParams> {
}

export const InstructorsScreen: React.FC<IInstructorsProps> = observer(props => {
    const classes = useStyles(props);
    const {instructorsScreenStore} = useStore();
    const projectId = useMemo(() => parseInt(props.match.params.projectId, 10), [props.match.params.projectId]);
    useEffect(() => {
        if (!isNaN(projectId)) {
            instructorsScreenStore.setProjectId(projectId);
            instructorsScreenStore.fetchInstructors();
        }
    }, [instructorsScreenStore, projectId]);

    const onBack = () => {
        instructorsScreenStore.goBack();
    };

    const onAddInstructor = () => {
        instructorsScreenStore.addInstructor();
    };

    const onImportInstructors = () => {
        instructorsScreenStore.importInstructors();
    };

    const onRemoveInstructors = () => {
        instructorsScreenStore.removeInstructors();
    };

    return (
        <div>
            <AppBar position='static' color='primary'>
                <Toolbar>
                    <BackButton onClick={onBack}/>
                    <Typography variant='h6' color='inherit' className={classes.flex}>
                        Instructors
                    </Typography>
                    <TableToolbarButtons
                        onAdd={onAddInstructor}
                        onImport={onImportInstructors}
                        onRemove={onRemoveInstructors}
                        canRemove={instructorsScreenStore.canRemoveInstructors}
                    />
                    <div className={classes.flex}/>
                    <UserAppBarButton/>
                </Toolbar>
            </AppBar>
            <InstructorsTable/>
            <ConfirmationDialog store={instructorsScreenStore.confirmationDialog}/>
            <SnackMessage snackMessageStore={instructorsScreenStore.snack}/>
            <ImportDialog store={instructorsScreenStore.importDialog}/>
            <Loading isOpen={instructorsScreenStore.isBusy}/>
        </div>
    );
});
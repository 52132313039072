import React from 'react';
import {observer} from 'mobx-react';
import {DataTable} from '../../common';
import {useStore} from '../../storeProvider';

export const GradesTable: React.FC<{}> = observer(props => {
    const {studentGradesScreenStore} = useStore();
    return (
        <DataTable store={studentGradesScreenStore.gradesTableStore}/>
    );
});
import React from 'react';
import {TableCell, TableHead, TableRow, TableSortLabel, makeStyles, createStyles, Theme} from '@material-ui/core';
import {observer} from 'mobx-react';
import {CheckAllCheckboxCell} from './CheckAllCheckboxCell';
import {DataTableStore} from '../model/DataTableStore';

export interface IDataTableHeadProps<T> {
    store: DataTableStore<T>;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    headCell: {
        whiteSpace: 'nowrap',
    }
}));

export const DataTableHead = observer(function <T>(props: IDataTableHeadProps<T>) {
    const {store} = props;
    const columns = store.columns;
    const classes = useStyles(props);

    const onSortHandlers = React.useMemo(() => {
        return new Map(
            columns.map(column => [column.key, () => store.sort(column.key)])
        )
    }, [store, columns]);

    return (
        <TableHead>
            <TableRow>
                <CheckAllCheckboxCell store={store}/>
                {store.columns.map(column => {
                    const sortDirection = store.orderBy === column.key && store.order !== false ?
                        store.order : undefined;
                    if (column.renderHeaderCell) {
                        return column.renderHeaderCell(sortDirection);
                    }
                    return (
                        <TableCell
                            key={`Header${column.key}`}
                            sortDirection={sortDirection}
                            classes={{
                                head: classes.headCell,
                            }}
                        >
                            <TableSortLabel
                                active={store.orderBy === column.key}
                                direction={sortDirection}
                                onClick={onSortHandlers.get(column.key)}
                            >
                                {column.header}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
});
import {action, computed, decorate, observable} from 'mobx';

export type OptionTextGetter<T> = (option: T | undefined) => string;

export class SelectorFieldStore<T> {
    private _value = observable.box<T | undefined>();

    public options = observable.array<T>();

    public label: string;

    constructor(
        label: string,
        options: T[],
        value?: T,
        private _optionTextGetter: OptionTextGetter<T> = (x: T | undefined) => x !== undefined ? String(x) : ''
    ) {
        this.label = label;
        this.options.replace(options);
        this._value.set(value);
    }

    public get value(): T | undefined {
        return this._value.get();
    }

    public setValue = (value: T | undefined) => {
        this._value.set(value);
    }

    public setLabel = (label: string) => {
        this.label = label;
    };

    public setInputText = (text: string) => {
        const value = this.options.find(option => this._optionTextGetter(option) === text);
        this._value.set(value);
    };

    public clear = () => {
        this._value.set(undefined);
    }
}

decorate(SelectorFieldStore, {
    value: computed,
    label: observable,
    setLabel: action,
    setInputText: action,
    setValue: action,
    clear: action
});
import {StudentInfo} from './StudentInfo';
import {DataTableStore} from '../../common';
import {action, computed, decorate, observable} from 'mobx';
import {RootStore} from '../../RootStore';
import {ConfirmationDialogStore} from '../../common';
import {SnackMessageStore, SnackType} from '../../common';
import {StudentsApi} from './StudentsApi';
import {IProjectStudentsRequestPayload} from '../../dtos';
import {UserProject} from '../../model/UserProject';
import {ImportDialogStore} from './ImportDialogStore';
import {IAddStudentsRequestPayload} from '../../dtos';
import {IRemoveStudentsRequestPayload, RelatedRecordAction} from '../../dtos';
import {split, mapBy, merge} from '../../shared/utils';
import { Team } from '../../model/Team';
import {RowDefinition} from '../../common';

const compareStrings = (a?: string, b?: string): number => {
    if (!a) {
        if (!b) {
            return 0
        }
        return -1;
    }
    if (!b) {
        return 1;
    }
    return a.localeCompare(b);
};

export class StudentsScreenStore {
    private _id = observable.box<number | undefined>(undefined);
    public readonly studentsTableStore: DataTableStore<StudentInfo>;
    public readonly confirmationDialog: ConfirmationDialogStore;
    private _isBusy = observable.box<boolean>(false);
    public readonly snackMessage: SnackMessageStore;
    public readonly importDialog: ImportDialogStore;

    public constructor(public rootStore: RootStore, private readonly studentsApi: StudentsApi, snackMessage: SnackMessageStore) {
        this.snackMessage = snackMessage;
        this.importDialog = new ImportDialogStore();
        this.studentsTableStore = new DataTableStore<StudentInfo>([{
            key: 'name',
            header: 'Name',
            // @ts-ignore
            getCellValue: row => row.data.user.name,
            sortFunction: (a, b) => a.data.user.name.localeCompare(b.data.user.name),
        }, {
            key: 'login',
            header: 'Login',
            // @ts-ignore
            getCellValue: row => row.data.user.login,
            sortFunction: (a, b) => a.data.user.login.localeCompare(b.data.user.login),
        }, {
            key: 'email',
            header: 'Email',
            // @ts-ignore
            getCellValue: row => row.data.user.email,
            sortFunction: (a, b) => a.data.user.email.localeCompare(b.data.user.email),
        }, {
            key: 'team',
            header: 'Team',
            // @ts-ignore
            getCellValue: row => row.data.teamName,
            sortFunction: (a, b) => a.data.teamName.localeCompare(b.data.teamName),
        }, {
            key: 'password',
            header: 'Password',
            // @ts-ignore
            getCellValue: row => row.data.user.password,
            sortFunction: (a, b) => compareStrings(a.data.user.password, b.data.user.password),
        }]);
        // const rows = [0, 1, 2, 3].map(x => ({data: new StudentInfo()}));
        // this.studentsTableStore.setRows(rows);
        this.confirmationDialog = new ConfirmationDialogStore(
            'Delete confirmation',
            'Delete selected students?',
            [{
                name: `Also delete unused user records`,
                defaultValue: true,
            }]);
    }

    public get id(): number | undefined {
        return this._id.get();
    }

    public setId = (id: number) => {
        this._id.set(id);
    };

    public get isBusy(): boolean {
        return this._isBusy.get();
    }

    private linkTeams = (userProjects: UserProject[], teams: Team[]) => {
        const teamsById = mapBy(teams, t => t.id);
        userProjects.forEach(up => {
            const user = up.user!;
            const student = user.student!;
            const teamStudents = student.teamStudents;
            if (teamStudents && teamStudents.length === 1) {
                teamStudents[0].team = teamsById.get(teamStudents[0].teamId)
            }
        });
    };

    public fetchStudents = async (): Promise<void> => {
        const projectId = this.id;
        if (!projectId) {
            return;
        }
        this._isBusy.set(true);
        try {
            const payload: IProjectStudentsRequestPayload = {
                projectId,
            };
            const reply = await this.studentsApi.fetchStudents(payload);
            const userProjects = reply.userProjects.map(UserProject.fromDto);
            const teams = reply.teams.map(Team.fromDto);
            this.linkTeams(userProjects, teams);
            const rows = userProjects.map(x => new RowDefinition(String(x.id), new StudentInfo(x)));
            this.studentsTableStore.setRows(rows);
            this.snackMessage.open(`Loaded students`, SnackType.Success);
        } catch (e) {
            this.snackMessage.open(`Filed to load students. ${e.message}`, SnackType.Error);
        } finally {
            this._isBusy.set(false);
        }
    };

    public goBack = () => {
        this.rootStore.routerStore.goBack();
    };

    public addStudent = () => {
        const rs = this.rootStore.routerStore;

        rs.push(`${rs.location.pathname}/student`)
    };

    public importStudents = async () => {
        const importResult = await this.importDialog.open();
        if (importResult.isOk) {
            this._isBusy.set(true);
            try {
                const students = importResult.students;
                const requestPayload: IAddStudentsRequestPayload = {
                    projectId: this.id!,
                    students: students.map(x => x.toDto()),
                };
                const repPayload = await this.studentsApi.saveStudents(requestPayload);
                const teams = repPayload.teams.map(Team.fromDto);
                const userProjects = repPayload.userProjects.map(UserProject.fromDto);
                this.linkTeams(userProjects, teams);
                const newRows = userProjects.map(x => new RowDefinition(String(x.id), new StudentInfo(x)));
                const oldRows = this.studentsTableStore.rows;
                const rows = merge(oldRows, newRows, row => row.data.userProject.user!.login);
                this.studentsTableStore.setRows(rows);
                this.snackMessage.open(`Students saved`, SnackType.Success);
            } catch (e) {
                this.snackMessage.open(`Failed to save students ${e.message}`, SnackType.Error);
            } finally {
                this._isBusy.set(false);
            }
        }
    };

    public removeStudents = async () => {
        const confirmation = await this.confirmationDialog.open();
        if (confirmation.isConfirmed) {
            this._isBusy.set(true);
            try {
                const [selectedRows, unselectedRows] = split(this.studentsTableStore.rows, x => !!x.isSelected);
                const reqPayload: IRemoveStudentsRequestPayload = {
                    projectId: this.id!,
                    userProjectIds: selectedRows.map(row => row.data.userProject.id!),
                    userRecordsAction: confirmation.options![0] ? RelatedRecordAction.RemoveIfUnused : RelatedRecordAction.Keep,
                };
                const repPayload = await this.studentsApi.deleteStudents(reqPayload);
                this.snackMessage.open(repPayload.message, SnackType.Success);
                this.studentsTableStore.setRows(unselectedRows);
            } catch (e) {
                this.snackMessage.open(`Failed to remove students. ${e.message}`);
            } finally {
                this._isBusy.set(false);
            }
        }
    };

    public get canRemoveStudents(): boolean {
        return this.studentsTableStore.rows.some(x => x.isSelected);
    }
}

decorate(StudentsScreenStore, {
    studentsTableStore: observable,
    confirmationDialog: observable,
    canRemoveStudents: computed,
    snackMessage: observable,
    fetchStudents: action,
    removeStudents: action,
    importStudents: action,
    goBack: action,
    id: computed,
    setId: action,
    importDialog: observable,
    isBusy: computed,
});
import {action, computed, decorate, observable} from 'mobx';
import {instructorsCsvParser} from './instructorsCsvParser';
import {User} from '../../model';

export interface ImportDialogResult {
    isOk: boolean;
    users: User[];
}

export class ImportDialogStore {
    private _isOpen = observable.box<boolean>(false);
    private _text = observable.box<string>('');
    private _helperText = observable.box<string>('');
    private _isValid = observable.box<boolean>(true);
    private _users = observable.array<User>(); // Users to add as instructors. Parsed from CSV

    private _promise?: Promise<ImportDialogResult>;
    private _resolve?: (x: ImportDialogResult) => void;

    public get isOpen(): boolean {
        return this._isOpen.get();
    }

    public get isValid(): boolean {
        return this._isValid.get();
    }

    public get helperText(): string {
        return this._helperText.get();
    }

    public get text(): string {
        return this._text.get();
    }

    public get canSave(): boolean {
        return this.isValid && this._users && this._users.length > 0;
    }

    public open = async (): Promise<ImportDialogResult> => {
        if (this.isOpen) {
            return this._promise!;
        }
        this._promise = new Promise(resolve => {
            this._resolve = resolve;
        });
        this._isOpen.set(true);
        return this._promise;
    };

    public setText = (text: string) => {
        this._text.set(text);
        try {
            const users = instructorsCsvParser(text);
            this._isValid.set(true);
            this._helperText.set(
                `${users.length} instructors successfully parsed. ` +
                `Press Save to add them to the project.`
            );
            this._users.replace(users);
        } catch (e) {
            this._isValid.set(false);
            this._helperText.set(e.message);
            this._users.replace([]);
        }
    };

    public cancel = () => {
        if (!this.isOpen) {
            return;
        }
        this._resolve!({
            isOk: false,
            users: [],
        });
        this._resolve = undefined;
        this._promise = undefined;
        this._isOpen.set(false);
    };

    public save = () => {
        if (!this.isOpen) {
            return;
        }
        this._resolve!({
            isOk: true,
            users: this._users.toJS(),
        });
        this._resolve = undefined;
        this._promise = undefined;
        this._isOpen.set(false);
    };
}

decorate(ImportDialogStore, {
    isOpen: computed,
    cancel: action,
    canSave: computed,
    helperText: computed,
    isValid: computed,
    text: computed,
    open: action,
    save: action,
    setText: action,
});
import * as React from 'react';
import {createStyles, FormControl, InputLabel, makeStyles, MenuItem, Select, Theme} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {SelectorFieldStore} from './selectorFieldStore';

export interface ISelectorFieldViewProps {
    field: SelectorFieldStore<any>;
    disabled?: boolean;
    renderValue?: (option: any) => React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {},
    inputLabel: {},
    select: {},
}));

function SelectorFieldViewImpl(props: ISelectorFieldViewProps) {
    const classes = useStyles(props);

    const {field, disabled} = props;

    const onChange = (e: React.ChangeEvent<{name?: string, value: unknown}>) => {
        const value = e.target.value;
        field.setValue(value);
    };

    const renderValueCallback = props.renderValue;

    const renderValue = React.useCallback((value: unknown) => {
        if (renderValueCallback === undefined) {
            return String(value);
        }
        return renderValueCallback(value);
    }, [renderValueCallback]);

    return (
        <FormControl className={classes.formControl} fullWidth margin='normal'>
            <InputLabel
                htmlFor='blooms-taxonomy-level'
                className={classes.inputLabel}
            >
                {field.label}
            </InputLabel>
            <Select
                className={classes.select}
                value={field.value}
                renderValue={renderValue}
                onChange={onChange}
                disabled={disabled}
                inputProps={{
                    // 'name': 'blooms-taxonomy-level',
                    // 'id': 'blooms-taxonomy-level',
                    // 'data-automation': 'BloomsTaxonomyLevelInput'
                }}
                MenuProps={{
                    // 'data-automation': 'BloomsTaxonomyLevelMenu'
                } as any}
            >
                {field.options.map((x, i) => {
                    const option = props.renderValue ? props.renderValue(x) : String(x);
                    return (
                        <MenuItem key={i} value={x}>{option}</MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export const SelectorFieldView = observer(SelectorFieldViewImpl);
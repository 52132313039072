import {User} from '../../model/User';
import {action, computed, decorate, observable, reaction} from 'mobx';
import {RootStore} from '../../RootStore';
import {IAuthApi} from './AuthApi';
import axios from 'axios';
import {
    createRequestDto,
    ICreateAccountRequestDto,
    ICreateAccountRequestPayload,
    ISignInRequestPayload,
    isRequestSuccessful
} from '../../dtos';
import {persist} from 'mobx-persist';
import {getLogger} from '../../common';

export interface ISignInResult {
    isOk: boolean;
    errorMessage: string | null;
}

export interface ICreateAccountResult {
    isOk: boolean;
    errorMessage?: string;
}

export class AuthStore {
    public user: User | null = null;
    public token: string | null = null;
    private log = getLogger('AuthStore');
    private _isRehydrated = observable.box<boolean>(false);

    public constructor(public rootStore: RootStore, private readonly authApi: IAuthApi) {
        reaction(() => this.token,
            token => {
                this.log.debug(`Setting default authorization token on HTTP headers to ${token}`);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            });
    }

    public get isRehydrated(): boolean {
        return this._isRehydrated.get();
    }

    public signIn = async (username: string, password: string): Promise<ISignInResult> => {
        const request = createRequestDto<ISignInRequestPayload>({
            login: username,
            password: password
        });
        try {
            const reply = await this.authApi.signIn(request);
            if (isRequestSuccessful(reply)) {
                this.token = reply.payload.token;
                this.user = User.fromDto(reply.payload!.user!);
                this.rootStore.routerStore.push('/');
                return {
                    isOk: true,
                    errorMessage: null
                };
            } else {
                this.user = null;
                this.token = null;
                return {
                    isOk: false,
                    errorMessage: reply.errorMessage
                };
            }
        } catch (exc) {
            this.user = null;
            this.token = null;
            return {
                isOk: false,
                errorMessage: String(exc),
            }
        }
    };

    public editProfile = () => {
        this.rootStore.routerStore.push('/profile');
    }

    public signOut = () => {
        this.user = null;
        this.token = null;
        this.rootStore.routerStore.push('/login');
    };

    public createAccount = async (payload: ICreateAccountRequestPayload): Promise<ICreateAccountResult> => {
        const request: ICreateAccountRequestDto = createRequestDto(payload);
        try {
            const reply = await this.authApi.createAccount(request);
            if (isRequestSuccessful(reply)) {
                this.token = reply.payload.token;
                this.user = User.fromDto(reply.payload!.user!);
                this.rootStore.routerStore.push('/');
                return {
                    isOk: true
                };
            } else {
                this.user = null;
                this.token = null;
                return {
                    isOk: false,
                    errorMessage: reply.errorMessage
                };
            }
        } catch (exc) {
            this.user = null;
            this.token = null;
            return {
                isOk: false,
                errorMessage: String(exc),
            }
        }
    };

    public setRehydrated = () => {
        this._isRehydrated.set(true);
    }
}

decorate(AuthStore, {
    user: [persist('object') as PropertyDecorator, observable],
    token: [persist, observable],
    signOut: action,
    createAccount: action,
    isRehydrated: computed,
    setRehydrated: action,
    editProfile: action,
});
import {action, computed, decorate, observable} from 'mobx';

export class NumericFieldStore {
    private readonly _value = observable.box<number | undefined>();
    private readonly _input = observable.box<string | undefined>();
    private readonly _label = observable.box<string>('');
    private readonly _isDisabled = observable.box<boolean>(false);
    private readonly _precision = observable.box<number>(3);
    private readonly _isFocused = observable.box<boolean>(false);

    public constructor(label: string, value?: number, precision: number = 3) {
        this._label.set(label);
        this._value.set(value);
        this._precision.set(precision || 0);
    }

    public get value(): number | undefined {
        return this._value.get();
    }

    public get valueText(): string {
        if (this.isFocused) {
            return this.input || '';
        }
        if (this.value === undefined) {
            return '';
        }
        return this.value.toFixed(this.precision);
    }

    public get input(): string | undefined {
        return this._input.get();
    }

    public get label(): string {
        return this._label.get();
    }

    public get isDisabled(): boolean {
        return this._isDisabled.get();
    }

    public get isFocused(): boolean {
        return this._isFocused.get();
    }

    public setValueFromInput = (input: string) => {
        this._input.set(input);
        if (input === '') {
            this.setValue(undefined);
        } else {
            const value = this.precision === 0 ? parseInt(input, 10) : parseFloat(input);
            if (!isNaN(value)) {
                this.setValue(value);
            }
        }
    };

    public setValue = (value: number | undefined) => {
        this._value.set(value);
    };

    public setLabel = (label: string) => {
        this._label.set(label);
    };

    public setDisabled = (disabled: boolean) => {
        this._isDisabled.set(disabled);
    };

    public get precision(): number {
        return this._precision.get();
    }

    public setPrecision = (precision: number) => {
        this._precision.set(precision);
    };

    public setIsFocused = (isFocused: boolean) => {
        if (isFocused === this.isFocused) {
            return;
        }
        if (isFocused) {
            this._input.set(this.valueText);
        } else {
            this._input.set(undefined);
        }
        this._isFocused.set(isFocused);
    }
}

decorate(NumericFieldStore, {
    value: computed,
    input: computed,
    label: computed,
    isDisabled: computed,
    isFocused: computed,
    setValue: action,
    setLabel: action,
    setDisabled: action,
    setValueFromInput: action,
    valueText: computed,
    precision: computed,
    setPrecision: action,
    setIsFocused: action,
});
import * as React from 'react';
import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../storeProvider';
import {MyResultCard} from './MyResultCard';

export interface IMyResultCardListProps {
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    noMyResultsMessage: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: theme.spacing(2),
    }
}));

export const MyResultCardList: React.FC<IMyResultCardListProps> = observer(props => {
    const classes = useStyles(props);
    const {myResultsScreenStore} = useStore();
    const {cards} = myResultsScreenStore;

    if (cards.length === 0) {
        return (
            <div className={classes.root}>
                <Typography className={classes.noMyResultsMessage} align={'center'}>
                    You have no teammates.
                </Typography>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            {cards.map((card, i) => (
                <MyResultCard
                    key={`card${i}`}
                    store={card}
                />
            ))}
        </div>
    );
});
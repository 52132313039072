import React from 'react';
import {RowDefinition} from '../../../common';
import {ResultsRowData} from './ResultsRowData';
import {createStyles, Link, TableCell, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

export interface INameRendererProps {
    row: RowDefinition<ResultsRowData>;
    onClick: (row: RowDefinition<ResultsRowData>) => void;
    columnIndex: number;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    nameCell: {
        position: 'relative',
        whiteSpace: 'nowrap',
        maxWidth: 200,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }
}));

export const NameCellRenderer: React.FC<INameRendererProps> = props => {
    const {row} = props;
    const classes = useStyles(props);

    const goToGrades = () => {
        // props.onClick(row);
    };

    return (
        <TableCell
            data-column-index={String(props.columnIndex)}
            className={clsx(classes.nameCell, props.className)}
        >
            <Link href="#" onClick={goToGrades}>
                {row.data.user.name}
            </Link>
        </TableCell>
    );
};
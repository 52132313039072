import {action, computed, decorate, observable} from 'mobx';
import {NumericFieldStore} from '../../../common';
// import {AnnotationTextModel} from '../../common';

export class ResultsEditorStore {
    private _isOpen = observable.box<boolean>(false);
    public readonly contribution: NumericFieldStore;
    public readonly consistency: NumericFieldStore;
    public readonly teamScore: NumericFieldStore;
    public readonly individualScore: NumericFieldStore;
    // public justification: AnnotationTextModel;

    public constructor() {
        // this.justification = new AnnotationTextModel();
        // this.justification.setSentences([
        //     'Volunteered to take on part of advanced math analysis.',
        //     'Researched love models at start of project.',
        //     'Dependable and capable with mathematics; can trust him to complete analysis.',
        //     'So quiet he often hardly seems present.',
        //     'Difficult to tell what he is thinking, does not often contribute ideas.'
        // ]);
        this.contribution = new NumericFieldStore('Contribution');
        this.consistency = new NumericFieldStore('Consistency');
        this.teamScore = new NumericFieldStore('Team Score');
        this.individualScore = new NumericFieldStore('Individual Score');
    }

    public get isOpen(): boolean {
        return this._isOpen.get();
    }

    public open = (isOpen: boolean = true) => {
        this._isOpen.set(isOpen);
    };

    public close = () => {
        this._isOpen.set(false);
    };
}

decorate(ResultsEditorStore, {
    isOpen: computed,
    contribution: observable,
    consistency: observable,
    teamScore: observable,
    individualScore: observable,
    open: action,
});

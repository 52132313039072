import {RouterStore} from 'mobx-react-router';
import {AuthStore} from './screens/auth/AuthStore';
import {decorate, observable} from 'mobx';
import {
    AccountScreenStore,
    AnalyticsApi,
    AnalyticsScreenStore,
    AuthApi,
    EvaluationApi,
    EvaluationScreenStore,
    HomeApi,
    HomeScreenStore,
    InstructorsApi,
    InstructorScreenStore,
    InstructorsScreenStore,
    LoginScreenStore,
    MyResultsApi,
    MyResultsScreenStore,
    ProfileApi,
    ProfileScreenStore,
    ProjectEditorScreenStore,
    ProjectResultsConfigScreenStore,
    ProjectScreenStore,
    ResultsApi,
    ResultsScreenStore,
    StudentGradesScreenStore,
    StudentsApi,
    StudentScreenStore,
    StudentsScreenStore
} from './screens';
import {create} from 'mobx-persist';
import {ApiFactory, logFactory, SnackMessageStore} from './common';

export class RootStore {
    public readonly routerStore: RouterStore;
    public readonly authStore: AuthStore;
    public readonly loginScreenStore: LoginScreenStore;
    public readonly homeScreenStore: HomeScreenStore;
    public readonly resultsStore: ResultsScreenStore;
    public readonly studentsScreenStore: StudentsScreenStore;
    public readonly studentScreenStore: StudentScreenStore;
    public readonly instructorsScreenStore: InstructorsScreenStore;
    public readonly instructorScreenStore: InstructorScreenStore;
    public readonly analyticsScreenStore: AnalyticsScreenStore;
    public readonly projectScreenStore: ProjectScreenStore;
    public readonly studentGradesScreenStore: StudentGradesScreenStore;
    public readonly accountScreenStore: AccountScreenStore;
    public readonly projectEditorScreenStore: ProjectEditorScreenStore;
    public readonly evaluationScreenStore: EvaluationScreenStore;
    public readonly myResultsScreenStore: MyResultsScreenStore;
    public readonly projectResultsConfigScreenStore: ProjectResultsConfigScreenStore;
    public readonly profileScreenStore: ProfileScreenStore;

    private readonly apiFactory: ApiFactory;
    private readonly logger = logFactory.getLogger('RootStore');

    constructor() {
        this.routerStore = new RouterStore();
        this.apiFactory = new ApiFactory(this.routerStore);

        const homeApi = new HomeApi(this.apiFactory);
        const studentsApi = new StudentsApi(this.apiFactory);
        const evaluationApi = new EvaluationApi(this.apiFactory);
        const resultsApi = new ResultsApi(this.apiFactory);
        const myResultsApi = new MyResultsApi(this.apiFactory);
        const instructorsApi = new InstructorsApi(this.apiFactory);
        const analyticsApi = new AnalyticsApi(this.apiFactory);
        const profileApi = new ProfileApi(this.apiFactory);

        const snackMessage = new SnackMessageStore();

        this.accountScreenStore = new AccountScreenStore(this);
        this.analyticsScreenStore = new AnalyticsScreenStore(this, analyticsApi, snackMessage);
        this.authStore = new AuthStore(this, new AuthApi());
        this.evaluationScreenStore = new EvaluationScreenStore(this, evaluationApi, snackMessage);
        this.homeScreenStore = new HomeScreenStore(this, homeApi, snackMessage);
        this.instructorsScreenStore = new InstructorsScreenStore(this, instructorsApi, snackMessage);
        this.instructorScreenStore = new InstructorScreenStore(this, instructorsApi, snackMessage);
        this.loginScreenStore = new LoginScreenStore(this);
        this.projectScreenStore = new ProjectScreenStore(this);
        this.projectEditorScreenStore = new ProjectEditorScreenStore(this, homeApi, snackMessage);
        this.resultsStore = new ResultsScreenStore(this, resultsApi, snackMessage);
        this.studentsScreenStore = new StudentsScreenStore(this, studentsApi, snackMessage);
        this.studentScreenStore = new StudentScreenStore(this);
        this.studentGradesScreenStore = new StudentGradesScreenStore(this);
        this.myResultsScreenStore = new MyResultsScreenStore(this, myResultsApi, snackMessage);
        this.projectResultsConfigScreenStore = new ProjectResultsConfigScreenStore(this, snackMessage);
        this.profileScreenStore = new ProfileScreenStore(this, profileApi, snackMessage);

        const hydrate = create();
        hydrate('auth', this.authStore).then(() => {
            this.logger.info('Auth store hydrated');
            this.authStore.setRehydrated();
        })
    }
}

decorate(RootStore, {
    accountScreenStore: observable,
    analyticsScreenStore: observable,
    authStore: observable,
    evaluationScreenStore: observable,
    homeScreenStore: observable,
    instructorScreenStore: observable,
    instructorsScreenStore: observable,
    loginScreenStore: observable,
    projectScreenStore: observable,
    projectEditorScreenStore: observable,
    resultsStore: observable,
    routerStore: observable,
    studentsScreenStore: observable,
    studentScreenStore: observable,
    studentGradesScreenStore: observable,
    myResultsScreenStore: observable,
    projectResultsConfigScreenStore: observable,
    profileScreenStore: observable,
});
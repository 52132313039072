import {decorate, observable} from 'mobx';
import {RootStore} from '../../RootStore';
import {AutocompleteFieldStore, TextFieldStore} from '../../common';

export class StudentScreenStore {
    public id = observable.box<number | null>(null);
    public login: AutocompleteFieldStore;
    public email: TextFieldStore;
    public password: TextFieldStore;
    public name: TextFieldStore;
    public isBusy = observable.box(false);
    public projectId = observable.box<number | null>(null);

    public constructor(public rootStore: RootStore) {
        this.login = new AutocompleteFieldStore('Login', undefined);
        this.email = new TextFieldStore('Email', '');
        this.password = new TextFieldStore('Password', '');
        this.name = new TextFieldStore('Name', '');
    }

    public goBack = () => {
        this.rootStore.routerStore.goBack();
    };

    public setProjectId = (projectId: number) => {

    };

    public save = () => {

    };
}

decorate(StudentScreenStore, {
});
import React, {useEffect} from 'react';
import {AppBar, createStyles, IconButton, makeStyles, Theme, Toolbar, Typography} from '@material-ui/core';
import {observer} from 'mobx-react';
import {UserAppBarButton} from '../auth/UserAppBarButton';
import {ProjectList} from './ProjectsList';
import {useStore} from '../../storeProvider';
import AddIcon from '@material-ui/icons/AddCircle';
import {SnackMessage} from '../../common';
import {Loading, BackButton} from '../../common';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    flex: {
        flex: 1,
    },
    addProjectButton: {}
}));

export interface IHomeProps {

}

export const HomeScreen: React.FC<IHomeProps> = observer(props => {
    const classes = useStyles(props);
    const {homeScreenStore, authStore} = useStore();

    useEffect(() => {
        if (authStore.isRehydrated) {
            homeScreenStore.fetchHomeData();
        }
    }, [authStore.isRehydrated, homeScreenStore]);

    const onAddProject = () => {
        homeScreenStore.createProject();
    };

    return (
        <div className={classes.root}>
            <AppBar position='static' color='primary'>
                <Toolbar>
                    <BackButton/>
                    <Typography variant='h6' color='inherit' className={classes.flex}>
                        GradeMe
                    </Typography>
                    <UserAppBarButton/>
                    <IconButton
                        className={classes.addProjectButton}
                        color='inherit'
                        aria-label='Add New Project'
                        onClick={onAddProject}
                    >
                        <AddIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <ProjectList/>
            <SnackMessage snackMessageStore={homeScreenStore.snackMessage}/>
            <Loading isOpen={homeScreenStore.isBusy}/>
        </div>
    );
});
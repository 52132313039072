import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {AutocompleteChangeReason, AutocompleteInputChangeReason} from '@material-ui/lab/Autocomplete';
import {observer} from 'mobx-react-lite';
import {AutocompleteFieldStore, IAutocompleteSuggestion} from './autocompleteFieldStore';
import {CircularProgress} from '@material-ui/core';

export interface IAutocompleteFieldViewProps {
    field: AutocompleteFieldStore;
    autoFocus?: boolean;
}

export const AutocompleteFieldView: React.FC<IAutocompleteFieldViewProps> = observer(props => {
    const {value, label, suggestions, isOpen, isLoading} = props.field;
    const {autoFocus} = props;

    const onChange = (event: any, newValue: IAutocompleteSuggestion | string | null, reason: AutocompleteChangeReason) => {
        props.field.setValue(newValue, reason);
    };

    const onInputChange = (event: any, input: string, reason: AutocompleteInputChangeReason) => {
        console.log(`onInputChange to ${input}`);
        props.field.setInput(input, reason);
    };

    const onOpen = () => {
        props.field.setIsOpen(true);
    };

    const onClose = () => {
        props.field.setIsOpen(false);
    };

    const getOptionLabel = (option: string | IAutocompleteSuggestion): string => {
        if (typeof option === 'string') {
            return option;
        }
        return option.label;
    };

    const renderOption = (option: IAutocompleteSuggestion): string => {
        return option.extendedLabel || option.label;
    };

    const renderInput = (params: any) => {
        return (
            <TextField
                {...params}
                label={label}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {isLoading ? <CircularProgress color='inherit' size={20}/> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    )
                }}
            />
        );
    };

    return (
        <Autocomplete
            value={value || null}
            onChange={onChange}
            onInputChange={onInputChange}
            selectOnFocus={autoFocus}
            clearOnBlur={true}
            options={suggestions}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            freeSolo={true}
            renderInput={renderInput}
            open={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            loading={isLoading}
            autoSelect={true}
        />
    );
});

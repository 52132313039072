import {
    IProfileScreenReplyPayload,
    IProfileScreenRequestPayload,
    ISaveProfileReplyPayload,
    ISaveProfileRequestPayload
} from '../../dtos';
import {ApiFactory} from '../../common';

export class ProfileApi {
    constructor(private readonly apiFactory: ApiFactory) {
    }

    public fetchScreenData = this.apiFactory.createApiFunc<IProfileScreenRequestPayload, IProfileScreenReplyPayload>('/api/profile/get-screen-data');
    public saveProfile = this.apiFactory.createApiFunc<ISaveProfileRequestPayload, ISaveProfileReplyPayload>('/api/profile/save-profile');
}
import * as React from 'react';
import {useEffect} from 'react';
import {
    AppBar,
    Button,
    Card,
    CardActions,
    CardContent,
    createStyles,
    Grid,
    makeStyles,
    TableContainer,
    Theme,
    Toolbar,
    Typography
} from '@material-ui/core';
import {RouteComponentProps} from 'react-router';
import {observer} from 'mobx-react';
import {useStore} from '../../storeProvider';
import {
    BackButton,
    ConfirmationDialog,
    DataTable,
    Loading,
    SelectorFieldView,
    SnackMessage,
    TextFieldView
} from '../../common';
import {ConceptEditor} from './ConceptEditor';

export interface IProjectEditorRouteParams {
    projectId: string;
}

export interface IProjectEditorScreenProps extends RouteComponentProps<IProjectEditorRouteParams> {
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        maxWidth: 800,
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    flex: {flex: 1},
    cardContentGrid: {},
    tableContainer: {},
}));

export const ProjectEditorScreen: React.FC<IProjectEditorScreenProps> = observer(props => {
    const {projectEditorScreenStore: screenStore} = useStore();

    useEffect(() => {
        const projectId = parseInt(props.match.params.projectId, 10);
        if (!isNaN(projectId)) {
            screenStore.setId(projectId);
            screenStore.fetch(projectId);
        } else {
            screenStore.reset();
        }
    }, [screenStore, props.match.params.projectId]);

    const classes = useStyles(props);

    const onSave = () => {
        screenStore.save();
    };

    const onCancel = () => {
        screenStore.cancel();
    };

    const onDelete = () => {
        screenStore.delete();
    };

    const onAddConcept = () => {
        screenStore.addConcept();
    }

    const onEditConcept = () => {
        screenStore.editConcept();
    }

    const onRemoveConcept = () => {
        screenStore.removeConcept();
    }

    const isExistingProject = screenStore.id != null;

    const titleText = isExistingProject ? 'Edit Project Details' : 'Create New Project';

    return (
        <div>
            <AppBar position='static' color='primary'>
                <Toolbar>
                    <BackButton onClick={onCancel}/>
                    <Typography variant='h6' color='inherit' className={classes.flex}>
                        {titleText}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Card className={classes.card}>
                <CardContent>
                    <Grid className={classes.cardContentGrid} container spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldView field={screenStore.name}
                                           textFieldProps={{fullWidth: true, autoFocus: true}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldView field={screenStore.description} textFieldProps={{fullWidth: true}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectorFieldView field={screenStore.status}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer className={classes.tableContainer}>
                                <DataTable
                                    store={screenStore.conceptsTableStore}
                                    tableProps={{}}
                                />
                            </TableContainer>
                        </Grid>
                        <Button
                            onClick={onAddConcept}
                        >
                            Add Concept
                        </Button>
                        <Button
                            onClick={onEditConcept}
                        >
                            Edit Concept
                        </Button>
                        <Button
                            onClick={onRemoveConcept}
                        >
                            Remove Concept
                        </Button>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button
                        color='primary'
                        onClick={onSave}
                        disabled={!screenStore.canSave}
                    >
                        Save
                    </Button>
                    <Button color='secondary' onClick={onCancel} data-automation='CancelButton'>
                        Cancel
                    </Button>
                    <Button
                        color='secondary'
                        onClick={onDelete}
                        disabled={!isExistingProject}
                    >
                        Delete Project
                    </Button>
                </CardActions>
            </Card>
            <SnackMessage snackMessageStore={screenStore.snackStore}/>
            <Loading isOpen={screenStore.isBusy}/>
            <ConfirmationDialog store={screenStore.confirmationDialogStore}/>
            <ConceptEditor store={screenStore.conceptEditorStore}/>
        </div>
    );
});
import {IProjectConceptDto} from '../dtos';
import {Concept} from './Concept';
import {Project} from './Project';

export class ProjectConcept {
    public id?: number;
    public concept?: Concept;
    public conceptId?: number;
    public project?: Project;
    public projectId?: number;
    public weight?: number;

    public static fromDto = (d: IProjectConceptDto): ProjectConcept => {
        const pc = new ProjectConcept();
        pc.id = d.id;
        pc.concept = d.concept ? Concept.fromDto(d.concept) : undefined;
        pc.conceptId = d.concept ? d.concept.id : d.conceptId;
        pc.project = d.project ? Project.fromDto(d.project) : undefined;
        pc.projectId = d.project ? d.project.id : d.projectId;
        pc.weight = d.weight;
        return pc;
    }

    public toDto = (): IProjectConceptDto => {
        return {
            id: this.id,
            weight: this.weight,
            concept: this.concept ? this.concept.toDto() : undefined,
            conceptId: this.concept ? this.concept.id : this.conceptId,
        };
    }

    public clone = (): ProjectConcept => {
        const c = new ProjectConcept();
        c.id = this.id;
        c.concept = this.concept;
        c.conceptId = this.conceptId;
        c.project = this.project;
        c.projectId = this.projectId;
        c.weight = this.weight;
        return c;
    }
}
import {action, computed, decorate, observable} from 'mobx';
import {RootStore} from '../../RootStore';
import {
    ConfirmationDialogStore,
    DataTableStore,
    DateFieldStore,
    RowDefinition,
    SelectorFieldStore,
    SnackMessageStore,
    SnackType,
    TextFieldStore
} from '../../common';
import {
    IDeleteProjectRequestPayload,
    IProjectDetailsReplyPayload,
    ISaveProjectRequestPayload,
    ProjectStatus
} from '../../dtos';
import {HomeApi} from '../home/HomeApi';
import {Concept, Project, ProjectConcept} from '../../model';
import {ConceptRowData} from './ConceptRowData';
import {ConceptEditorStore} from './ConceptEditorStore';

export class ProjectEditorScreenStore {
    private _id = observable.box<number | undefined>(undefined);
    public name: TextFieldStore;
    public description: TextFieldStore;
    public status: SelectorFieldStore<ProjectStatus>;
    public creationTime: DateFieldStore;
    public readonly conceptsTableStore: DataTableStore<ConceptRowData>;
    private _isBusy = observable.box(false);
    public readonly snackStore: SnackMessageStore;
    public readonly confirmationDialogStore: ConfirmationDialogStore;
    private _project = observable.box<Project | undefined>(undefined);
    public readonly conceptEditorStore: ConceptEditorStore;
    private _concepts = observable.array<Concept>();

    public constructor(public rootStore: RootStore, private readonly homeApi: HomeApi, snack: SnackMessageStore) {
        this.name = new TextFieldStore('Name', '');
        this.description = new TextFieldStore('Description', '');
        this.status = new SelectorFieldStore<ProjectStatus>('Status', [
            ProjectStatus.Closed,
            ProjectStatus.Evaluation,
            ProjectStatus.Results
        ], ProjectStatus.Closed);
        this.creationTime = new DateFieldStore('Created');
        this.conceptsTableStore = new DataTableStore<ConceptRowData>([{
            key: 'name',
            header: 'Concept',
            getCellValue: row => row.data.name,
            sortFunction: (a, b) => a.data.name.localeCompare(b.data.name), // TODO create reasonable defaults
        }, {
            key: 'weight',
            header: 'Weight',
            getCellValue: row => row.data.weight,
            sortFunction: (a, b) => a.data.weight - b.data.weight,
        }]);
        this.snackStore = snack;
        this.confirmationDialogStore = new ConfirmationDialogStore('Delete confirmation', '');
        this.conceptEditorStore = new ConceptEditorStore('Edit concept', homeApi, snack);
    }

    public get id(): number | undefined {
        return this._id.get();
    }

    public get isBusy(): boolean {
        return this._isBusy.get();
    }

    public get project(): Project | undefined {
        return this._project.get();
    }

    public get concepts(): Concept[] {
        return this._concepts;
    }

    public get canSave(): boolean {
        if (this.name.value.length < 1) {
            return false;
        }
        if (this.description.value.length < 1) {
            return false;
        }

        if (this.isBusy) {
            return false;
        }
        return true;
    }

    private clear() {
        this.name.clear();
        this.description.clear();
        this.status.clear();
        this.creationTime.clear();
    }

    public fetch = async (id: number | undefined): Promise<void> => {
        this._isBusy.set(true);
        try {
            const payload: IProjectDetailsReplyPayload = await this.homeApi.fetchProjectDetails({projectId: id});
            const project = Project.fromDto(payload.project);
            const concepts = payload.concepts.map(d => Concept.fromDto(d));
            this._project.set(project);
            this._concepts.replace(concepts);
            this.name.setValue(project.name);
            this.description.setValue(project.description);
            this.status.setValue(project.status);
            this.creationTime.setValue(project.creationTime);

            const projectConcepts = project.projectConcepts || [];
            const conceptRows = projectConcepts.map((pc, i) => {
                const row = new RowDefinition<ConceptRowData>(String(i), new ConceptRowData(pc));
                return row;
            });
            this.conceptsTableStore.setRows(conceptRows);

            this._id.set(project.id);
        } catch (e) {
            this.snackStore.open(`Failed to fetch project details. ${e.message}`, SnackType.Error);
            this.clear();
        } finally {
            this._isBusy.set(false);
        }
    };

    public reset = async (): Promise<void> => {
        this.setId(undefined);
        this.fetch(undefined);
        // this.name.setValue('');
        // this.description.setValue('');
        // this.status.setValue(ProjectStatus.Closed);
        //
        // const contribution = new ProjectConcept();
        // contribution.concept =
        // const defaultConcepts: RowDefinition<ConceptRowData>[] = [
        //     new RowDefinition<ConceptRowData>('0', new ConceptRowData(new ProjectConcept()))
        // ]
        //
        // this.conceptsTableStore.setRows(defaultConcepts);
    };

    public cancel = () => {
        this.rootStore.routerStore.goBack();
    };

    public goBack = () => {
        this.rootStore.routerStore.goBack();
    };

    public addConcept = async () => {
        const projectConcept = new ProjectConcept();
        projectConcept.project = this.project;
        projectConcept.weight = 0;
        const result = await this.conceptEditorStore.open(this.concepts, projectConcept);
        if (result.isConfirmed) {
            const projectConcept = new ProjectConcept();
            projectConcept.concept = new Concept();
            projectConcept.concept.name = result.conceptName;
            projectConcept.weight = result.weight;

            const row = new RowDefinition<ConceptRowData>(
                projectConcept.concept!.name!,
                new ConceptRowData(projectConcept));
            this.conceptsTableStore.rows.push(row);
        }
    }

    public editConcept = async () => {
        const selectedRows = this.conceptsTableStore.selectedRows;
        if (selectedRows.length < 1) {
            return;
        }
        const rowToEdit = selectedRows[0];
        const result = await this.conceptEditorStore.open(this.concepts, rowToEdit.data.projectConcept);
        if (result.isConfirmed) {
            const projectConcept = rowToEdit.data.projectConcept.clone();
            if (result.conceptName !== projectConcept.concept!.name) {
                let concept = this.concepts.find(c => c.name === result.conceptName);
                if (!concept) {
                    concept = new Concept();
                    concept.name = result.conceptName;
                }
                projectConcept.concept = concept;
            }
            rowToEdit.setData(new ConceptRowData(projectConcept));
        }
    }

    public removeConcept = async () => {
        const selectedRows = this.conceptsTableStore.selectedRows
        if (selectedRows.length < 1) {
            return;
        }
        this.conceptsTableStore.removeSelectedRows();
    }

    public save = async () => {
        this._isBusy.set(true);
        let project = new Project(
            this.name.value,
            this.description.value,
            this.creationTime.value,
            this.status.value
        );
        project.id = this.id;
        const isNewProject = project.id == null;
        project.projectConcepts = this.conceptsTableStore.rows.map(row => {
            return row.data.projectConcept;
        });
        try {
            const reqPayload: ISaveProjectRequestPayload = {
                project
            };
            const repPayload = await this.homeApi.saveProjectDetails(reqPayload);
            project = Project.fromDto(repPayload.userProject.project!);
            this.name.setValue(project.name);
            this.description.setValue(project.description);
            this.creationTime.setValue(project.creationTime);
            this.status.setValue(project.status);
            this._id.set(project.id);
            const message = isNewProject ? `Project created` : `Project saved`;
            this.snackStore.open(message, SnackType.Success);
            this.goBack();
        } catch (e) {
            this.snackStore.open(`Failed to ${isNewProject ? 'create' : 'save'} project. ${e.message}`);
        } finally {
            this._isBusy.set(false);
        }
    };

    public setId = (projectId: number | undefined) => {
        this._id.set(projectId);
    };

    public delete = async () => {
        this.confirmationDialogStore.setContentText(
            `Are you sure you want to delete project "${this.name.value}"?`);
        const confirmationResult = await this.confirmationDialogStore.open();
        if (confirmationResult.isConfirmed) {
            this.deleteReally();
        }
    };

    private deleteReally = async (): Promise<void> => {
        this._isBusy.set(true);
        try {
            const reqPayload: IDeleteProjectRequestPayload = {
                projectId: this.id!
            };
            const repPayload = await this.homeApi.deleteProject(reqPayload);
            const message = repPayload.message;
            this.clear();
            this.snackStore.open(message, SnackType.Success);
            this.goBack();
        } catch (e) {
            this.snackStore.open(`Failed to delete project. ${e.message}`);
        } finally {
            this._isBusy.set(false);
        }
    }
}

decorate(ProjectEditorScreenStore, {
    id: computed,
    fetch: action,
    reset: action,
    cancel: action,
    goBack: action,
    save: action,
    setId: action,
    isBusy: computed,
    delete: action,
    snackStore: observable,
    canSave: computed,
    addConcept: action,
    editConcept: action,
    removeConcept: action,
    project: computed,
    concepts: computed,
});
import {action, computed, decorate, observable, reaction} from 'mobx';
import {Concept, ProjectConcept} from '../../model';
import {AutocompleteFieldStore, IAutocompleteSuggestion, NumericFieldStore} from '../../common/fields';
import {getLogger, SnackMessageStore, SnackType} from '../../common';
import {HomeApi} from '../home';

export interface ConceptEditorResult {
    conceptName: string;
    weight: number;
    isConfirmed: boolean;
}

export class ConceptEditorStore {
    private readonly _log = getLogger(`ConceptEditorStore`);

    private _isOpen = observable.box<boolean>(false);
    private _title = observable.box<string>('');
    private _projectConcept = observable.box<ProjectConcept>();
    private _promise?: Promise<ConceptEditorResult>;
    private _resolve?: (value: ConceptEditorResult) => void;
    public concept: AutocompleteFieldStore;
    public weight: NumericFieldStore;

    private readonly _conceptQuery = observable.box<string>('');
    private readonly _isSearchInProgress = observable.box<boolean>(false);
    private readonly _suggestedConcepts = observable.array<Concept>();
    private readonly _allConcepts = observable.array<Concept>();

    public readonly snack: SnackMessageStore;
    private _isBusy = observable.box(false);

    public constructor(title: string, private readonly homeApi: HomeApi, snack: SnackMessageStore) {
        this.snack = snack;
        this._title.set(title);
        this.weight = new NumericFieldStore('Weight', 0, 2);
        this.concept = new AutocompleteFieldStore('Concept', undefined);

        reaction(() => {
            return {
                concept: this.concept.value,
                searchQuery: this.concept.searchQuery,
                query: this.conceptQuery,
                isInProgress: this.isSearchInProgress,
            };
        }, ({concept, searchQuery, query, isInProgress}) => {
            this._log.debug(`Reaction ["${concept ? JSON.stringify(concept.value) : null}", "${searchQuery}", "${query}", "${isInProgress}"]`);
            if (!searchQuery) {
                return;
            }
            if (searchQuery !== query && !isInProgress) {
                this._conceptQuery.set(searchQuery);
                this.findConcept();
            }
        });

        reaction(() => {
            return this.concept.value;
        }, (conceptName: IAutocompleteSuggestion | undefined) => {
            if (!conceptName) {
                return;
            }
            this._log.debug(`Reaction on concept name ${conceptName.value}`);
            const concept = this._suggestedConcepts.find(x => x.name === conceptName.value);
            if (concept) {
                this._log.debug(`Concept found, setting`);
                // TODO set other fields related to concept if any. Default weight?
            } else {
                // Clear other fields related to concept if any. Default weight?
            }
        });
    }

    public findConcept = async () => {
        this._log.debug(`Looking for concepts. Name = "${this.concept.input}"`);
        try {
            this._isSearchInProgress.set(true);
            this._isBusy.set(true);
            this.concept.setIsLoading(true);

            // TODO make proper api call and search on the backend
            // const request: IFindConceptRequestPayload = {
            //     name: this.conceptQuery
            // };
            // const reply = await this.homeApi.findConcept(request);

            const suggestions = this._allConcepts.filter(x => {
                if (!x.name) {
                    return false;
                }
                if (x.name.includes(this.conceptQuery)) {
                    return true;
                }
                return false;
            });

            // this._suggestedConcepts.replace(reply.concepts.map(Concept.fromDto));
            this._suggestedConcepts.replace(suggestions);

            // this._log.debug(`Suggestions found: ${reply.concepts.map(x => x.name).join(', ')}`);
            this.concept.setSuggestions(this._suggestedConcepts.map(x => ({
                label: x.name || '',
                extendedLabel: x.name || '',
                value: x.name || ''
            })));
        } catch (error) {
            this.snack.open(`Exception when searching for concepts. "${error.message}"`, SnackType.Error);
        } finally {
            this.concept.setIsLoading(false);
            this._isBusy.set(false);
            this._isSearchInProgress.set(false);
        }
    };

    public get conceptQuery(): string {
        return this._conceptQuery.get();
    }

    public get isSearchInProgress(): boolean {
        return this._isSearchInProgress.get();
    }

    public setTitle = (title: string) => {
        this._title.set(title);
    };

    public get title(): string {
        return this._title.get();
    }

    public get isOpen(): boolean {
        return this._isOpen.get();
    }

    public get projectConcept(): ProjectConcept {
        return this._projectConcept.get();
    }

    public open = async (concepts: Concept[], projectConcept: ProjectConcept): Promise<ConceptEditorResult> => {
        if (this.isOpen) {
            return this._promise!;
        }
        this._promise = new Promise((resolver) => {
            this._resolve = resolver;
        });
        this._isOpen.set(true);
        this._projectConcept.set(projectConcept);

        this._allConcepts.replace(concepts);
        //this.concept.options.replace(concepts);
        if (projectConcept.concept !== undefined) {
            const concept = projectConcept.concept;
            const value: IAutocompleteSuggestion = {
                label: concept.name || '',
                extendedLabel: concept.name || '',
                value: concept.name || ''
            };
            this.concept.setValue(value, 'select-option'); // TODO better way of initializing autocomplete
        } else {
            this.concept.clear();
        }
        // this.concept.setValue(projectConcept.concept);
        this.weight.setValue(projectConcept.weight);
        this.setTitle(`Edit concept"`);
        return this._promise;
    };

    public close = (reply?: boolean) => {
        if (!this.isOpen) {
            return;
        }
        this._resolve!({
            isConfirmed: !!reply,
            conceptName: this.concept.value!.value,
            weight: this.weight.value!, // TODO check value presence in canSave
        });
        this._resolve = undefined;
        this._promise = undefined;
        this._isOpen.set(false);
    };
}

decorate(ConceptEditorStore, {
    setTitle: action,
    title: computed,
    isOpen: computed,
    open: action,
    close: action,
    projectConcept: computed,
    concept: observable,
    weight: observable,
});
import {ApiFactory} from '../../common';
import {
    IResultsReplyPayload,
    IResultsRequestPayload,
    ISaveEvaluationReviewsReplyPayload,
    ISaveEvaluationReviewsRequestPayload
} from '../../dtos';
import {Urls} from '../../shared/urls';

export class ResultsApi {
    constructor(private readonly apiFactory: ApiFactory) {
    }

    public fetchResults = this.apiFactory.createApiFunc<IResultsRequestPayload, IResultsReplyPayload>(Urls.getInstructorsResults);
    public saveEvaluationReviews = this.apiFactory.createApiFunc<ISaveEvaluationReviewsRequestPayload, ISaveEvaluationReviewsReplyPayload>(Urls.saveEvaluationReviews);
}
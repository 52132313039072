import {Student, Team, User, UserProject} from '../../../model';
import {computed, decorate, observable} from 'mobx';
import {EvaluationCellStore} from './EvaluationCellStore';
import {NumericFieldStore} from '../../../common/fields';

export class ResultsRowData {
    private _userProject = observable.box<UserProject>();
    public evaluationCells = observable.array<EvaluationCellStore>();
    private _index = observable.box<number | undefined>();
    public readonly consistency: NumericFieldStore;
    public readonly bonusForEvaluations: NumericFieldStore;
    public readonly contribution: NumericFieldStore;

    public constructor(userProject: UserProject) {
        this._userProject.set(userProject);
        const tss = this.team?.teamStudents || [];
        const s = this.student;
        const evaluationCells = tss.map((ts) => {
            const e = s.receivedEvaluationsByEvaluator?.get(ts.studentId!);
            return new EvaluationCellStore(e);
        });
        this.evaluationCells.replace(evaluationCells);
        this.consistency = new NumericFieldStore('Consistency', undefined);
        this.bonusForEvaluations = new NumericFieldStore('Bonus for Evaluations', undefined);
        this.contribution = new NumericFieldStore('Contribution', undefined);
    }

    public get userProject(): UserProject {
        return this._userProject.get();
    }

    public get index(): number | undefined {
        const t = this.team;
        const tss = this.student.teamStudents;
        if (!t || !tss || tss.length < 1) {
            return undefined;
        }
        const ts = tss[0];
        return t.teamStudents!.indexOf(ts);
    }

    public get user(): User {
        return this.userProject.user!;
    }

    public get student(): Student {
        return this.user.student!;
    }

    public get team(): Team | undefined {
        const teamStudents = this.student.teamStudents;
        if (teamStudents && teamStudents.length > 0) {
            return teamStudents[0].team;
        }
        return undefined;
    }

    public get teamName(): string {
        return this.team ? this.team.name : '';
    }

    public toString = () => {
        const s = [`UserProject: ${this._userProject.get().toString()}`];
        if (this.evaluationCells.length > 0) {
            s.push(`Evaluations: [`);
            s.push(this.evaluationCells.map(c => c.toString()).join(', '));
            s.push(`]`);
        }
        return s.join(', ');
    }
}

decorate(ResultsRowData, {
    userProject: computed,
    user: computed,
    team: computed,
    teamName: computed,
    contribution: observable,
    index: computed,
    bonusForEvaluations: observable,
    consistency: observable,
});
import {Evaluation} from './Evaluation';
import {ISentenceDto} from '../dtos';
import {SentenceConcept} from './SentenceConcept';

export class Sentence {
    public id?: number;
    public evaluation?: Evaluation;
    public evaluationId?: number;
    public position?: number;
    public text?: string;
    public sentenceConcepts?: SentenceConcept[];

    public static fromDto = (d: ISentenceDto): Sentence => {
        const s = new Sentence();
        s.id = d.id;
        s.evaluation = d.evaluation ? Evaluation.fromDto(d.evaluation) : undefined;
        s.evaluationId = d.evaluation ? d.evaluation.id : d.evaluationId;
        s.position = d.position;
        s.text = d.text;
        s.sentenceConcepts = d.sentenceConcepts ? d.sentenceConcepts.map(SentenceConcept.fromDto) : undefined;
        return s;
    };

    public toDto = (): ISentenceDto => {
        return {
            id: this.id,
            evaluation: this.evaluation ? this.evaluation.toDto() : undefined,
            evaluationId: this.evaluation ? this.evaluation.id : this.evaluationId,
            position: this.position,
            text: this.text,
            sentenceConcepts: this.sentenceConcepts ? this.sentenceConcepts.map(x => x.toDto()) : undefined,
        };
    }
}
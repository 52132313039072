import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {observer} from 'mobx-react';
import {LineChart, Line, CartesianGrid, XAxis, YAxis, ComposedChart, Tooltip, Legend, Bar} from 'recharts';
import {useStore} from '../../storeProvider';

const useStyles = makeStyles((theme: Theme) => createStyles({
    flex: {flex: 1},
}));

export interface IContributionDiagramProps {
}

export const ContributionDiagram: React.FC<IContributionDiagramProps> = observer(props => {
    const classes = useStyles(props);
    const {analyticsScreenStore} = useStore();

    const data = analyticsScreenStore.data?.contributionDiagram || [];

    // const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}];

    const width = 800;
    const height = 600;

    return (
        <div>
            <LineChart width={width} height={height} data={data} margin={{top: 5, right: 20, bottom: 5, left: 0}}>
                <Line type="monotone" dataKey="value" stroke="#8884d8"/>
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                <XAxis dataKey="name"/>
                <YAxis/>
            </LineChart>
            <ComposedChart
                width={width}
                height={height}
                data={data}
                margin={{
                    top: 20, right: 20, bottom: 20, left: 20,
                }}
            >
                <CartesianGrid stroke="#f5f5f5"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip/>
                {/*<Legend/>*/}
                <Bar dataKey="value" barSize={60} fill="#413ea0"/>
                <Line type="monotone" dataKey="value" stroke="#ff7300"/>
            </ComposedChart>
        </div>
    );
});

import {action, computed, decorate, observable, reaction} from 'mobx';
import {RootStore} from '../../RootStore';
import {
    AutocompleteFieldStore,
    getLogger,
    IAutocompleteSuggestion,
    SnackMessageStore,
    SnackType,
    TextFieldStore
} from '../../common';
import {User} from '../../model';
import {InstructorsApi} from './InstructorsApi';
import {IAddInstructorsRequestPayload, IFindUserRequestPayload} from '../../dtos';

export class InstructorScreenStore {
    private readonly _instructorId = observable.box<number | undefined>(undefined);
    private readonly _projectId = observable.box<number | undefined>(undefined);

    public readonly login: AutocompleteFieldStore;
    public readonly email: TextFieldStore;
    public readonly password: TextFieldStore;
    public readonly name: TextFieldStore;
    public readonly snack: SnackMessageStore;

    private readonly _isBusy = observable.box<boolean>(false);

    private readonly _userQuery = observable.box<string>('');
    private readonly _isSearchInProgress = observable.box<boolean>(false);
    private readonly _suggestedUsers = observable.array<User>();

    private readonly _log = getLogger('InstructorScreenStore');

    public constructor(
        public rootStore: RootStore,
        private readonly api: InstructorsApi,
        snack: SnackMessageStore
    ) {
        this.snack = snack;
        this.login = new AutocompleteFieldStore('Login', undefined);
        this.email = new TextFieldStore('Email', '');
        this.password = new TextFieldStore('Password', '');
        this.name = new TextFieldStore('Name', '');

        reaction(() => {
            return {
                login: this.login.value,
                searchQuery: this.login.searchQuery,
                query: this.userQuery,
                isInProgress: this.isSearchInProgress
            };
        }, ({login, searchQuery, query, isInProgress}) => {
            this._log.debug(`Reaction ["${login ? JSON.stringify(login.value) : null}", "${searchQuery}", "${query}", "${isInProgress}"]`);
            if (!searchQuery) {
                return;
            }
            if (searchQuery !== query && !isInProgress) {
                this._userQuery.set(searchQuery);
                this.findUser();
            }
            // We have searched this user/query (or we are searching right now), so there is nothing else to do
        });

        reaction(() => {
            return this.login.value;
        }, (login: IAutocompleteSuggestion | undefined) => {
            if (!login) {
                return;
            }
            this._log.debug(`Reaction on login value ${login.value}`);
            const user = this._suggestedUsers.find(x => x.login === login.value);
            if (user) {
                this._log.debug(`User found, setting email, name`);
                this.email.setValue(user.email);
                this.name.setValue(user.name);
                this.password.setValue('');
                this.password.setDisabled(true);
            } else {
                this.password.setDisabled(false);
            }
        });
    }

    public findUser = async () => {
        this._log.debug(`Looking for users. Login = "${this.login.input}"`);
        try {
            this._isSearchInProgress.set(true);
            this._isBusy.set(true);
            this.login.setIsLoading(true);
            const request: IFindUserRequestPayload = {
                login: this.userQuery,
                projectId: this.projectId,
            };
            const reply = await this.api.findUser(request);
            this._suggestedUsers.replace(reply.users.map(User.fromDto));
            this._log.debug(`Suggestions found: ${reply.users.map(x => x.login).join(', ')}`);
            this.login.setSuggestions(this._suggestedUsers.map(x => ({
                label: x.login,
                extendedLabel: `${x.login} (${x.name} ${x.email})`,
                value: x.login
            })));
            this.login.setIsOpen(true);
        } catch (error) {
            this.snack.open(`Exception when searching for an existing user. ${error.message}`, SnackType.Error);
        } finally {
            this.login.setIsLoading(false);
            this._isBusy.set(false);
            this._isSearchInProgress.set(false);
        }
    };

    public get instructorId(): number | undefined {
        return this._instructorId.get();
    }

    public get projectId(): number | undefined {
        return this._projectId.get();
    }

    public get isBusy(): boolean {
        return this._isBusy.get();
    }

    public get userQuery(): string {
        return this._userQuery.get();
    }

    public get isSearchInProgress(): boolean {
        return this._isSearchInProgress.get();
    }

    public goBack = () => {
        this.rootStore.routerStore.goBack();
    };

    public setProjectId = (projectId: number | undefined) => {
        this._projectId.set(projectId);
    };

    public setInstructorId = (instructorId: number | undefined) => {
        this._instructorId.set(instructorId);
    };

    public clear = () => {
        this.login.clear();
        this.name.clear();
        this.email.clear();
        this.password.clear();
    };

    public save = async () => {
        try {
            const projectId = this.projectId;
            const user = new User();
            user.login = this.login.value!.value;
            user.name = this.name.value;
            user.email = this.email.value;
            user.password = this.password.value;
            const users = [user];
            const reqPayload: IAddInstructorsRequestPayload = {
                projectId,
                users
            };
            await this.api.addInstructors(reqPayload);
            this.snack.open(`Instructor added`, SnackType.Success);
            this.goBack();
            this.clear();
        } catch (e) {
            this.snack.open(`Failed to add instructor. ${e.message}`, SnackType.Error);
        } finally {
            this._isBusy.set(false);
        }
    };
}

decorate(InstructorScreenStore, {
    login: observable,
    email: observable,
    userQuery: computed,
    isSearchInProgress: computed,
    goBack: action,
    setProjectId: action,
    setInstructorId: action,
    save: action,
    findUser: action,
    isBusy: computed,
    instructorId: computed,
    projectId: computed,
    clear: action,
});
import {User} from './User';
import {TeamStudent} from './TeamStudent';
import {IStudentDto} from '../dtos';
import {Evaluation} from './Evaluation';
import {mapBy} from '../shared/utils';

export class Student {
    public id?: number = undefined;
    public user?: User;
    public teamStudents?: TeamStudent[];
    public receivedEvaluations?: Evaluation[];
    public submittedEvaluations?: Evaluation[];

    public receivedEvaluationsByEvaluator?: Map<number, Evaluation>;
    public submittedEvaluationsByEvaluated?: Map<number, Evaluation>;

    public addSubmittedEvaluation = (e: Evaluation) => {
        if (!this.submittedEvaluations) {
            this.submittedEvaluations = [];
        }
        this.submittedEvaluations.push(e);
        if (!this.submittedEvaluationsByEvaluated) {
            this.submittedEvaluationsByEvaluated = new Map();
        }
        this.submittedEvaluationsByEvaluated.set(e.evaluatedId!, e);
    };

    public addReceivedEvaluation = (e: Evaluation) => {
        if (!this.receivedEvaluations) {
            this.receivedEvaluations = [];
        }
        this.receivedEvaluations.push(e);
        if (!this.receivedEvaluationsByEvaluator) {
            this.receivedEvaluationsByEvaluator = new Map();
        }
        this.receivedEvaluationsByEvaluator.set(e.evaluatorId!, e);
    };

    public static fromDto = (d: IStudentDto): Student => {
        const s = new Student();
        s.id = d.id;
        s.user = d.user ? User.fromDto(d.user) : undefined;
        s.teamStudents = d.teamStudents ? d.teamStudents.map(x => TeamStudent.fromDto(x)) : undefined;
        s.receivedEvaluations = d.receivedEvaluations ? d.receivedEvaluations.map(Evaluation.fromDto) : undefined;
        s.submittedEvaluations = d.submittedEvaluations ? d.submittedEvaluations.map(Evaluation.fromDto) : undefined;
        s.receivedEvaluationsByEvaluator = s.receivedEvaluations ? mapBy(s.receivedEvaluations, x => x.evaluatorId!) : undefined;
        s.submittedEvaluationsByEvaluated = s.submittedEvaluations ? mapBy(s.submittedEvaluations, x => x.evaluatedId!) : undefined;
        return s;
    };

    public toDto = (): IStudentDto => {
        return {
            id: this.id,
            user: this.user ? this.user.toDto() : undefined,
            teamStudents: this.teamStudents ? this.teamStudents.map(x => x.toDto()) : undefined,
            receivedEvaluations: this.receivedEvaluations ? this.receivedEvaluations.map(x => x.toDto()) : undefined,
            submittedEvaluations: this.submittedEvaluations ? this.submittedEvaluations.map(x => x.toDto()) : undefined,
        };
    };
}
import {IUserDto} from './IUserDto';
import {IUserProjectDto} from '../dtos/IUserProjectDto';
import {ITeamDto} from './ITeamDto';
import {IEvaluationDto} from './IEvaluationDto';
import {IProjectConceptDto} from './IProjectConceptDto';
// import {IProjectInstructorDto} from '../../../grademe-server/src/dtos';

export enum ProjectStatus {
    Closed = 'Closed',
    Results = 'Results',
    Evaluation = 'Evaluation',
}

export interface IProjectDto {
    id?: number;
    name: string;
    description: string;
    creationTime?: Date;
    author?: IUserDto;
    userProjects?: IUserProjectDto[];
    teams?: ITeamDto[];
    evaluations?: IEvaluationDto[];
    status?: ProjectStatus;
    projectConcepts?: IProjectConceptDto[];
    // projectInstructors?: IProjectInstructorDto[];
}
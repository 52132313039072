import {observable, computed, action, decorate} from 'mobx';

export class TextFieldStore {
    public label: string;
    private _value = observable.box('');
    private _isValid = observable.box(true);
    private _isDisabled = observable.box(false);

    constructor(label: string, value: string) {
        this.label = label;
        this.setValue(value);
    }

    public setLabel(label: string): void {
        this.label = label;
    }

    public setValue(value: string): void {
        this._value.set(value);
    }

    public setDisabled(value: boolean): void {
        this._isDisabled.set(value);
    }

    public get value(): string {
        return this._value.get();
    }

    public get isValid(): boolean {
        return this._isValid.get();
    }

    public get isDisabled(): boolean {
        return this._isDisabled.get();
    }

    public clear = () => {
        this._value.set('');
        this._isValid.set(true);
    }
}

decorate(TextFieldStore, {
    label: observable,
    setLabel: action,
    setValue: action,
    setDisabled: action,
    isDisabled: computed,
    value: computed,
    isValid: computed,
    clear: action,
});
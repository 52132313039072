import {RowDefinition, SelectionMode, DataTableStore} from '../../common';
import {decorate, observable} from 'mobx';
import {RootStore} from '../../RootStore';
import {StudentGrades} from './StudentGrades';

// // n starts with 0
// const makeGradeColumn = (n: number): IColumnDefinition<ResultsRowData> => {
//     return {
//         key: `grade${n + 1}`,
//         header: `Grade ${n + 1}`,
//         getCellValue: row => row.data.grades.length <= n ? null : row.data.grades[n],
//         sortFunction: () => 0
//     };
// };

export class StudentGradesScreenStore {
    public readonly gradesTableStore: DataTableStore<StudentGrades>;

    // public readonly detailedViewStore: DetailedViewStore;

    public constructor(public rootStore: RootStore) {
        this.gradesTableStore = new DataTableStore<StudentGrades>([{
            key: 'name',
            header: 'Name',
            getCellValue: (row) => row.data.name,
            sortFunction: () => 0,
        }, {
            key: 'gradeTo',
            header: 'Grade To',
            getCellValue: row => row.data.gradeTo,
            sortFunction: (a, b) => a.data.gradeTo - b.data.gradeTo,
        }, {
            key: 'gradeFrom',
            header: 'Grade From',
            getCellValue: row => row.data.gradeFrom,
            sortFunction: (a, b) => a.data.gradeFrom - b.data.gradeFrom,
        }], SelectionMode.Cell);

        const rows: RowDefinition<StudentGrades>[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((x, i) => {
            return new RowDefinition(String(i), new StudentGrades());
        });

        this.gradesTableStore.setRows(rows);
    }

    public goBack = () => {
        this.rootStore.routerStore.goBack();
    }
}

decorate(StudentGradesScreenStore, {
    gradesTableStore: observable,
});
import React from 'react';
import {TableBody} from '@material-ui/core';
import {observer} from 'mobx-react';
import {DataTableStore} from '../model/DataTableStore';
import {DataTableRow} from './DataTableRow';

export interface IDataTableBodyProps<T> {
    store: DataTableStore<T>;
}

export const DataTableBody = observer(function <T>(props: IDataTableBodyProps<T>) {
    const {store} = props;

    return (
        <TableBody>
            {store.rows.map((row, rowIndex) => {
                return (
                    <DataTableRow key={row.id} store={store} rowIndex={rowIndex}/>
                );
            })}
        </TableBody>
    );
});
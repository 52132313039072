import * as _ from 'lodash';
import {User} from '../../model';

export const instructorsCsvParser = (csv: string): User[] => {
    const expectedRowCount = 4;
    csv = _.replace(csv, /\r\n/, '\n');
    csv = _.trim(csv, '\n');
    const lines = csv.split(/\n/);
    const cells = lines.map(x => x.split('\t'));
    const users = cells.map((x, ln) => {
        if (!x) {
            throw new Error(`Bad CSV. Ln ${ln} is empty`);
        }
        if (x.length !== expectedRowCount) {
            throw new Error(`Bad CSV. Ln ${ln} has ${x.length} elements, ${expectedRowCount} elements expected`);
        }
        const [name, login, email, password] = x;
        const user: User = new User();
        user.name = name;
        user.email = email;
        user.password = password;
        user.login = login;
        return user;
    });
    return users;
};
import {ProjectConcept} from '../../model';

export class ConceptRowData {
    public projectConcept: ProjectConcept;

    public constructor(projectConcept: ProjectConcept) {
        this.projectConcept = projectConcept;
    }

    public get name(): string {
        return this.projectConcept?.concept?.name || '';
    }

    public get weight(): number {
        return this.projectConcept?.weight || 0;
    }
}
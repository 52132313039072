import {createRequestDto, IReplyDto} from '../dtos';
import axios, {AxiosResponse} from 'axios';
import { RouterStore } from 'mobx-react-router';

export class ApiFactory {
    constructor(private readonly routerStore: RouterStore) {

    }

    public createApiFunc = <TRequestPayload, TReplyPayload>(path: string[] | string): (payload: TRequestPayload) => Promise<TReplyPayload> => {
        const url: string = (Array.isArray(path)) ? path.join('/') : path;
        const apiFunc = async (payload: TRequestPayload): Promise<TReplyPayload> => {
            let reply: IReplyDto<TReplyPayload>;
            try {
                const request = createRequestDto(payload);
                const response: AxiosResponse<IReplyDto<TReplyPayload>> = await axios.post(url, request);
                reply = response.data;
            } catch (e) {
                if (e.response && e.response.data && e.response.data.statusCode === 401) {
                    this.routerStore.push('/login');
                }
                throw e;
            }
            if (reply.isOk) {
                return reply.payload;
            } else {
                throw new Error(reply.errorMessage);
            }
        };
        return apiFunc;
    };
}

// export const createApiFunc = <TRequestPayload, TReplyPayload>(path: string[] | string): (payload: TRequestPayload) => Promise<TReplyPayload> => {
//     const url: string = (Array.isArray(path)) ? path.join('/') : path;
//     const apiFunc = async (payload: TRequestPayload): Promise<TReplyPayload> => {
//         let reply: IReplyDto<TReplyPayload>;
//         try {
//             const request = createRequestDto(payload);
//             const response: AxiosResponse<IReplyDto<TReplyPayload>> = await axios.post(url, request);
//             reply = response.data;
//         } catch (e) {
//             if (e.response && e.response.data && e.response.data.statusCode === 401) {
//
//             }
//             throw e;
//         }
//         if (reply.isOk) {
//             return reply.payload;
//         } else {
//             throw new Error(reply.errorMessage);
//         }
//     };
//     return apiFunc;
// };
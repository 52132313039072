import {action, computed, decorate, observable} from 'mobx';

export enum SnackType {
    Error = 'Error',
    Warning = 'Warning',
    Info = 'Info',
    Success = 'Success'
}

export class SnackMessageStore {
    private _isOpen = observable.box<boolean>(false);
    private _text = observable.box<string>('');
    private _snackType = observable.box<SnackType>(SnackType.Info);

    public get isOpen(): boolean {
        return this._isOpen.get();
    };

    public get text(): string {
        return this._text.get();
    };

    public get snackType(): SnackType {
        return this._snackType.get();
    };

    public open = (text: string, snackType: SnackType = SnackType.Info) => {
        this._text.set(text);
        this._snackType.set(snackType);
        this._isOpen.set(true);
    };

    public close = () => {
        this._isOpen.set(false);
        this._text.set('');
    };
}

decorate(SnackMessageStore, {
    isOpen: computed,
    text: computed,
    snackType: computed,
    open: action,
    close: action,
});
import * as React from 'react';
import {createStyles, makeStyles, TableCell, Theme, lighten, darken, fade} from '@material-ui/core';
import clsx from 'clsx';
import {ReviewIndicator} from './ReviewIndicator';
import {EvaluationCellStore} from './EvaluationCellStore';
import {observer} from 'mobx-react';
import {useStore} from '../../../storeProvider';

const useStyles = makeStyles((theme: Theme) => {
    const borderColor = theme.palette.type === 'light'
        ? lighten(fade(theme.palette.divider, 1), 0.88)
        : darken(fade(theme.palette.divider, 1), 0.68);
    const border = `1px solid ${borderColor}`;
    return createStyles({
        evaluationCell: {
            position: 'relative',
            borderLeft: border,
        }
    });
});

export interface IEvaluationCellProps {
    rowIndex: number;
    columnIndex: number;
    className?: string;
    onClick: React.MouseEventHandler;
    cellStore?: EvaluationCellStore;
}

export const EvaluationCellRenderer: React.FC<IEvaluationCellProps> = observer(props => {
    const store = useStore().projectResultsConfigScreenStore;
    const classes = useStyles(props);
    const {cellStore} = props;
    const quality = cellStore?.quality;
    const value = cellStore?.grade;
    const normValue = cellStore?.normalisedGrade;
    const text: string[] = [];
    if (normValue !== undefined) {
        text.push(`${normValue.toFixed(2)}`);
    }
    if (store.showAbsoluteGrades.value && value !== undefined) {
        text.push(`(${value})`);
    }
    return (
        <TableCell
            data-column-index={String(props.columnIndex)}
            className={clsx(classes.evaluationCell, props.className)}
            onClick={props.onClick}
        >
            <ReviewIndicator quality={quality}/>
            {text.join(' ')}
        </TableCell>
    );
});
import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Chip,
    createStyles, Grid,
    makeStyles,
    Slider,
    Theme,
    Typography
} from '@material-ui/core';
import {observer} from 'mobx-react';
import {Star as StarIcon} from '@material-ui/icons';
import {useStore} from '../../../storeProvider';
import {SliderWithInput} from '../SliderWithInput';

export interface IResultCardProps {

}

const useStyles = makeStyles((theme: Theme) => createStyles({
    cardAction: {
        paddingRight: theme.spacing(1),
    }
}));

export const ResultCard: React.FC<IResultCardProps> = observer(props => {
    const {resultsStore} = useStore();
    const {resultsEditorStore} = resultsStore;

    const grade = 4;

    const classes = useStyles(props);

    return (
        <Card>
            <CardHeader
                title={'Result'}
                subheader={'subheader'}
                avatar={
                    <Chip
                        icon={<StarIcon/>}
                        label={String(grade)}
                    />
                }
                action={
                    <div className={classes.cardAction}>
                        <Typography gutterBottom>
                            Evaluation quality
                        </Typography>
                        <Slider
                            defaultValue={80}
                            step={10}
                            valueLabelDisplay='auto'
                        />
                    </div>
                }
            >
            </CardHeader>
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <SliderWithInput field={resultsEditorStore.contribution}/>
                    </Grid>
                    <Grid item xs={3}>
                        <SliderWithInput field={resultsEditorStore.consistency}/>
                    </Grid>
                    <Grid item xs={3}>
                        <SliderWithInput field={resultsEditorStore.teamScore}/>
                    </Grid>
                    <Grid item xs={3}>
                        <SliderWithInput field={resultsEditorStore.individualScore}/>
                    </Grid>
                </Grid>

                {/*<AnnotationTextArea*/}
                {/*    annotationText={detailedViewStore.justification}*/}
                {/*    concepts={concepts}*/}
                {/*    disabled={false}*/}
                {/*/>*/}
                {/*<ConceptButtons*/}
                {/*    annotationText={detailedViewStore.justification}*/}
                {/*    concepts={concepts}*/}
                {/*    disabled={false}*/}
                {/*/>*/}
            </CardContent>
        </Card>
    );
});
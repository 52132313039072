import {computed, decorate, observable} from 'mobx';
import {RootStore} from '../../RootStore';
import {SelectorFieldStore, TextFieldStore} from '../../common';
import {ProjectStatus} from '../../dtos';

export class ProjectScreenStore {
    public id = observable.box<number | null>(null);
    public name: TextFieldStore;
    public description: TextFieldStore;
    public status: SelectorFieldStore<ProjectStatus>;
    private _isBusy = observable.box(false);
    public projectId = observable.box<number | null>(null);
    public concepts = observable.array<string>(['Contribution', 'Strengths', 'Suggestions for improvement']);

    public constructor(public rootStore: RootStore) {
        this.name = new TextFieldStore('Name', '');
        this.description = new TextFieldStore('Description', '');
        this.status = new SelectorFieldStore<ProjectStatus>('Status', [
            ProjectStatus.Closed,
            ProjectStatus.Evaluation,
            ProjectStatus.Results
        ], ProjectStatus.Closed);
    }

    public goBack = () => {
        this.rootStore.routerStore.goBack();
    };

    public setProjectId = (projectId: number) => {

    };

    public save = () => {

    };

    public get isBusy() {
        return this._isBusy.get();
    }
}

decorate(ProjectScreenStore, {
    isBusy: computed,
});
import {IConceptDto} from '../dtos';
import {SentenceConcept} from './SentenceConcept';
import {ProjectConcept} from './ProjectConcept';

export class Concept {
    public id?: number;
    name?: string;
    sentenceConcepts?: SentenceConcept[];
    projectConcepts?: ProjectConcept[];

    public static fromDto = (d: IConceptDto): Concept => {
        const c = new Concept();
        c.id = d.id;
        c.name = d.name;
        c.sentenceConcepts = d.sentenceConcepts ? d.sentenceConcepts.map(SentenceConcept.fromDto) : undefined;
        c.projectConcepts = d.projectConcepts ? d.projectConcepts.map(ProjectConcept.fromDto) : undefined;
        return c;
    };

    public toDto = (): IConceptDto => {
        return {
            id: this.id,
            name: this.name,
            sentenceConcepts: this.sentenceConcepts ? this.sentenceConcepts.map(x => x.toDto()) : undefined,
            projectConcepts: this.projectConcepts ? this.projectConcepts.map(x => x.toDto()) : undefined,
        };
    }

    public clone = (): Concept => {
        const c = new Concept();
        c.name = this.name;
        c.sentenceConcepts = this.sentenceConcepts;
        c.projectConcepts = this.projectConcepts;
        return c;
    }
}
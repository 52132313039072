import React from 'react';
import {createStyles, makeStyles, TableCell, Theme} from '@material-ui/core';
import {observer} from 'mobx-react';
import {getAttribute} from '../../getAttribute';
import {SelectionMode, DataTableStore} from '../model/DataTableStore';
import {ICellComponentProps} from '../model/ICellComponentProps';
import {IColumnDefinition} from '../model/IColumnDefinition';

export interface IDataTableCellProps<T> {
    store: DataTableStore<T>;
    rowIndex: number;
    column: IColumnDefinition<T>;
    columnIndex: number;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    cursor: {
        backgroundColor: theme.palette.grey['300'],
    },
    alternateRow: {
        backgroundColor: theme.palette.grey['200'],
    }
}));

export const DataTableCell = observer(function <T>(props: IDataTableCellProps<T>) {
    const {store, rowIndex, column, columnIndex} = props;
    const classes = useStyles(props);
    const row = store.rows[rowIndex];

    const onCellClick = (event: React.MouseEvent<Element>): void => {
        if (store.selectionMode === SelectionMode.Cell) {
            if (!store.setCursor) {
                return;
            }
            const dataRowIndex = getAttribute(event.target as Element, 'data-row-index');
            const dataColumnIndex = getAttribute(event.target as Element, 'data-column-index');
            const rowIndex = parseInt(dataRowIndex, 10);
            const columnIndex = parseInt(dataColumnIndex, 10);
            const column = store.columns[columnIndex];
            if (column.isFocusable) {
                store.setCursor({rowIndex, columnIndex});
            }
        } else {
            const dataRowIndex = getAttribute(event.target as Element, 'data-row-index');
            const rowIndex = parseInt(dataRowIndex, 10);
            const row = store.rows[rowIndex];
            store.selectAll(false);
            store.selectRow(row, rowIndex, true);
        }
    };

    const isCursorOnCell = (rowIndex: number, columnIndex: number) => {
        return row.cursor === columnIndex;
    };

    const getCellClassName = (isCursor: boolean, alteration: boolean, rowIndex: number): string | undefined => {
        const cursorClassName = isCursor ? classes.cursor : undefined;
        const alternateClassName = alteration ? classes.alternateRow : undefined;
        if (cursorClassName) {
            return cursorClassName;
        }
        return alternateClassName;
    };

    const isCursor = isCursorOnCell(rowIndex, columnIndex);
    const CellComponent = column.cellComponent;
    if (CellComponent) {
        const defaultCellProps: ICellComponentProps<T> = {
            row,
            rowIndex,
            column,
            columnIndex,
            className: getCellClassName(isCursor, row.alteration, rowIndex),
            onClick: onCellClick
        };
        const cellProps = column.cellPropsBuilder ? column.cellPropsBuilder(defaultCellProps) : defaultCellProps;
        return (
            <CellComponent {...cellProps}/>
        );
    }

    return (
        <TableCell
            data-column-index={String(columnIndex)}
            className={getCellClassName(isCursor, row.alteration, rowIndex)}
            onClick={onCellClick}
        >
            {column.getCellValue(row)}
        </TableCell>
    );
});
import axios from 'axios';
import {ICreateAccountReplyDto, ICreateAccountRequestDto, ISignInReplyDto, ISignInRequestDto} from '../../dtos';

// const log = getLogger('AuthApi');

export interface IAuthApi {
    createAccount: (request: ICreateAccountRequestDto) => Promise<ICreateAccountReplyDto>;
    signIn: (request: ISignInRequestDto) => Promise<ISignInReplyDto>;
    signInWithToken: (token: string) => Promise<ISignInReplyDto>;
}

export class AuthApi implements IAuthApi {
    public createAccount = async (request: ICreateAccountRequestDto): Promise<ICreateAccountReplyDto> => {
        const reply = await axios.post('/api/auth/create-account', request);
        return reply.data;
    };

    public signIn = async (request: ISignInRequestDto): Promise<ISignInReplyDto> => {
        const reply = await axios.post('/api/auth/sign-in', request);
        return reply.data;
    };

    public signInWithToken = async (token: string): Promise<ISignInReplyDto> => {
        const reply = await axios.post('/api/auth/sign-in-with-token', {token});
        return reply.data;
    }
}
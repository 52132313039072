import {action, decorate, observable} from 'mobx';

export class DateFieldStore {
    public value?: Date ;
    public label: string;

    public constructor(label: string) {
        this.label = label;
    }

    public setValue = (value?: Date) => {
        this.value = value;
    };

    public setLabel = (label: string) => {
        this.label = label;
    };

    public clear = () => {
        this.value = undefined;
    }
}

decorate(DateFieldStore, {
    value: observable,
    label: observable,
    setValue: action,
    setLabel: action,
    clear: action,
});
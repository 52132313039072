import * as React from 'react';
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    Theme
} from '@material-ui/core';
import {observer} from 'mobx-react';
import {AutocompleteFieldView, NumericFieldView} from '../../common';
import {ConceptEditorStore} from './ConceptEditorStore';

export interface IConceptEditorProps {
    store: ConceptEditorStore;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    dialog: {},
    contentGrid: {},
    flex: {flex: 1}
}));

export const ConceptEditor: React.FC<IConceptEditorProps> = observer(props => {
    const {store} = props;
    const classes = useStyles(props);

    const onSave = () => {
        store.close(true);
    };

    const onCancel = () => {
        store.close(false);
    };

    return (
        <Dialog
            className={classes.dialog}
            open={props.store.isOpen}
            onClose={onCancel}
        >
            <DialogTitle>
                {store.title}
            </DialogTitle>
            <DialogContent>
                <Grid className={classes.contentGrid} container spacing={2}>
                    <Grid item xs={12}>
                        <AutocompleteFieldView field={store.concept} autoFocus/>
                    </Grid>
                    <Grid item xs={12}>
                        <NumericFieldView field={store.weight} textFieldProps={{fullWidth: true}}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>
                    Cancel
                </Button>
                <Button onClick={onSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
});
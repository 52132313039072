import {action, computed, decorate, observable} from 'mobx';
import {Evaluation} from '../../../model/Evaluation';
import {EvaluationReview} from '../../../model/EvaluationReview';
import { mapBy } from '../../../shared';

export class EvaluationCellStore {
    private readonly _evaluation = observable.box<Evaluation | undefined>();
    private readonly _quality = observable.box<number | undefined>();
    private readonly _grade = observable.box<number | undefined>();
    private readonly _normalisedGrade = observable.box<number | undefined>();

    constructor(evaluation?: Evaluation) {
        this.setEvaluation(evaluation);
    }

    public get quality(): number | undefined {
        return this._quality.get();
    }

    public get grade(): number | undefined {
        return this._grade.get();
    }

    public get normalisedGrade(): number | undefined {
        return this._normalisedGrade.get();
    }

    public get evaluation(): Evaluation | undefined {
        return this._evaluation.get();
    }

    public setEvaluation = (e?: Evaluation) => {
        this._evaluation.set(e);
        const grade = this.evaluation?.grade;
        this._grade.set(grade);
        const ers = this.evaluation?.evaluationReviews;
        const q = ers && ers.length === 1 ? ers[0].quality : undefined;
        this._quality.set(q);
    };

    public setNormalizedGrade = (g: number | undefined) => {
        this._normalisedGrade.set(g);
    };

    public get evaluationReview(): EvaluationReview | undefined {
        const ers = this.evaluation?.evaluationReviews;
        if (!ers || ers.length === 0) {
            return undefined;
        }
        return ers[0];
    }

    public setEvaluationReview = (er: EvaluationReview) => {
        const e = this.evaluation;
        if (!e) {
            throw Error('Attempted to set evaluation review when there is no evaluation');
        }
        // Old sentence->sentenceConcepts are erased and replaced by sentenceConcepts from evaluation review
        if (e.sentences) {
            e.sentences.forEach(s => {
                s.sentenceConcepts = [];
            });

            e.evaluationReviews = er ? [er] : undefined;
            if (e.evaluationReviews) {
                const sentencesById = mapBy(e.sentences, s => s.id);
                e.evaluationReviews.forEach(r => {
                    if (r.sentenceConcepts) {
                        r.sentenceConcepts.forEach(sc => {
                            const sentence = sentencesById.get(sc.sentenceId);
                            if (sentence) {
                                sentence.sentenceConcepts!.push(sc);
                            }
                        })
                    }
                })
            }
        }
        this.setEvaluation(e);
    }

    public toString = () => {
        const e = this.evaluation;
        if (!e) {
            return `Evaluation is undefined`;
        }
        const s = [`Evaluation of "${e.evaluatedLogin}" by "${e.evaluatorLogin}"`];
        s.push(`Grade: ${this.grade}`);
        return s.join(', ');
    }
}

decorate(EvaluationCellStore, {
    quality: computed,
    grade: computed,
    evaluation: computed,
    evaluationReview: computed,
    setEvaluationReview: action,
    setEvaluation: action,
    normalisedGrade: computed,
    setNormalizedGrade: action,
});
export interface IReplyDtoBase {
    requestId: string;
}

export interface IGoodReplyDto<T> extends IReplyDtoBase {
    isOk: true;
    payload: T;
}

export interface IBadReplyDto extends IReplyDtoBase {
    isOk: false;
    errorMessage: string;
}

export type IReplyDto<T> = IGoodReplyDto<T> | IBadReplyDto;

export function isRequestSuccessful<T>(reply: IReplyDto<T>): reply is IGoodReplyDto<T> {
    return reply.isOk;
}
import {action, computed, decorate, observable} from 'mobx';
import {AutocompleteChangeReason, AutocompleteInputChangeReason} from '@material-ui/lab';

export interface IAutocompleteSuggestion {
    label: string;
    value: string;
    extendedLabel?: string;
}

export class AutocompleteFieldStore {
    public value: IAutocompleteSuggestion | undefined;
    public input: string;
    public label: string;
    private _isLoading = observable.box<boolean>(false);
    private _isOpen = observable.box<boolean>(false);
    private _searchQuery = observable.box<string>('');
    public readonly suggestions = observable.array<IAutocompleteSuggestion>();

    constructor(label: string, value: IAutocompleteSuggestion | undefined) {
        this.value = value;
        this.label = label;
        this.input = value ? value.value : '';
    }

    public setValue = (newValue: IAutocompleteSuggestion | undefined | null | string, reason: AutocompleteChangeReason) => {
        console.log(`setValue invoked ${JSON.stringify(newValue)}`);
        if (newValue == null) {
            this.value = undefined;
        } else if (reason === 'create-option' || reason === 'blur') {
            this.value = {
                label: newValue as string,
                value: newValue as string,
            };
        } else {
            this.value = newValue as IAutocompleteSuggestion;
        }
    };

    public setInput = (value: string, reason: AutocompleteInputChangeReason) => {
        console.log(`Field setInput. input="${value}"`);
        this.input = value;
        if (reason === 'input') {
            this._searchQuery.set(value);
        } else {
            this._searchQuery.set('');
        }
    };

    public setSuggestions = (suggestions: IAutocompleteSuggestion[]) => {
        this.suggestions.replace(suggestions);
    };

    public get isEmpty() {
        return this.value == null || this.value.value == null || this.value.value === '';
    };

    public clear() {
        this.setValue(undefined, 'clear');
        this.suggestions.clear();
    }

    public get isLoading(): boolean {
        return this._isLoading.get();
    }

    public setIsLoading = (isLoading: boolean) => {
        this._isLoading.set(isLoading);
    };

    public get isOpen(): boolean {
        return this._isOpen.get();
    }

    public setIsOpen = (isOpen: boolean) => {
        this._isOpen.set(isOpen);
    };

    public get searchQuery(): string {
        return this._searchQuery.get();
    }

    public setSearchQuery = (searchQuery: string) => {
        this._searchQuery.set(searchQuery);
    }
}

decorate(AutocompleteFieldStore, {
    value: observable,
    input: observable,
    label: observable,
    setValue: action,
    setInput: action,
    setSuggestions: action,
    isEmpty: computed,
    clear: action,
    isLoading: computed,
    setIsLoading: action,
    isOpen: computed,
    setIsOpen: action,
    searchQuery: computed,
    setSearchQuery: action,
});

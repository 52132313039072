import {EvaluationCellStore, ResultsRowData, ResultsTableStore} from './table';
import {RowDefinition, SnackMessageStore, SnackType} from '../../common';
import {action, computed, decorate, observable, reaction} from 'mobx';
import {RootStore} from '../../RootStore';
import {DetailedViewStore} from './review';
import {ResultsEditorStore} from './results';
import {ResultsApi} from './ResultsApi';
import {IResultsRequestPayload, ISaveEvaluationReviewsRequestPayload} from '../../dtos';
import {EvaluationReview, Project, ProjectConcept} from '../../model';
import {compareOptionalNumbers, groupBy, mapBy, Matrix} from '../../shared/utils';
import * as _ from 'lodash';
import {defaultEvaluationColumnCount} from './constants';
import {toCsv} from './CsvUtils';
import {buildUserProjectsWithStudents, calcGroupContributions} from './gradesCalculation';

const updateGroupContributions = (group: RowDefinition<ResultsRowData>[]) => {
    const data = group.map(row => row.data);
    const contribs = calcGroupContributions(data);
    const rowsByIndex = mapBy(group, row => row.data.index);
    contribs.forEach(c => {
        const r = rowsByIndex.get(c.index)!;
        r.data.contribution.setValue(c.contribution);
        r.data.bonusForEvaluations.setValue(c.bonusForEvaluations);
        r.data.consistency.setValue(c.consistency);
    });
}

export class ResultsScreenStore {
    private _id = observable.box<number | undefined>();
    private _project = observable.box<Project | undefined>();
    private _isBusy = observable.box<boolean>(false);
    public readonly snack: SnackMessageStore;
    // This helps to apply evaluation results
    private _cellsByEvaluationId: Map<number, EvaluationCellStore> = new Map();
    // Update _cellsByEvaluationId every time the rows are changed!
    public readonly resultsTableStore: ResultsTableStore;
    public readonly detailedViewStore: DetailedViewStore;
    public readonly resultsEditorStore: ResultsEditorStore;
    public readonly projectConcepts = observable.array<ProjectConcept>();

    public constructor(public rootStore: RootStore, private readonly api: ResultsApi, snack: SnackMessageStore) {
        this.snack = snack;
        this.resultsTableStore = new ResultsTableStore();
        this.detailedViewStore = new DetailedViewStore(this);
        this.resultsEditorStore = new ResultsEditorStore();

        // Open detailed view when the user clicks a grade cell
        reaction(() => {
            return this.resultsTableStore.cursor;
        }, cursor => {
            if (this.detailedViewStore.isModified) {
                this.detailedViewStore.save();
            }
            const column = this.resultsTableStore.columns[cursor.columnIndex];
            const isGradeColumn = column.key.startsWith('grade');
            if (isGradeColumn) {
                const row = this.resultsTableStore.rows[cursor.rowIndex];
                const teammateIndex = parseInt(column.key.slice(5)) - 1;
                const e = row.data.evaluationCells[teammateIndex]!.evaluation;
                if (e) {
                    this.detailedViewStore.setEvaluation(e);
                    this.detailedViewStore.open();
                } else {
                    this.detailedViewStore.setEvaluation(undefined);
                    this.detailedViewStore.close();
                }
            } else {
                // this.resultsEditorStore.open();
                this.detailedViewStore.setEvaluation(undefined);
                this.detailedViewStore.close();
            }
        });
    }

    public get id(): number | undefined {
        return this._id.get();
    }

    public get project(): Project | undefined {
        return this._project.get();
    }

    public setId = (id: number | undefined) => {
        this._id.set(id);
    };

    public get isBusy(): boolean {
        return this._isBusy.get();
    }

    public updateContributions = () => {
        const rows = this.resultsTableStore.rows;
        const groups: Array<RowDefinition<ResultsRowData>[]> = Array.from(groupBy(rows, row => row.data.team!.id).values());
        groups.forEach(group => {
            try {
                updateGroupContributions(group);
            } catch (e) {
                throw new Error(`Failed to update contributions for the group: [${group.map(r => String(r)).join(', ')}]. Cause: ${e}`);
            }
        });
    };

    public updateNormalisedGrades = () => {
        const rows = this.resultsTableStore.rows;
        const groups: Array<RowDefinition<ResultsRowData>[]> = Array.from(groupBy(rows, row => row.data.team!.id).values());
        groups.forEach(group => {
            group.sort((a, b) => compareOptionalNumbers(a.data.index, b.data.index));
            const teammateCount = group.length;
            const grades: Array<number | undefined> = [];
            group.forEach(g => {
                for (let i = 0; i < teammateCount; ++i) {
                    const cell = g.data.evaluationCells[i];
                    const e = cell?.evaluation;
                    grades.push(e ? e.grade : undefined);
                }
            });
            const mGrades = new Matrix(grades, teammateCount);
            const normalisedGrades = mGrades.clone().normalise();
            group.forEach((r, i) => {
                r.data.evaluationCells.forEach((cell, j) => {
                    cell.setNormalizedGrade(normalisedGrades.get(i, j));
                });
            });
        });
    };

    public fetchResults = async (): Promise<void> => {
        const projectId = this.id;
        if (!projectId) {
            this._project.set(undefined);
            return;
        }
        this._isBusy.set(true);
        try {
            const payload: IResultsRequestPayload = {
                projectId,
            };
            const reply = await this.api.fetchResults(payload);
            const projectConcepts = reply.project.projectConcepts!.map(ProjectConcept.fromDto);
            this.projectConcepts.replace(projectConcepts);

            const project = Project.fromDto(reply.project);
            const userProjectsWithStudents = buildUserProjectsWithStudents(project);
            const rows = userProjectsWithStudents.map(up => new RowDefinition(String(up.id), new ResultsRowData(up)));
            const maxEvaluationCellCount = _.max(rows.map(row => row.data.evaluationCells.length));
            this.resultsTableStore.rebuildColumns(maxEvaluationCellCount);

            // Building index of rows to be able to find the right row to apply updated evaluation review after saving it
            this._cellsByEvaluationId.clear();
            rows.forEach(row => {
                row.data.evaluationCells.forEach(c => {
                    if (c.evaluation) {
                        this._cellsByEvaluationId.set(c.evaluation.id!, c);
                    }
                });
            });

            project.projectConcepts = undefined;
            project.evaluations = undefined;
            project.userProjects = undefined;
            project.teams = undefined;
            this._project.set(project);

            this.resultsTableStore.setRows(rows);
            this.updateNormalisedGrades();
            this.updateContributions();
            this.snack.open(`Results loaded`, SnackType.Success);
        } catch (e) {
            this.snack.open(`Failed to load results ${e.message}`, SnackType.Error);
        } finally {
            this._isBusy.set(false);
        }
    };

    public goBack = () => {
        this.rootStore.routerStore.goBack();
    };

    public goToGrades = (row: RowDefinition<ResultsRowData>) => {
        const dest = `/grades/1/student/${row.data.student.id}`;
        this.rootStore.routerStore.push(dest);
    };

    public goToConfig = () => {
        this.rootStore.routerStore.push('/project-results-config');
    };

    public download = () => {
        const table = [];
        const header = [
            `Login`,
            `Name`,
            `Index`,
            `Team`
        ];
        for (let i = 0; i < defaultEvaluationColumnCount; ++i) {
            header.push(`G ${i + 1}`);
            header.push(`G ${i + 1} Normalised`);
        }
        header.push(`Contribution`, `Bonus for Evaluations`, `Consistency`);
        table.push(header);


        for (let row of this.resultsTableStore.rows) {
            const evaluationCells = [];
            for (let i = 0; i < defaultEvaluationColumnCount; ++i) {
                const cell = row.data.evaluationCells.length > i ? row.data.evaluationCells[i] : undefined;
                if (cell) {
                    evaluationCells.push(cell.grade);
                    evaluationCells.push(cell.normalisedGrade);
                } else {
                    evaluationCells.push('');
                    evaluationCells.push('');
                }
            }
            const cells = [
                row.data.student.user?.login,
                row.data.student.user?.name,
                row.data.index,
                row.data.team?.name,
                ...evaluationCells,
                row.data.contribution.value,
                row.data.bonusForEvaluations.value,
                row.data.consistency.value
            ];
            table.push(cells);
        }

        this.saveFile(table);
    }

    public downloadForAi = () => {
        const table: string[][] = [];
        const project = this.project;
        if (!project) {
            return;
        }
        const projectConcepts = this.projectConcepts;
        if (!projectConcepts) {
            return;
        }
        const concepts = projectConcepts.map(x => x.concept);

        const header = [
            'Sentence',
            ...concepts.map(x => x && x.name ? x.name : '')
        ];
        table.push(header);

        for (let row of this.resultsTableStore.rows) {
            for (let i = 0; i < defaultEvaluationColumnCount; ++i) {
                const cell = row.data.evaluationCells.length > i ? row.data.evaluationCells[i] : undefined;
                if (!cell) {
                    continue;
                }
                if (!cell.evaluation?.sentences) {
                    continue;
                }
                for (let sentence of cell.evaluation?.sentences) {
                    const exportRow = [];
                    exportRow.push(sentence.text || '');
                    for (let concept of concepts) {
                        if (-1 !== sentence.sentenceConcepts?.findIndex(x => x.conceptId === concept?.id)) {
                            exportRow.push('1');
                        } else {
                            exportRow.push('0');
                        }
                    }
                    table.push(exportRow);
                }
            }
        }

        let fileName = this.project?.name || 'UnknownProject';
        fileName = fileName?.replace(/[\W_]+/g, " ");
        const now = new Date();
        fileName = [fileName, now.toLocaleDateString(), '_sentences'].join(' ');
        fileName = fileName + '.csv';

        this.saveFile(table, fileName);
    }

    private saveFile = (table: Array<string | number | undefined>[], fileName?: string) => {
        if (!fileName) {
            fileName = this.project?.name || 'UnknownProject';
            fileName = fileName?.replace(/[\W_]+/g, " ");
            const now = new Date();
            fileName = [fileName, now.toLocaleDateString()].join(' ');
            fileName = fileName + '.csv';
        }

        const csvContent = toCsv(table);
        const link = document.createElement("a");
        const blob = new Blob([csvContent],{type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file named "my_data.csv".
        document.body.removeChild(link);
    }

    public saveEvaluationReview = async (er: EvaluationReview): Promise<void> => {
        this._isBusy.set(true);
        try {
            const evaluationReviews = [er].map(x => {
                x.evaluation = undefined;
                x.reviewer = undefined;
                if (x.sentenceConcepts) {
                    x.sentenceConcepts.forEach(sc => {
                        sc.sentence = undefined;
                        sc.evaluationReview = undefined;
                    })
                }
                return x.toDto();
            });
            const payload: ISaveEvaluationReviewsRequestPayload = {
                evaluationReviews,
            };
            const reply = await this.api.saveEvaluationReviews(payload);
            reply.evaluationReviews.map(EvaluationReview.fromDto).forEach(x => {
                const cell = this._cellsByEvaluationId.get(x.evaluationId!)!;
                cell.setEvaluationReview(x);
            });
            this.snack.open(reply.message, SnackType.Success);
            this.updateContributions();
        } catch (e) {
            this.snack.open(`Failed to save evaluation reviews. ${e.message}`, SnackType.Error);
        } finally {
            this._isBusy.set(false);
        }
    };
}

decorate(ResultsScreenStore, {
    resultsTableStore: observable,
    detailedViewStore: observable,
    resultsEditorStore: observable,
    fetchResults: action,
    id: computed,
    setId: action,
    isBusy: computed,
    goBack: action,
    goToConfig: action,
});
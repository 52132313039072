import {ITeamDto} from '../dtos';
import {TeamStudent} from './TeamStudent';
import {Project} from './Project';

export class Team {
    public id?: number;
    public name: string;
    public teamStudents?: TeamStudent[];
    public project?: Project;

    constructor(name: string) {
        this.name = name;
    }

    public static fromDto = (d: ITeamDto): Team => {
        const t = new Team(d.name);
        t.id = d.id;
        t.teamStudents = d.teamStudents ? d.teamStudents.map(TeamStudent.fromDto) : undefined;
        t.project = d.project ? Project.fromDto(d.project) : undefined;
        return t;
    };

    public toDto = (): ITeamDto => {
        return {
            id: this.id,
            name: this.name,
            teamStudents: this.teamStudents ? this.teamStudents.map(x => x.toDto()) : undefined,
            project: this.project ? this.project.toDto() : undefined,
        };
    }
}
import React from 'react';
import {AppBar, createStyles, makeStyles, Theme, Toolbar, Typography} from '@material-ui/core';
import {observer} from 'mobx-react';
import {UserAppBarButton} from '../auth/UserAppBarButton';
import {BackButton} from '../../common';
import {useStore} from '../../storeProvider';
import {GradesTable} from './GradesTable';

const useStyles = makeStyles((theme: Theme) => createStyles({
    flex: {flex: 1},
}));

export interface IStudentGradesScreen {

}

export const StudentGradesScreen: React.FC<IStudentGradesScreen> = observer(props => {
    const classes = useStyles(props);

    const {studentGradesScreenStore} = useStore();

    const onBack = () => {
        studentGradesScreenStore.goBack();
    };

    return (
        <div>
            <AppBar position='static' color='primary'>
                <Toolbar>
                    <BackButton onClick={onBack}/>
                    <Typography variant='h6' color='inherit' className={classes.flex}>
                        Student Grades
                    </Typography>
                    <UserAppBarButton/>
                </Toolbar>
            </AppBar>
            <GradesTable/>
        </div>
    );
});
import React from 'react';
import {observer} from 'mobx-react';
import {createStyles, Drawer, makeStyles, Theme, useMediaQuery} from '@material-ui/core';
import {useStore} from '../../../storeProvider';
import {ReviewCard} from './ReviewCard';

export interface IDetailedViewProps {

}

const useStyles = makeStyles((_: Theme) => createStyles({
    root: {},
}));

export const DetailedView: React.FC<IDetailedViewProps> = observer(props => {
    const classes = useStyles(props);

    const {resultsStore} = useStore();
    const {detailedViewStore} = resultsStore;
    const {isOpen} = detailedViewStore;
    const isMobile = !useMediaQuery('(min-width:1000px)');

    const onClose = () => {
        resultsStore.detailedViewStore.close();
    };

    return (
        <div className={classes.root}>
            <Drawer
                anchor='bottom'
                open={isOpen}
                onClose={onClose}
                variant={isMobile ? 'temporary' : 'persistent'}
            >
                <ReviewCard isMobile={isMobile}/>
            </Drawer>
        </div>
    );
});

import * as React from 'react';
import {createStyles, makeStyles, TextField, Theme} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {TextFieldStore} from './textFieldStore';
import {TextFieldProps} from '@material-ui/core/TextField';

export interface ITextFieldViewProps {
    field: TextFieldStore;
    textFieldProps: TextFieldProps;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    textField: {}
}));

export const TextFieldView: React.FC<ITextFieldViewProps> = observer(props => {
    const classes = useStyles(props);

    const {field, textFieldProps} = props;

    const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        field.setValue(e.target.value);
    };

    return (
        <TextField
            label={field.label}
            className={classes.textField}
            {...textFieldProps}
            value={field.value}
            disabled={field.isDisabled}
            onChange={onChange}
        />
    );
});
import {action, computed, decorate, observable} from 'mobx';
import {RootStore} from '../../RootStore';
import {ICreateAccountRequestPayload} from '../../dtos';
import {TextFieldStore} from '../../common';

export class AccountScreenStore {
    public readonly username: TextFieldStore;
    public readonly name: TextFieldStore;
    public readonly email: TextFieldStore;
    public readonly password: TextFieldStore;
    private _errorMessage = observable.box<string | undefined>('');
    private _isBusy = observable.box<boolean>(false);

    public constructor(public rootStore: RootStore) {
        this.username = new TextFieldStore('Username', '');
        this.name = new TextFieldStore('Name', '');
        this.email = new TextFieldStore('Email', '');
        this.password = new TextFieldStore('Password', '');
    }

    public get errorMessage() {
        return this._errorMessage.get();
    }

    public get isBusy() {
        return this._isBusy.get();
    }

    public createAccount = async (): Promise<void> => {
        const login = this.username.value;
        const password = this.password.value;
        const name = this.name.value;
        const email = this.email.value;

        const payload: ICreateAccountRequestPayload = {
            user: {
                login,
                password,
                name,
                email
            }
        };

        this._errorMessage.set(undefined);
        this._isBusy.set(true);
        const result = await this.rootStore.authStore.createAccount(payload);
        this._isBusy.set(false);
        this._errorMessage.set(result.errorMessage);
    };

    public cancel = (): void => {
        this.rootStore.routerStore.push('/');
    }
}

decorate(AccountScreenStore, {
    username: observable,
    name: observable,
    email: observable,
    password: observable,
    errorMessage: computed,
    isBusy: computed,
    createAccount: action,
});
import {CircularProgress, createStyles, makeStyles, Theme} from '@material-ui/core';
import * as React from 'react';
import {observer} from 'mobx-react';

const useStyles = makeStyles((theme: Theme) => createStyles({
    mask: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, .15)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    progress: {}
}));

export interface ILoadingProps {
    id?: string;
    isOpen: boolean;
}

export const Loading: React.FC<ILoadingProps> = observer(props => {
    const classes = useStyles(props);
    const {isOpen, id} = props;
    if (!isOpen) {
        return null;
    }
    return (
        <div className={classes.mask} id={id}>
            <CircularProgress className={classes.progress}/>
        </div>
    );
});
import React, {useEffect, useMemo} from 'react';
import {
    AppBar,
    Button,
    Card,
    CardActions,
    CardContent,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Toolbar,
    Typography
} from '@material-ui/core';
import {observer} from 'mobx-react';
import {UserAppBarButton} from '../auth/UserAppBarButton';
import {useStore} from '../../storeProvider';
import {BackButton, Loading, SelectorFieldView, TextFieldView} from '../../common';
import {RouteComponentProps} from 'react-router';

const useStyles = makeStyles((theme: Theme) => createStyles({
    flex: {flex: 1},
    card: {
        maxWidth: 800,
        marginTop: theme.spacing(1),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    cardContentGrid: {},
}));

export interface IProjectScreenRouteParams {
    projectId: string;
}

export interface IProjectScreenProps extends RouteComponentProps<IProjectScreenRouteParams> {
}

const textFieldProps = {
    fullWidth: true,
};

export const ProjectScreen: React.FC<IProjectScreenProps> = observer(props => {
    const classes = useStyles(props);
    const {projectScreenStore} = useStore();

    const projectId = useMemo(() => parseInt(props.match.params.projectId, 10), [props.match.params.projectId]);

    useEffect(() => {
        if (!isNaN(projectId)) {
            projectScreenStore.setProjectId(projectId);
        }
    }, [projectScreenStore, projectId]);

    const onBack = () => {
        projectScreenStore.goBack();
    };

    const onSave = () => {
        projectScreenStore.save();
    };

    return (
        <div>
            <AppBar position='static' color='primary'>
                <Toolbar>
                    <BackButton onClick={onBack}/>
                    <Typography variant='h6' color='inherit' className={classes.flex}>
                        Project
                    </Typography>
                    <UserAppBarButton/>
                </Toolbar>
            </AppBar>
            <Card className={classes.card}>
                <CardContent>
                    <Grid className={classes.cardContentGrid} container spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldView field={projectScreenStore.name} textFieldProps={textFieldProps}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldView field={projectScreenStore.description} textFieldProps={textFieldProps}/>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectorFieldView field={projectScreenStore.status}/>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button color='primary' onClick={onSave}>
                        Save
                    </Button>
                </CardActions>
            </Card>
            <Loading isOpen={projectScreenStore.isBusy}/>
        </div>
    );
});
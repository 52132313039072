import {ResultsRowData} from './table';
import {
    auxMatrix,
    compareOptionalNumbers,
    computeBonusesForEvaluations,
    computeConsistency,
    mapBy,
    Matrix,
    median,
    normalize,
    withDefaultGrades,
    withDefaultWeights
} from '../../shared';
import {ProjectRole} from '../../dtos';
import {Project, ProjectConcept, UserProject} from '../../model';

export const buildUserProjectsWithStudents = (project: Project): UserProject[] => {
    const projectConcepts = project.projectConcepts!.map(ProjectConcept.fromDto);
    const concepts = projectConcepts.map(pc => pc.concept!);
    const conceptsById = mapBy(concepts, x => x.id);

    const userProjectsWithStudents = project.userProjects!
        .filter(up => up.user && up.user.student && up.roles!.includes(ProjectRole.Student));
    userProjectsWithStudents.forEach(up => {
        up.user!.student!.user = up.user;
    });
    const userProjectsByStudent = mapBy(userProjectsWithStudents, up => up.user!.student!.id);
    project.teams!.forEach(t => {
        t.teamStudents!.forEach(ts => {
            ts.team = t;
            const up = userProjectsByStudent.get(ts.studentId)!;
            up.user!.student!.teamStudents = [ts];
        })
    });
    const evaluations = project.evaluations!;
    evaluations.forEach(e => {
        const evaluator = userProjectsByStudent.get(e.evaluatorId)!.user!.student!;
        const evaluated = userProjectsByStudent.get(e.evaluatedId)!.user!.student!;
        e.evaluator = evaluator;
        e.evaluated = evaluated;
        evaluator.addSubmittedEvaluation(e);
        evaluated.addReceivedEvaluation(e);

        const evaluationReviews = e.evaluationReviews;
        if (evaluationReviews) {
            const sentencesById = mapBy(e.sentences!, x => x.id);

            evaluationReviews.forEach(er => {
                const sentenceConcepts = er.sentenceConcepts;
                if (sentenceConcepts) {
                    sentenceConcepts.forEach(sc => {
                        sc.concept = conceptsById.get(sc.conceptId);
                        const sentence = sentencesById.get(sc.sentenceId)!;
                        sc.sentence = sentence;
                        if (!sentence.sentenceConcepts) {
                            sentence.sentenceConcepts = [];
                        }
                        sentence.sentenceConcepts.push(sc);
                    });
                }
            });
        }
    });
    return userProjectsWithStudents;
}

export interface IResultsRowDataContributions {
    index?: number,
    contribution?: number,
    bonusForEvaluations?: number,
    consistency?: number
}

export const extractGradesAndQualities = (group: ResultsRowData[]) => {
    const teammateCount = group.length;
    const grades: Array<number | undefined> = [];
    const qualities: Array<number | undefined> = [];
    group.forEach(row => {
        for (let i = 0; i < teammateCount; ++i) {
            const cell = row.evaluationCells[i];
            const e = cell?.evaluation;
            grades.push(e ? e.grade : undefined);
            qualities.push(cell ? cell.quality : undefined);
        }
    });
    return [grades, qualities];
}

export const calcGroupContributions = (group: ResultsRowData[]): IResultsRowDataContributions[] => {
    group.sort((a, b) => compareOptionalNumbers(a.index, b.index));
    const teammateCount = group.length;
    const [grades, qualities] = extractGradesAndQualities(group);
    const mGrades = withDefaultGrades(new Matrix(grades, teammateCount));
    const mQualities = withDefaultWeights(new Matrix(qualities, teammateCount));
    let mAux = auxMatrix(mGrades, mQualities);
    mAux = mAux.normalise();
    let contributions = mAux.getRows().map(x => median(x));
    contributions = normalize(contributions);
    const bonusesForEvaluations = computeBonusesForEvaluations(mQualities);
    const normalisedGrades = mGrades.clone().normalise();
    const consistencies = computeConsistency(normalisedGrades, contributions);
    const result: IResultsRowDataContributions[] = [];
    group.forEach((row, i) => {
        result.push({
            index: row.index,
            contribution: contributions[i],
            bonusForEvaluations: bonusesForEvaluations[i],
            consistency: consistencies[i]
        });
    });
    return result;
}
import {action, computed, decorate, observable} from 'mobx';
import {SnackMessageStore, SnackType} from '../../common';
import {RootStore} from '../../RootStore';
import {MyResultsApi} from './MyResultsApi';
import {Evaluation} from '../../model/Evaluation';
import {Team} from '../../model/Team';
import {MyResultCardStore} from './MyResultCardStore';
import { Concept } from '../../model/Concept';
import {mapBy, flatMap} from '../../shared/utils';
import {IMyResultsReplyPayload} from '../../dtos';

export class MyResultsScreenStore {
    private _id = observable.box<number | undefined>(undefined);
    public readonly cards = observable.array<MyResultCardStore>();
    private _team = observable.box<Team>();
    private _isBusy = observable.box<boolean>(false);
    private _concepts = observable.array<Concept>();
    public readonly snack: SnackMessageStore;

    constructor(public rootStore: RootStore, private readonly api: MyResultsApi, snack: SnackMessageStore) {
        this.snack = snack;
    }

    public get id(): number | undefined {
        return this._id.get();
    }

    public setId = (id: number) => {
        this._id.set(id);
    };

    public get team(): Team {
        return this._team.get();
    }

    public get isBusy(): boolean {
        return this._isBusy.get();
    }

    public get concepts(): Concept[] {
        return this._concepts;
    }

    public goBack = () => {
        this.rootStore.routerStore.goBack();
    };

    public fetchScreenData = async (): Promise<void> => {
        const projectId = this.id;
        if (!projectId) {
            return;
        }
        this._isBusy.set(true);
        try {
            const reply: IMyResultsReplyPayload = await this.api.fetchScreenData({projectId});
            const normalisedEvaluations = reply.normalisedEvaluations.map(x => {
                return {
                    evaluation: Evaluation.fromDto(x.evaluation),
                    normalisedGrade: x.normalisedGrade,
                };
            });
            // const evaluations = reply.evaluations.map(Evaluation.fromDto);
            // const normalisedGrades = reply.normalisedGrades;
            const team = Team.fromDto(reply.team);
            const concepts = reply.concepts.map(Concept.fromDto);
            const conceptsById = mapBy(concepts, c => c.id);
            const evaluationReviews = flatMap(normalisedEvaluations, x => x.evaluation.evaluationReviews);
            const sentenceConcepts = flatMap(evaluationReviews, er => er.sentenceConcepts);
            const sentences = flatMap(normalisedEvaluations, x => x.evaluation.sentences);
            const sentencesById = mapBy(sentences, s => s.id);
            sentenceConcepts.forEach(sc => {
                sc.concept = conceptsById.get(sc.conceptId);
                const s = sentencesById.get(sc.sentenceId)!;
                if (!s.sentenceConcepts) {
                    s.sentenceConcepts = [];
                }
                s.sentenceConcepts.push(sc);
            });
            const cards = normalisedEvaluations.map(x => {
                const card = new MyResultCardStore(this, x.evaluation, x.normalisedGrade);
                return card;
            });
            this.cards.replace(cards);
            this._team.set(team);
            this._concepts.replace(concepts);
            this.snack.open(`Results loaded`, SnackType.Success);
        } catch (e) {
            this.snack.open(`Failed to load screen data. ${e.message}`);
        } finally {
            this._isBusy.set(false);
        }
    };
}

decorate(MyResultsScreenStore, {
    id: computed,
    setId: action,
    isBusy: computed,
    goBack: action,
    fetchScreenData: action,
    concepts: computed,
    team: computed,
});
import React from 'react';
import {observer} from 'mobx-react';
import {DataTable} from '../../../common';
import {useStore} from '../../../storeProvider';
import {createStyles, Paper, TableContainer, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
    tableContainer: {
        maxHeight: '100%',
    },
    tablePaper: {
        position: 'absolute',
        top: 64,
        right: 0,
        bottom: 0,
        left: 0,
    }
}));

export const ResultsTable: React.FC<{}> = observer(props => {
    const {resultsStore} = useStore();
    const classes = useStyles(props);
    return (
        <Paper className={classes.tablePaper}>
            <TableContainer className={classes.tableContainer}>
                <DataTable
                    store={resultsStore.resultsTableStore}
                    tableProps={{
                        stickyHeader: true,
                    }}
                />
            </TableContainer>
        </Paper>
    );
});
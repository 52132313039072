import React, {useEffect, useMemo} from 'react';
import {AppBar, createStyles, makeStyles, Theme, Toolbar, Typography} from '@material-ui/core';
import {observer} from 'mobx-react';
import {UserAppBarButton} from '../auth/UserAppBarButton';
import {ResultsTable} from './table/ResultsTable';
import {BackButton, Loading, SettingsButton, SnackMessage, DownloadButton} from '../../common';
import {useStore} from '../../storeProvider';
import {DetailedView} from './review/DetailedView';
import {ResultsEditor} from './results/ResultsEditor';
import {RouteComponentProps} from 'react-router';
import {when} from 'mobx';

const useStyles = makeStyles((theme: Theme) => createStyles({
    flex: {flex: 1},
}));

export interface IResultsScreenRouteProps {
    projectId: string;
}

export interface IResultsProps extends RouteComponentProps<IResultsScreenRouteProps> {

}

export const ResultsScreen: React.FC<IResultsProps> = observer(props => {
    const classes = useStyles(props);
    const {resultsStore} = useStore();
    const projectId = useMemo(() => parseInt(props.match.params.projectId, 10), [props.match.params.projectId]);

    useEffect(() => {
        when(() => resultsStore.rootStore.authStore.isRehydrated, () => {
            if (!isNaN(projectId)) {
                resultsStore.setId(projectId);
                resultsStore.fetchResults();
            }
        });
    }, [resultsStore, projectId]);

    const onBack = () => {
        resultsStore.goBack();
    };

    const onMenu = () => {
        resultsStore.goToConfig();
    };

    const onDownload = () => {
        resultsStore.download();
    }

    const onDownloadForAi = () => {
        resultsStore.downloadForAi();
    }

    const title = `${resultsStore.project?.name || `Loading`} Results`;

    return (
        // position='sticky'
        <div>
            <AppBar color='primary'>
                <Toolbar>
                    <BackButton onClick={onBack}/>
                    <SettingsButton onClick={onMenu}/>
                    <DownloadButton onClick={onDownload}/>
                    <DownloadButton onClick={onDownloadForAi}/>
                    <Typography variant='h6' color='inherit' className={classes.flex}>
                        {title}
                    </Typography>
                    <UserAppBarButton/>
                </Toolbar>
            </AppBar>
            <ResultsTable/>
            <DetailedView/>
            <ResultsEditor/>
            <SnackMessage snackMessageStore={resultsStore.snack}/>
            <Loading isOpen={resultsStore.isBusy}/>
        </div>
    );
});
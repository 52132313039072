import * as _ from 'lodash';
import {action, decorate, observable} from 'mobx';
import {filterMap} from '../../../shared/utils';

enum SelectionMode {
    Change = 'Change',
    Add = 'Add'
}

export class Sentence {
    public text: string;
    public start: number; // start index in the essay text
    public isSelected: boolean;

    public concepts = observable.array<string>();
    public conceptPredictions = observable.map<string, number>();

    constructor(text: string, concepts: string[] = []) {
        this.start = 0;
        this.text = text;
        this.isSelected = false;
        this.concepts.replace(concepts);
    }

    public setConcepts = (concepts?: string[]) => {
        this.concepts.replace(concepts || []);
    };

    public setConceptPredictions = (conceptPredictions: Map<string, number>, threshold?: number) => {
        this.conceptPredictions.replace(conceptPredictions);
        if (threshold != null) {
            this.applyThreshold(threshold);
        }
    };

    public applyThreshold = (threshold: number) => {
        const concepts = filterMap(this.conceptPredictions, (concept, prediction) => prediction >= threshold).keys();
        this.setConcepts(Array.from(concepts));
    };
}

export interface ISentenceWithConcepts {
    text: string;
    concepts?: string[];
}

decorate(Sentence, {
    text: observable,
    start: observable,
    isSelected: observable,
    setConcepts: action,
    setConceptPredictions: action,
    applyThreshold: action,
});

export class AnnotationTextModel {
    public text: string;
    public sentences = observable.array<Sentence>();
    private selectionMode = SelectionMode.Add;

    constructor() {
        this.text = '';
    }

    public setText(text: string) {
        this.text = text;
        this.sentences.replace(
            text.replace(/([.?!])/g, '$1|')
                .split('|')
                .filter(t => !!t && t.length > 0)
                .map(t => new Sentence(t)));
    }

    public setSentences = (sentences: string[]) => {
        this.sentences.replace(sentences.map(x => new Sentence(x)));
    };

    public loadSentences = (input?: ISentenceWithConcepts[]) => {
        if (!input || input.length === 0) {
            this.sentences.replace([]);
            return;
        }
        const sentences: Sentence[] = input.map(x => {
            const s = new Sentence(x.text);
            s.setConcepts(x.concepts);
            return s;
        });
        this.sentences.replace(sentences);
    };

    public toggleConceptOnSelectedSentences = (concept: string) => {
        const selectedSentences = this.sentences.filter(x => x.isSelected);
        const exists = selectedSentences.every(s => _.includes(s.concepts, concept));
        selectedSentences.forEach(sentence => {
            if (!exists) {
                if (!_.includes(sentence.concepts, concept)) {
                    sentence.concepts.push(concept);
                }
            } else {
                sentence.concepts.remove(concept);
            }
        });
        this.selectionMode = SelectionMode.Change;
    };

    public toggleSelection = (sentenceIndex: number) => {
        const sentence = this.sentences[sentenceIndex];
        if (this.selectionMode === SelectionMode.Change) {
            this.sentences.forEach(s => s.isSelected = false);
            this.selectionMode = SelectionMode.Add;
        }
        sentence.isSelected = !sentence.isSelected;
    };

    public clearConcepts = () => {
        this.sentences.forEach(sentence => {
            sentence.concepts.clear();
        });
    }
}

decorate(AnnotationTextModel, {
    text: observable,
    setText: action,
    setSentences: action,
    toggleConceptOnSelectedSentences: action,
    toggleSelection: action,
    clearConcepts: action,
});
import * as React from 'react';
import moment from 'moment';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    createStyles,
    IconButton,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import SettingsIcon from '@material-ui/icons/Settings';
import {Project} from '../../model/Project';
import {ProjectCardStore} from './ProjectCardStore';

export interface IInstructorsProjectCardProps {
    store: ProjectCardStore;
    onEditProject: (project: Project) => void;
    onResults: (project: Project) => void;
    onStudents: (project: Project) => void;
    onInstructors: (project: Project) => void;
    onAnalytics: (project: Project) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        margin: theme.spacing(2, 1)
    },
    description: {
        maxHeight: theme.spacing(11),
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    status: {},
    actions: {
        flexWrap: 'wrap'
    },
    created: {}
}));

export const InstructorsProjectCard: React.FC<IInstructorsProjectCardProps> = observer(props => {
    const classes = useStyles(props);
    const {store, onEditProject} = props;
    const project = store.userProject.project!;
    const {description, name, status, creationTime} = project;
    const {studentCount, teamCount, evaluationProgress} = store;
    const createdText = `Created on ${moment(creationTime).format('DD-MMM-YYYY')}`;

    const onEdit = () => onEditProject(project);
    const onInstructors = () => {
        props.onInstructors(project);
    };
    const onStudents = () => {
        props.onStudents(project);
    };
    const onAnalytics = () => {
        props.onAnalytics(project);
    };
    const onResults = () => {
        props.onResults(project);
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                data-automation='CardHeader'
                action={
                    <IconButton onClick={onEdit}>
                        <SettingsIcon color='primary'/>
                    </IconButton>
                }
                title={name}
                subheader={createdText}
            />
            <CardContent>
                <Typography
                    className={classes.description}
                    color='textSecondary'
                    gutterBottom
                >
                    {description}
                </Typography>
                <Typography
                    className={classes.status}
                    color='primary'
                    gutterBottom
                >
                    {`Status: ${String(status)}`}
                </Typography>
                <Typography>
                    {`${studentCount} students in ${teamCount} teams. Evaluation progress: ${evaluationProgress}%`}
                </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={onStudents}
                    title='Students'
                >
                    Students
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={onInstructors}
                    title='Instructors'
                >
                    Instructors
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={onResults}
                    title='Results'
                >
                    Results
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={onAnalytics}
                    title='Analytics'
                >
                    Analytics
                </Button>
            </CardActions>
        </Card>
    );
});
import React from 'react';
import {
    Avatar,
    Button,
    createStyles,
    IconButton,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    Typography
} from '@material-ui/core';
import {observer} from 'mobx-react';
import {AccountCircle} from '@material-ui/icons';
import {useStore} from '../../storeProvider';

export interface IUserAppBarButtonProps {
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row'
    },
    userName: {
        paddingTop: '12px',
        paddingLeft: theme.spacing(1),
    },
    userAvatar: {
        marginRight: theme.spacing(1),
    }
}));

export const UserAppBarButton: React.FC<IUserAppBarButtonProps> = observer(props => {
    const classes = useStyles(props);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {authStore} = useStore();

    const onButtonClick: React.MouseEventHandler<any> = e => {
        setAnchorEl(e.currentTarget);
    };

    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const onEditProfile = () => {
        authStore.editProfile();
    };

    const onSignOut = () => {
        authStore.signOut();
    };

    const isOpen = !!anchorEl;

    if (!authStore.user) {
        return (
            <div className={classes.root}>
                <Button
                    color='secondary'
                    variant='contained'
                    onClick={onSignOut}
                >
                    Sign In
                </Button>
            </div>
        );
    }

    const username = authStore.user ? authStore.user.login : '';
    return (
        <div className={classes.root}>
            <Typography color='inherit' className={classes.userName}>
                {username}
            </Typography>
            <IconButton
                aria-owns={isOpen ? 'menu-appbar' : undefined}
                aria-haspopup={true}
                onClick={onButtonClick}
                color='inherit'
            >
                <AccountCircle/>
            </IconButton>
            <Menu
                id='menu-app-bar'
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={isOpen}
                onClose={onMenuClose}
            >
                <MenuItem>
                    <Avatar className={classes.userAvatar}>
                        <AccountCircle/>
                    </Avatar>
                    <ListItemText
                        primary='Signed in as'
                        secondary={username}
                    />
                </MenuItem>
                <MenuItem onClick={onEditProfile}>Edit Profile</MenuItem>
                <MenuItem onClick={onSignOut}>Sign Out</MenuItem>
            </Menu>
        </div>
    );
});
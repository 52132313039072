import {UserProject, User} from '../../model';

export class InstructorRow {
    public readonly userProject: UserProject;

    public constructor(userProject: UserProject) {
        this.userProject = userProject;
    }

    public get user(): User {
        return this.userProject.user!;
    }
}
import {IUserDto} from '../dtos';
import {Student} from './Student';
import {UserProject} from './UserProject';
import { EvaluationReview } from './EvaluationReview';

export class User {
    public id?: number;
    public name: string = '';
    public email: string = '';
    public login: string = '';
    public password?: string;
    public student?: Student;
    public userProjects?: UserProject[];
    public evaluationReviews?: EvaluationReview[];

    public static fromDto(dto: IUserDto): User {
        const user = new User();
        user.id = dto.id;
        user.name = dto.name;
        user.email = dto.email;
        user.login = dto.login;
        user.password = dto.password;
        user.student = dto.student ? Student.fromDto(dto.student) : undefined;
        user.userProjects = dto.userProjects ? dto.userProjects.map(UserProject.fromDto) : undefined;
        user.evaluationReviews = dto.evaluationReviews ? dto.evaluationReviews.map(EvaluationReview.fromDto) : undefined;
        return user;
    }

    public toDto = (): IUserDto => {
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            login: this.login,
            password: this.password,
            student: this.student ? this.student.toDto() : undefined,
            userProjects: this.userProjects ? this.userProjects.map(x => x.toDto()) : undefined,
            evaluationReviews: this.evaluationReviews ? this.evaluationReviews.map(x => x.toDto()) : undefined,
        };
    }
}
import {TextFieldStore} from '../../common';
import {action, computed, decorate, observable} from 'mobx';
import {RootStore} from '../../RootStore';

export class LoginFormStore {
    public readonly username: TextFieldStore;
    public readonly password: TextFieldStore;
    private _errorMessage = observable.box<string | null>('');
    private _isBusy = observable.box<boolean>(false);

    constructor(private readonly rootStore: RootStore) {
        this.username = new TextFieldStore('Username', '');
        this.password = new TextFieldStore('Password', '');
    }

    public get errorMessage() {
        return this._errorMessage.get();
    }

    public get isBusy() {
        return this._isBusy.get();
    }

    public signIn = async () => {
        this._errorMessage.set(null);
        this._isBusy.set(true);
        const result = await this.rootStore.authStore.signIn(this.username.value, this.password.value);
        this._isBusy.set(false);
        this._errorMessage.set(result.errorMessage);
    };

    public createAccount = () => {
        this.rootStore.routerStore.push('/account');
    };
}

decorate(LoginFormStore, {
    username: observable,
    password: observable,
    signIn: action,
    createAccount: action,
    errorMessage: computed,
    isBusy: computed,
});
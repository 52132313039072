import {ApiFactory} from '../../common';
import {
    IEvaluationDataReplyPayload,
    IEvaluationDataRequestPayload,
    ISaveEvaluationReplyPayload,
    ISaveEvaluationRequestPayload
} from '../../dtos';
import {Urls} from '../../shared/urls';

// const log = getLogger('EvaluationApi');

export class EvaluationApi {
    constructor(private readonly apiFactory: ApiFactory) {
    }

    public fetchScreenData = this.apiFactory.createApiFunc<IEvaluationDataRequestPayload, IEvaluationDataReplyPayload>(Urls.getEvaluations);
    public saveEvaluation = this.apiFactory.createApiFunc<ISaveEvaluationRequestPayload, ISaveEvaluationReplyPayload>(Urls.saveEvaluations);
}
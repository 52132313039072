import * as _ from 'lodash';
import {Student, Team, TeamStudent, User} from '../../model';

export const studentsCsvParser = (csv: string): [Student[], string[]] => {
    const errors: string[] = [];
    const expectedRowCount = 5;
    csv = _.replace(csv, /\r\n/, '\n');
    csv = _.trim(csv, '\n');
    const lines = csv.split(/\n/);
    const cells = lines.map(x => x.split('\t'));
    const students = cells.map((x, ln) => {
        if (!x) {
            throw new Error(`Bad CSV. Ln ${ln} is empty`);
        }
        x = x.map(c => {
            const trimmed = c.trim();
            if (c.length !== trimmed.length) {
                errors.push(`Trailing/leading space in line ${ln}: "${c}"`);
            }
            return trimmed;
        })
        if (x.length !== expectedRowCount) {
            throw new Error(`Bad CSV. Ln ${ln} has ${x.length} elements, ${expectedRowCount} elements expected`);
        }
        const [name, login, email, teamName, password] = x;
        const user: User = new User();
        user.name = name;
        user.email = email;
        user.password = password;
        user.login = login;
        const student: Student = new Student();
        student.user = user;
        const teamStudent: TeamStudent = new TeamStudent();
        teamStudent.team = new Team(teamName);
        student.teamStudents = [teamStudent];
        return student;
    });
    return [students, errors];
};
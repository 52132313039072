import React from 'react';
import {observer} from 'mobx-react';
import {createStyles, Drawer, makeStyles, Theme} from '@material-ui/core';
import {useStore} from '../../../storeProvider';
import {ResultCard} from './ResultCard';

export interface IResultsEditorProps {

}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
}));

export const ResultsEditor: React.FC<IResultsEditorProps> = observer(props => {
    const classes = useStyles(props);

    const {resultsStore} = useStore();
    const isOpen = resultsStore.resultsEditorStore.isOpen;

    const onClose = () => {
        resultsStore.resultsEditorStore.close();
    };

    const renderReview = () => {
        return (
            <ResultCard/>
        );
    };

    return (
        <div className={classes.root}>
            <Drawer anchor='bottom' open={isOpen} onClose={onClose}>
                {renderReview()}
            </Drawer>
        </div>
    );
});

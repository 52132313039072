import * as React from 'react';
import moment from 'moment';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {UserProject} from '../../model/UserProject';
import {Project} from '../../model';
import {ProjectStatus} from '../../dtos';

export interface IStudentsProjectCardProps {
    userProject: UserProject;
    onMyResults: (project: Project) => void;
    onEvaluate: (project: Project) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        margin: theme.spacing(2, 1)
    },
    description: {
        maxHeight: theme.spacing(11),
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    status: {},
    actions: {
        flexWrap: 'wrap'
    },
    created: {}
}));

export const StudentsProjectCard: React.FC<IStudentsProjectCardProps> = observer(props => {
    const classes = useStyles(props);
    const {userProject} = props;
    const project = userProject.project!;
    const {description, name, status} = project;
    const created = `Created on ${moment(project.creationTime).format('DD-MMM-YYYY')}`;

    const canSeeResults = project.status === ProjectStatus.Results || project.status === ProjectStatus.Closed;

    const onEvaluate = () => {
        props.onEvaluate(project);
    };
    const onResults = () => {
        props.onMyResults(project);
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                data-automation='CardHeader'
                title={name}
                subheader={created}
            />
            <CardContent>
                <Typography
                    className={classes.description}
                    color='textSecondary'
                    gutterBottom
                >
                    {description}
                </Typography>
                <Typography
                    className={classes.status}
                    color='primary'
                    gutterBottom
                >
                    {String(status)}
                </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={onEvaluate}
                    title='Evaluate'
                >
                    Evaluate
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={onResults}
                    title='Results'
                    disabled={!canSeeResults}
                >
                    Results
                </Button>
            </CardActions>
        </Card>
    );
});
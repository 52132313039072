import * as React from 'react';

export class AnnotationPalette {
    public static getButtonStyle(index: number): React.CSSProperties {
        return {
            backgroundColor: this.getColor(index)
        };
    }

    public static getWordStyle(concepts: string[], allConcepts: string[]): React.CSSProperties {
        const conceptIndices = new Map(allConcepts.map((c, i) => [c, i] as [string, number]));
        const colorIndices = concepts.map(c => conceptIndices.get(c)) as number[];
        const style: React.CSSProperties = {};
        if (colorIndices.length === 1) {
            style.backgroundColor = this.getColor(colorIndices[0]);
        } else if (colorIndices.length > 1) {
            style.background = this.getGradient(colorIndices);
        }
        return style;
    }

    private static _colors: string[] = [
        '#ff8a80', // Red
        '#82b1ff', // Blue
        '#b9f6ca', // Green
        '#ff80ab', // Pink
        '#ffff8d', // Yellow
        '#ea80fc', // Purple
        '#ffd180', // Orange
        '#84ffff', // Cyan
        '#f4ff81', // Lime
        '#b388ff', // Deep Purple
        '#ff9e80', // Deep Orange,
        '#8c9eff', // Indigo
        '#ccff90', // Light Green
        '#80d8ff', // Light Blue
        '#ffe57f', // Amber,
        '#a7ffeb'  // Teal
    ];

    private static getColor(index: number): string {
        return this._colors[index % this._colors.length];
    }

    // Stripes with no gaps
    private static getSimpleGradient(indices: number[]): string {
        const step = 100 / indices.length;
        const colorStops = indices.map((colorIndex, index) => {
            const color = this.getColor(colorIndex);
            const startPos = index * step;
            const start = index === 0 ? color : `${color} ${startPos}%`;
            const endPos = (index + 1) * step;
            const end = index === indices.length - 1 ? `` : `, ${color} ${endPos}%`;
            return [start, end].join('');
        });
        const gradient = `linear-gradient(to bottom, ${colorStops.join(', ')})`;
        return gradient;
    }

    // Stripes divided by transparent gaps of gapSize width
    private static getGradient(indices: number[]): string {
        const gapSize = 2;
        const step = (100 - gapSize * (indices.length - 1)) / indices.length;
        const colorPoints: Array<{ position: number | null, color: string }> = [];
        let pos = 0;
        for (let i = 0; i < indices.length; ++i) {
            const color = this.getColor(indices[i]);
            colorPoints.push({
                position: pos,
                color
            });
            if (i < indices.length - 1) {
                pos += step;
                colorPoints.push({
                    position: pos,
                    color
                });
                colorPoints.push({
                    position: pos,
                    color: 'transparent'
                });
                pos += gapSize;
                colorPoints.push({
                    position: pos,
                    color: 'transparent'
                });
            } else {
                colorPoints.push({
                    position: null,
                    color
                });
            }
        }
        const colorStops = colorPoints.map(({position, color}) => {
            if (position === 0 || position == null) {
                return color;
            }
            return `${color} ${position}%`;
        });
        const gradient = `linear-gradient(to bottom, ${colorStops.join(', ')})`;
        return gradient;
    }
}
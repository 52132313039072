export const getAttribute = (node: Element, attributeName: string): string => {
    if (!node) {
        throw Error(`Attribute ${attributeName} not found`);
    }
    const attributeValue = node.getAttribute(attributeName);
    if (attributeValue != null) {
        return attributeValue;
    }
    return getAttribute(node.parentNode as Element, attributeName);
};

import React from 'react';
import {createStyles, IconButton, makeStyles, Theme} from '@material-ui/core';
import {AddCircle as AddIcon, Delete as DeleteIcon, TableChart as TableChartIcon,} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => createStyles({
    flex: {flex: 1},
    addButton: {},
    importsButton: {},
    removesButton: {},
}));

export interface ITableToolbarButtonsProps {
    onAdd: () => void;
    onImport: () => void;
    onRemove: () => void;
    canRemove: boolean;
}

export const TableToolbarButtons: React.FC<ITableToolbarButtonsProps> = props => {
    const classes = useStyles(props);
    const {onAdd, onImport, onRemove, canRemove} = props;

    return (
        <>
            <IconButton
                className={classes.addButton}
                color='inherit'
                aria-label='Add  to the project'
                onClick={onAdd}
            >
                <AddIcon/>
            </IconButton>
            <IconButton
                className={classes.importsButton}
                color='inherit'
                aria-label='Import s from CSV'
                onClick={onImport}
            >
                <TableChartIcon/>
            </IconButton>
            <IconButton
                className={classes.removesButton}
                color='inherit'
                aria-label='Remove s from this project'
                disabled={!canRemove}
                onClick={onRemove}
            >
                <DeleteIcon/>
            </IconButton>
        </>
    );
};
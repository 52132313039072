import {colors, createStyles, IconButton, makeStyles, Snackbar, SnackbarContent, Theme} from '@material-ui/core';
import * as React from 'react';
import {SnackMessageStore, SnackType} from './SnackMessageStore';
import {
    CheckCircle as CheckCircleIcon,
    Close as CloseIcon,
    Error as ErrorIcon,
    Info as InfoIcon,
    Warning as WarningIcon
} from '@material-ui/icons';
import {observer} from 'mobx-react';

const AUTO_HIDE_DURATION = 6000;

const useStyles = makeStyles((theme: Theme) => createStyles({
    close: {
        padding: theme.spacing(0.5)
    },
    success: {
        backgroundColor: colors.green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    warning: {
        backgroundColor: colors.amber[700]
    },
    info: {
        backgroundColor: theme.palette.primary.dark
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        fontSize: 20
    },
    typeIcon: {
        fontSize: 20,
        marginRight: theme.spacing(1),
        opacity: 0.9
    }
}));

export interface ISnackMessageProps {
    id?: string;
    snackMessageStore: SnackMessageStore;
}

export const SnackMessage: React.FC<ISnackMessageProps> = observer(props => {
    const classes = useStyles(props);
    const {isOpen, text, snackType} = props.snackMessageStore;

    const onClose = () => {
        props.snackMessageStore.close();
    };

    const getClassName = (): string => {
        switch (snackType) {
            case SnackType.Error:
                return classes.error;
            case SnackType.Info:
                return classes.info;
            case SnackType.Success:
                return classes.success;
            case SnackType.Warning:
                return classes.warning;
            default:
                return throwOnUnexpectedValue(snackType);
        }
    };

    const getIcon = () => {
        const {snackType} = props.snackMessageStore;
        switch (snackType) {
            case SnackType.Success:
                return CheckCircleIcon;
            case SnackType.Warning:
                return WarningIcon;
            case SnackType.Info:
                return InfoIcon;
            case SnackType.Error:
                return ErrorIcon;
            default:
                return throwOnUnexpectedValue(snackType);
        }
    };

    const Icon = getIcon();
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            open={isOpen}
            autoHideDuration={AUTO_HIDE_DURATION}
            onClose={onClose}
        >
            <SnackbarContent
                className={getClassName()}
                message={
                    <span id={props.id} className={classes.message}>
                        <Icon className={classes.typeIcon}/>
                        {text}
                    </span>
                }
                action={[
                    <IconButton
                        key='close'
                        aria-label='Close'
                        color='inherit'
                        className={classes.close}
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon}/>
                    </IconButton>
                ]}
            />
        </Snackbar>
    );
});

export const throwOnUnexpectedValue = (value: any): never => {
    throw new Error(`Unexpected value: ${String(value)}`);
};
import {
    IAddStudentsReplyPayload,
    IAddStudentsRequestPayload,
    IProjectStudentsReplyPayload,
    IProjectStudentsRequestPayload,
    IRemoveStudentsReplyPayload,
    IRemoveStudentsRequestPayload
} from '../../dtos';
import {ApiFactory} from '../../common';
import {Urls} from '../../shared/urls';

export class StudentsApi {
    constructor(private readonly apiFactory: ApiFactory) {
    }
    public fetchStudents = this.apiFactory.createApiFunc<IProjectStudentsRequestPayload, IProjectStudentsReplyPayload>(Urls.getProjectStudents);
    public saveStudents = this.apiFactory.createApiFunc<IAddStudentsRequestPayload, IAddStudentsReplyPayload>(Urls.addStudentsToProject);
    public deleteStudents = this.apiFactory.createApiFunc<IRemoveStudentsRequestPayload, IRemoveStudentsReplyPayload>(Urls.removeStudentsFromProject);
}
import {
    IDeleteProjectReplyPayload,
    IDeleteProjectRequestPayload,
    IHomeDataPayload,
    IHomeDataRequestPayload,
    IProjectDetailsReplyPayload,
    IProjectDetailsRequestPayload,
    ISaveProjectReplyPayload,
    ISaveProjectRequestPayload,
    IFindConceptRequestPayload,
    IFindConceptReplyPayload
} from '../../dtos';
import {ApiFactory} from '../../common';
import {Urls} from '../../shared';

// const log = getLogger('HomeApi');

export class HomeApi {
    constructor(private readonly apiFactory: ApiFactory) {
    }

    public fetchHomeData = this.apiFactory.createApiFunc<IHomeDataRequestPayload, IHomeDataPayload>('/api/home/get-home-data');
    public fetchProjectDetails = this.apiFactory.createApiFunc<IProjectDetailsRequestPayload, IProjectDetailsReplyPayload>('/api/home/get-project-details');
    public saveProjectDetails = this.apiFactory.createApiFunc<ISaveProjectRequestPayload, ISaveProjectReplyPayload>('/api/home/save-project-details');
    public deleteProject = this.apiFactory.createApiFunc<IDeleteProjectRequestPayload, IDeleteProjectReplyPayload>('/api/home/delete-project');

    public findConcept = this.apiFactory.createApiFunc<IFindConceptRequestPayload, IFindConceptReplyPayload>(Urls.findConcept);
}
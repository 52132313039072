import React from 'react';
import {createStyles, makeStyles, Table, TableProps, Theme} from '@material-ui/core';
import {observer} from 'mobx-react';
import {DataTableStore} from '../model/DataTableStore';
import {ICellComponentProps} from '../model/ICellComponentProps';
import {DataTableHead} from './DataTableHead';
import {DataTableBody} from './DataTableBody';

export type CellPropsBuilder<T, U> = (props: ICellComponentProps<T>) => U;

export interface IDataTableProps<T> {
    store: DataTableStore<T>;
    tableProps?: TableProps;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        margin: theme.spacing(2),
        overflowX: 'auto'
    },
    table: {},
    cursor: {
        backgroundColor: theme.palette.grey['300'],
    },
    alternateRow: {
        backgroundColor: theme.palette.grey['200'],
    }
}));

export const DataTable = observer(function <T>(props: IDataTableProps<T>) {
    const {store, tableProps} = props;
    const classes = useStyles(props);

    return (
        // <Paper className={classes.root}>
        <Table {...tableProps} className={classes.table}>
            <DataTableHead store={store}/>
            <DataTableBody store={store}/>
        </Table>
        // </Paper>
    );
});
import * as React from 'react';
import moment from 'moment';
import {Card, CardContent, CardHeader, createStyles, makeStyles, Theme} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {EvaluationCardStore} from './EvaluationCardStore';
import {TextFieldView} from '../../common';
import {NumericFieldView} from '../../common';

export interface IEvaluationCardProps {
    store: EvaluationCardStore;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        margin: theme.spacing(2, 1)
    },
    description: {
        maxHeight: theme.spacing(11),
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    status: {},
    actions: {
        flexWrap: 'wrap'
    },
    created: {}
}));

export const EvaluationCard: React.FC<IEvaluationCardProps> = observer(props => {
    const classes = useStyles(props);
    const {store} = props;
    const {grade, justification, evaluation} = store;
    const teammateName = evaluation.evaluated!.user!.name;
    const time = moment(evaluation.time).format('DD-MMM-YY HH:mm:SS');
    return (
        <Card className={classes.card}>
            <CardHeader
                data-automation='CardHeader'
                title={teammateName}
                subheader={time}
            />
            <CardContent>
                <NumericFieldView
                    field={grade}
                    textFieldProps={{}}
                />
                <TextFieldView
                    field={justification}
                    textFieldProps={{
                        multiline: true,
                        rowsMax: 10,
                        fullWidth: true
                    }}
                />
            </CardContent>
        </Card>
    );
});
import {BooleanFieldStore, SnackMessageStore} from '../../common';
import {action, computed, decorate, observable} from 'mobx';
import {RootStore} from '../../RootStore';

export class ProjectResultsConfigScreenStore {
    private _isBusy = observable.box<boolean>(false);
    public readonly snack: SnackMessageStore;
    public readonly showAbsoluteGrades: BooleanFieldStore;

    public constructor(public rootStore: RootStore, snack: SnackMessageStore) {
        this.snack = snack;
        this.showAbsoluteGrades = new BooleanFieldStore('Show absolute grades', false);
    }

    public get isBusy(): boolean {
        return this._isBusy.get();
    }

    public goBack = () => {
        this.rootStore.routerStore.goBack();
    };
}

decorate(ProjectResultsConfigScreenStore, {
    isBusy: computed,
    goBack: action,
});
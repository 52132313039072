import {ITeamStudentDto} from '../dtos';
import {Student} from './Student';
import {Team} from './Team';

export class TeamStudent {
    public id?: number;
    public student?: Student;
    public studentId?: number;
    public team?: Team;
    public teamId?: number;

    public static fromDto = (d: ITeamStudentDto): TeamStudent => {
        const t = new TeamStudent();
        t.id = d.id;
        t.student = d.student ? Student.fromDto(d.student) : undefined;
        t.studentId = d.studentId;
        t.team = d.team ? Team.fromDto(d.team) : undefined;
        t.teamId = d.teamId;
        return t;
    };

    public toDto = (): ITeamStudentDto => {
        return {
            id: this.id,
            student: this.student ? this.student.toDto() : undefined,
            studentId: this.student ? this.student.id : this.studentId,
            team: this.team ? this.team.toDto() : undefined,
            teamId: this.team ? this.team.id : this.teamId,
        };
    }
}
import React, { useMemo } from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export interface IReviewIndicatorProps {
    quality?: number;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: 6,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        // border: 'solid 1px ' + theme.palette.grey.A200,
    },
    qualityBar: {
        width: 6,
        backgroundColor: theme.palette.success.main,
        position: 'absolute',
        bottom: 0,
        left: 0,
    }
}));

export const ReviewIndicator: React.FC<IReviewIndicatorProps> = props => {
    const classes = useStyles(props);
    const {quality} = props;
    const height = quality || 0;
    const style: React.CSSProperties = useMemo(() => ({
        height: `${height}%`,
    }), [height]);
    if (quality === undefined) {
        return null;
    }
    return (
        <div className={classes.root}>
            <div className={classes.qualityBar} style={style}/>
        </div>
    );
};
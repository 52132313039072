export const quoteForCsv = (st: string) => {
    return ['"', st.replace(/"/, '""'), '"'].join('');
};

export const cellToCsv = (value: string | number | undefined) => {
    if (value == undefined) {
        return '';
    }
    if (typeof value === 'number') {
        return String(value);
    }
    if (value.includes('"') || value.includes(',')) {
        return quoteForCsv(value);
    }
    return value;
}

export const toCsv = (rows: any[][]) => {
    const csvLines = [];
    for (let row of rows) {
        csvLines.push(row.map(cellToCsv).join(','));
    }
    return csvLines.join('\n');
}
import React from 'react';
import {Checkbox, TableCell} from '@material-ui/core';
import {observer} from 'mobx-react';
import {DataTableStore} from '../model/DataTableStore';

export interface ICheckAllCheckboxCellProps<T> {
    store: DataTableStore<T>;
}

export const CheckAllCheckboxCell = observer(function <T>(props: ICheckAllCheckboxCellProps<T>) {
    const {store} = props;

    const onSelectAllClick = () => {
        const isAllSelected = store.rows.every(row => row.isSelected);
        store.selectAll(!isAllSelected);
    };
    const isAllSelected = store.rows.every(row => row.isSelected);
    const isAnySelected = store.rows.some(row => row.isSelected);

    return (

        <TableCell padding='checkbox'>
            <Checkbox
                data-automation='CheckAll'
                indeterminate={!isAllSelected && isAnySelected}
                checked={isAllSelected}
                onChange={onSelectAllClick}
            />
        </TableCell>
    );
});
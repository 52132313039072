import {action, computed, decorate, IReactionDisposer, observable, reaction} from 'mobx';
import {AnnotationTextModel, ISentenceWithConcepts, NumericFieldStore} from '../../../common';
import {Evaluation, EvaluationReview, SentenceConcept} from '../../../model';
import {ResultsScreenStore} from '../ResultsScreenStore';
import {arraysEqual, flatMap, mapBy, setsEqual, unique} from '../../../shared';

export class DetailedViewStore {
    private _isOpen = observable.box<boolean>(false);
    private readonly _evaluation = observable.box<Evaluation | undefined>();
    public evaluationTextModel: AnnotationTextModel;
    public readonly quality: NumericFieldStore;
    private _qualityReactionDisposer: IReactionDisposer | undefined = undefined;

    public constructor(private readonly resultsScreenStore: ResultsScreenStore) {
        this.evaluationTextModel = new AnnotationTextModel();
        this.quality = new NumericFieldStore('Evaluation Quality', 0);
    }

    public get evaluation(): Evaluation | undefined {
        return this._evaluation.get();
    }

    public setEvaluation = (e: Evaluation | undefined) => {
        if (this._qualityReactionDisposer) {
            this._qualityReactionDisposer();
        }

        this._evaluation.set(e);
        if (e) {
            if (e.sentences) {
                const sentencesWithConcepts: ISentenceWithConcepts[] = e.sentences.map(s => {
                    const concepts = s.sentenceConcepts && s.sentenceConcepts.map(sc => sc.concept!.name!);
                    const x: ISentenceWithConcepts = {
                        text: s.text!,
                        concepts
                    };
                    return x;
                });
                this.evaluationTextModel.loadSentences(sentencesWithConcepts);

            } else {
                this.evaluationTextModel.setText('');
            }
            const er = e.evaluationReviews && e.evaluationReviews[0];
            if (er) {
                this.quality.setValue(er.quality);
            } else {
                this.quality.setValue(0);
            }
        } else {
            this.evaluationTextModel.setText('');
            this.quality.setValue(0);
        }

        this._qualityReactionDisposer = reaction(() => {
            const conceptsPresent: string[] = unique(
                flatMap(this.evaluationTextModel.sentences, s => s.concepts));
            const projectConceptsByName = mapBy(this.resultsScreenStore.projectConcepts, c => c.concept!.name);
            const totalWeight = conceptsPresent.map(n => projectConceptsByName.get(n)).reduce(
                (prev, curr) => prev + curr!.weight!, 0);
            return totalWeight;
        }, (totalWeight: number) => {
            this.quality.setValue(totalWeight);
        });
    };

    public get isModified(): boolean {
        const ers = this.evaluation?.evaluationReviews;
        const q = ers && ers.length > 0 ? ers[0].quality : 0;
        if (q !== this.quality.value) {
            return true;
        }
        const newConcepts: Set<string>[] = this.evaluationTextModel.sentences.map(s => new Set(s.concepts));
        const oldConcepts: Set<string>[] = (this.evaluation?.sentences || []).map(s => new Set((s.sentenceConcepts || []).map(c => c.concept!.name!)));
        return !arraysEqual(oldConcepts, newConcepts, setsEqual);
    }

    public get isOpen(): boolean {
        return this._isOpen.get();
    }

    public open = (isOpen: boolean = true) => {
        this._isOpen.set(isOpen);
    };

    public close = () => {
        if (this.isModified) {
            this.save();
        }
        this._isOpen.set(false);
    };

    public eraseAnnotations = () => {
        this.evaluationTextModel.clearConcepts();
    };

    public save = () => {
        const e = this.evaluation;
        if (!e) {
            return;
        }
        const projectConceptsByName = mapBy(this.resultsScreenStore.projectConcepts.toJS(), c => c.concept!.name);
        const sentences = e.sentences!;
        const er = new EvaluationReview();
        // reviewer can be current user only, to be set by the BE
        er.evaluation = e;
        er.evaluationId = e.id;
        er.quality = this.quality.value;
        er.sentenceConcepts = flatMap(this.evaluationTextModel.sentences, (x, i) => {
            const concepts = x.concepts.map(c => projectConceptsByName.get(c)!.concept!);
            const sentence = sentences[i];
            const sentenceConcepts = concepts.map(c => {
                const sc = new SentenceConcept();
                sc.sentence = sentence;
                sc.sentenceId = sentence.id;
                sc.concept = c;
                sc.conceptId = c.id;
                return sc;
            });
            return sentenceConcepts;
        });
        this.resultsScreenStore.saveEvaluationReview(er);
    };
}

decorate(DetailedViewStore, {
    evaluation: computed,
    isOpen: computed,
    open: action,
    setEvaluation: action,
    save: action,
    eraseAnnotations: action,
    quality: observable,
});

import {IUserDto} from './IUserDto';
import {IProjectDto} from './IProjectDto';

export enum ProjectRole {
    Author = 'Author',
    Student = 'Student',
    Instructor = 'Instructor',
}

export interface IUserProjectDto {
    id?: number;
    user?: IUserDto;
    project?: IProjectDto;
    roles?: ProjectRole[];
}
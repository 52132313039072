import * as React from 'react';
import {createStyles, makeStyles, TextField, Theme} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {TextFieldProps} from '@material-ui/core/TextField';
import {NumericFieldStore} from './NumericFieldStore';

export interface INumericFieldViewProps {
    field: NumericFieldStore;
    textFieldProps: TextFieldProps;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    textField: {}
}));

export const NumericFieldView: React.FC<INumericFieldViewProps> = observer(props => {
    const classes = useStyles(props);

    const {field, textFieldProps} = props;

    const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        props.field.setValueFromInput(e.target.value);
    };

    const onFocus: React.FocusEventHandler<HTMLInputElement> = e => {
        props.field.setIsFocused(true);
    };

    const onBlur: React.FocusEventHandler<HTMLInputElement> = e => {
        props.field.setIsFocused(false);
    };

    return (
        <TextField
            label={field.label}
            className={classes.textField}
            {...textFieldProps}
            value={field.valueText}
            disabled={field.isDisabled}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    );
});
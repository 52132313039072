import {action, computed, decorate, observable} from 'mobx';
import {EvaluationCardStore} from './EvaluationCardStore';
import {SnackMessageStore, SnackType} from '../../common';
import {RootStore} from '../../RootStore';
import {EvaluationApi} from './EvaluationApi';
import {Evaluation} from '../../model/Evaluation';
import {Team} from '../../model/Team';
import {ISaveEvaluationRequestPayload, ProjectStatus} from '../../dtos';
import {mapBy} from '../../shared/utils';

export class EvaluationScreenStore {
    private _id = observable.box<number | undefined>(undefined);
    public readonly evaluationCards = observable.array<EvaluationCardStore>();
    private _team = observable.box<Team>();
    private _isBusy = observable.box<boolean>(false);
    public readonly snack: SnackMessageStore;

    constructor(public rootStore: RootStore, private readonly api: EvaluationApi, snack: SnackMessageStore) {
        this.snack = snack;
    }

    public get id(): number | undefined {
        return this._id.get();
    }

    public setId = (id: number) => {
        this._id.set(id);
    };

    public get team(): Team {
        return this._team.get();
    }

    public get isBusy(): boolean {
        return this._isBusy.get();
    }

    public get isReadonly(): boolean {
        return !this.team || this.team.project!.status !== ProjectStatus.Evaluation;
    }

    public goBack = () => {
        this.rootStore.routerStore.goBack();
    };

    public fetchScreenData = async (): Promise<void> => {
        const projectId = this.id;
        if (!projectId) {
            return;
        }
        this._isBusy.set(true);
        try {
            const reply = await this.api.fetchScreenData({projectId});
            const evaluations = reply.evaluations.map(Evaluation.fromDto);
            const team = Team.fromDto(reply.team);
            evaluations.forEach(e => {
                e.project = team.project;
            });
            const cards = evaluations.map(e => {
                const card = new EvaluationCardStore(this, e);
                return card;
            });
            this.evaluationCards.replace(cards);
            this._team.set(team);
            if (this.isReadonly) {
                this.snack.open(`The evaluation is over. This page is read only.`, SnackType.Info);
            }
        } catch (e) {
            this.snack.open(`Failed to load screen data. ${e.message}`);
        } finally {
            this._isBusy.set(false);
        }
    };

    public submit = async (): Promise<void> => {
        const projectId = this.id;
        if (!projectId) {
            return;
        }
        this._isBusy.set(true);
        const evaluations = this.evaluationCards.map(card => card.buildEvaluation());
        evaluations.forEach(e => {
            e.evaluator = undefined;
            e.evaluated = undefined;
            e.project = undefined;
            if (e.sentences) {
                e.sentences.forEach(s => {
                    s.evaluation = undefined;
                });
            }
        });
        try {
            const request: ISaveEvaluationRequestPayload = {
                evaluations: evaluations.map(x => x.toDto())
            };
            const reply = await this.api.saveEvaluation(request);
            const savedEvaluations = reply.evaluations.map(Evaluation.fromDto);
            const cardsByEvaluated = mapBy(this.evaluationCards, x => x.evaluation.evaluatedId);
            savedEvaluations.forEach(e => {
                e.project = this.team.project;
                const card = cardsByEvaluated.get(e.evaluatedId);
                card!.setEvaluation(e);
            });
            this.snack.open(reply.message, SnackType.Success);
        } catch (e) {
            this.snack.open(`Failed to submit evaluations. ${e.message}`, SnackType.Error);
        } finally {
            this._isBusy.set(false);
        }
    }
}

decorate(EvaluationScreenStore, {
    id: computed,
    setId: action,
    isBusy: computed,
    goBack: action,
    fetchScreenData: action,
    team: computed,
    isReadonly: computed,
});
import {Evaluation} from '../../model/Evaluation';
import {NumericFieldStore, TextFieldStore} from '../../common';
import {action, decorate, observable, reaction} from 'mobx';
import {EvaluationScreenStore} from './EvaluationScreenStore';

export class EvaluationCardStore {
    public evaluation: Evaluation;

    public readonly justification: TextFieldStore;
    public readonly grade: NumericFieldStore;

    public constructor(private readonly evaluationScreenStore: EvaluationScreenStore, evaluation: Evaluation) {
        this.evaluation = evaluation;
        this.justification = new TextFieldStore('Justification', evaluation.justificationText);
        this.grade = new NumericFieldStore('Grade', evaluation.grade, 0);

        reaction(() => {
            const isReadonly = this.evaluationScreenStore.isReadonly;
            return isReadonly;
        }, (isReadonly: boolean) => {
            this.justification.setDisabled(isReadonly);
            this.grade.setDisabled(isReadonly);
        }, {
            fireImmediately: true
        });
    }

    public setEvaluation = (evaluation: Evaluation) => {
        this.evaluation = evaluation;
        this.justification.setValue(evaluation.justificationText);
        this.grade.setValue(evaluation.grade);
    };

    public buildEvaluation = (): Evaluation => {
        const e = this.evaluation.shallowClone();
        e.grade = this.grade.value;
        e.setJustificationText(this.justification.value);
        return e;
    };
}

decorate(EvaluationCardStore, {
    evaluation: observable,
    justification: observable,
    grade: observable,
    buildEvaluation: action,
    setEvaluation: action,
});
import {IRequestDto} from './IRequestDto';
// TODO import * as uuid from 'uuid';
const uuid = {
    v4: () => 'test'
};

export function createRequestDto<T>(payload: T): IRequestDto<T> {
    return {
        payload,
        requestId: uuid.v4(),
    };
}
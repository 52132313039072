import {computed, decorate, observable} from 'mobx';
import {UserProject} from '../../model';
import {IHomeScreenProjectDto} from '../../dtos';

export class ProjectCardStore {
    private _userProject = observable.box<UserProject>();
    private _studentCount = observable.box<number>();
    private _teamCount = observable.box<number>();
    private _evaluationProgress = observable.box<number>();

    public get userProject(): UserProject {
        return this._userProject.get();
    }

    public get studentCount(): number {
        return this._studentCount.get();
    }

    public get teamCount(): number {
        return this._teamCount.get();
    }

    public get evaluationProgress(): number {
        return this._evaluationProgress.get();
    }

    public static fromDto(dto: IHomeScreenProjectDto): ProjectCardStore {
        const x = new ProjectCardStore();
        x._userProject.set(UserProject.fromDto(dto.userProject));
        x._studentCount.set(dto.studentCount);
        x._teamCount.set(dto.teamCount);
        x._evaluationProgress.set(dto.evaluationProgress);
        return x;
    }
}

decorate(ProjectCardStore, {
    studentCount: computed,
    teamCount: computed,
    userProject: computed,
    evaluationProgress: computed,
})
import {NumericFieldStore} from './NumericFieldStore';
import * as React from 'react';
import {createStyles, makeStyles, Slider, SliderProps, Theme, Typography} from '@material-ui/core';
import {observer} from 'mobx-react';
import clsx from 'clsx';

export interface ISliderFieldViewProps {
    field: NumericFieldStore;
    sliderProps?: SliderProps;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {

    }
}));

export const SliderFieldView: React.FC<ISliderFieldViewProps> = observer(props => {
    const classes = useStyles(props);

    const {field} = props;

    const onChange: (event: React.ChangeEvent<{}>, value: number | number[]) => void = (event: React.ChangeEvent<{}>, value: number | number[]) => {
        field.setValue(value as number);
    };

    const sliderProps: SliderProps = {
        step: 10,
        valueLabelDisplay: 'auto',
        ...props.sliderProps,
        value: field.value,
    };

    const className = clsx(classes.root, props.className);

    return (
        <div className={className}>
            <Typography gutterBottom>
                {field.label}
            </Typography>
            <Slider
                {...sliderProps}
                onChange={onChange} // There is a type error if this is in sliderProps
            />
        </div>
    );
});
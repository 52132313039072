export const Urls = {
    getProjectStudents: ['/api/students', 'get-project-students'],
    addStudentsToProject: ['/api/students', 'add-students-to-project'],
    removeStudentsFromProject: ['/api/students', 'remove-students'],
    getEvaluations: ['/api/evaluation', 'get-evaluations'],
    saveEvaluations: ['/api/evaluation', 'save-evaluation'],
    getInstructorsResults: ['/api/results', 'get-results'],
    saveEvaluationReviews: ['/api/results', 'save-evaluation-reviews'],
    getEvaluationReviews: ['/api/results', 'get-evaluation-reviews'], // Not sure if this is needed
    getMyResults: ['/api/my-results', 'get-screen-data'],
    findUser: ['/api/instructors', 'find-user'],
    fetchInstructors: ['/api/instructors', 'fetch-instructors'],
    addInstructors: ['/api/instructors', 'add-instructors'],
    removeInstructors: ['/api/instructors', 'remove-instructors'],

    findConcept: ['/api/home', 'find-concept'],
    getAnalyticsData: ['/api/analytics', 'get-screen-data'],
    getProfileData: ['/api/profile', 'get-screen-data'],
    saveProfile: ['/api/profile', 'save-profile'],
};
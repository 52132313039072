import * as React from 'react';
import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../storeProvider';
import {EvaluationCard} from './EvaluationCard';

export interface IEvaluationCardListProps {
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    noEvaluationsMessage: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: theme.spacing(2),
    }
}));

export const EvaluationCardList: React.FC<IEvaluationCardListProps> = observer(props => {
    const classes = useStyles(props);
    const {evaluationScreenStore} = useStore();
    const {evaluationCards} = evaluationScreenStore;

    if (evaluationCards.length === 0) {
        return (
            <div className={classes.root}>
                <Typography className={classes.noEvaluationsMessage} align={'center'}>
                    You have no teammates.
                </Typography>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            {evaluationCards.map((card, i) => (
                <EvaluationCard
                    key={`card${i}`}
                    store={card}
                />
            ))}
        </div>
    );
});
import {IRequestDto} from './IRequestDto';

export enum RelatedRecordAction {
    Keep,
    RemoveIfUnused,
    // ForceRemove
}

export interface IRemoveStudentsRequestPayload {
    projectId: number;
    userProjectIds: number[];
    userRecordsAction: RelatedRecordAction;
}

export type IRemoveStudentsRequestDto = IRequestDto<IRemoveStudentsRequestPayload>;
import {decorate, observable} from 'mobx';
import {LoginFormStore} from './LoginFormStore';
import {RootStore} from '../../RootStore';

export class LoginScreenStore {
    public readonly loginFormStore: LoginFormStore;

    constructor(private readonly rootStore: RootStore) {
        this.loginFormStore = new LoginFormStore(rootStore);
    }
}

decorate(LoginScreenStore, {
    loginFormStore: observable,
});
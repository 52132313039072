import {ConfirmationDialogStore, DataTableStore, RowDefinition, SnackMessageStore, SnackType} from '../../common';
import {action, computed, decorate, observable} from 'mobx';
import {RootStore} from '../../RootStore';
import {IAddInstructorsRequestPayload, IInstructorsRequestPayload, IRemoveInstructorsRequestPayload} from '../../dtos';
import {InstructorsApi} from './InstructorsApi';
import {UserProject} from '../../model';
import {InstructorRow} from './InstructorRow';
import {merge, split} from '../../shared';
import {ImportDialogStore} from './ImportDialogStore';

export class InstructorsScreenStore {
    private readonly _projectId = observable.box<number | undefined>(undefined);
    private readonly _isBusy = observable.box<boolean>(false);
    public readonly snack: SnackMessageStore;
    public readonly instructorsTableStore: DataTableStore<InstructorRow>;
    public readonly confirmationDialog: ConfirmationDialogStore;
    public readonly importDialog: ImportDialogStore;

    public constructor(public rootStore: RootStore, private readonly instructorsApi: InstructorsApi, snack: SnackMessageStore) {
        this.snack = snack;
        this.importDialog = new ImportDialogStore();
        this.instructorsTableStore = new DataTableStore<InstructorRow>([{
            key: 'name',
            header: 'Name',
            getCellValue: row => row.data.user.name,
            sortFunction: () => 0,
        }, {
            key: 'login',
            header: 'Login',
            getCellValue: row => row.data.user.login,
            sortFunction: () => 0,
        }, {
            key: 'email',
            header: 'Email',
            getCellValue: row => row.data.user.email,
            sortFunction: () => 0,
        }, {
            key: 'password',
            header: 'Password',
            getCellValue: row => row.data.user.password,
            sortFunction: () => 0,
        }]);
        // const rows = _.range(0, 200).map(x => (new RowDefinition(String(x), new InstructorInfo())));
        // this.instructorsTableStore.setRows(rows);
        this.confirmationDialog = new ConfirmationDialogStore('Delete confirmation', 'Delete selected instructors?');
    }

    public get projectId(): number | undefined {
        return this._projectId.get();
    }

    public setProjectId = (id: number | undefined) => {
        this._projectId.set(id);
    };

    public get isBusy(): boolean {
        return this._isBusy.get();
    }

    public fetchInstructors = async (): Promise<void> => {
        const projectId = this.projectId;
        if (!projectId) {
            return;
        }
        this._isBusy.set(true);
        try {
            const payload: IInstructorsRequestPayload = {
                projectId,
            };
            const reply = await this.instructorsApi.fetchInstructors(payload);
            const userProjects = reply.userProjects.map(UserProject.fromDto);
            const rows = userProjects.map(up => new RowDefinition(String(up.id), new InstructorRow(up)));
            this.instructorsTableStore.setRows(rows);
            this.snack.open(`Loaded instructors`, SnackType.Success);
        } catch (e) {
            this.snack.open(`Failed to load instructors. ${e.message}`, SnackType.Error);
        } finally {
            this._isBusy.set(false);
        }
    };

    public goBack = () => {
        this.rootStore.routerStore.goBack();
    };

    public addInstructor = () => {
        const rs = this.rootStore.routerStore;
        rs.push(`${rs.location.pathname}/instructor`)
    };

    public importInstructors = async () => {
        const importResult = await this.importDialog.open();
        if (importResult.isOk) {
            this._isBusy.set(true);
            try {
                const users = importResult.users;
                const requestPayload: IAddInstructorsRequestPayload = {
                    projectId: this.projectId,
                    users: users.map(x => x.toDto()),
                };
                const replyPayload = await this.instructorsApi.addInstructors(requestPayload);
                const userProjects = (replyPayload.userProjects || []).map(UserProject.fromDto);
                const newRows = userProjects.map(x => new RowDefinition(String(x.id), new InstructorRow(x)));
                const oldRows = this.instructorsTableStore.rows;
                const rows = merge(oldRows, newRows, row => row.data.userProject.user!.login);
                this.instructorsTableStore.setRows(rows);
                this.snack.open(`Instructors added`, SnackType.Success);
            } catch (e) {
                this.snack.open(`Failed to save instructors ${e.message}`, SnackType.Error);
            } finally {
                this._isBusy.set(false);
            }
        }
    };

    public removeInstructors = async () => {
        const confirmation = await this.confirmationDialog.open();
        if (confirmation.isConfirmed) {
            this._isBusy.set(true);
            try {
                const [selectedRows, unselectedRows] = split(this.instructorsTableStore.rows, x => !!x.isSelected);
                const reqPayload: IRemoveInstructorsRequestPayload = {
                    userProjectIds: selectedRows.map(row => row.data.userProject.id!),
                };
                const repPayload = await this.instructorsApi.removeInstructors(reqPayload);
                this.snack.open(repPayload.message, SnackType.Success);
                this.instructorsTableStore.setRows(unselectedRows);
            } catch (e) {
                this.snack.open(`Failed to remove instructors. ${e.message}`);
            } finally {
                this._isBusy.set(false);
            }
        }
    };

    public get canRemoveInstructors(): boolean {
        return this.instructorsTableStore.rows.some(x => x.isSelected);
    }
}

decorate(InstructorsScreenStore, {
    isBusy: computed,
    projectId: computed,
    setProjectId: action,
    fetchInstructors: action,
    instructorsTableStore: observable,
    confirmationDialog: observable,
    canRemoveInstructors: computed,
    importInstructors: action,
});
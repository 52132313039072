import {Sentence} from './Sentence';
import { Concept } from './Concept';
import {ISentenceConceptDto} from '../dtos';
import {EvaluationReview} from './EvaluationReview';

export class SentenceConcept {
    public id?: number;
    public sentence?: Sentence;
    public sentenceId?: number;
    public concept?: Concept;
    public conceptId?: number;
    public evaluationReview?: EvaluationReview;
    public evaluationReviewId?: number;

    public static fromDto = (d: ISentenceConceptDto): SentenceConcept => {
        const sc = new SentenceConcept();
        sc.id = d.id;
        sc.concept = d.concept ? Concept.fromDto(d.concept) : undefined;
        sc.conceptId = d.concept ? d.concept.id : d.conceptId;
        sc.sentence = d.sentence ? Sentence.fromDto(d.sentence) : undefined;
        sc.sentenceId = d.sentence ? d.sentence.id : d.sentenceId;
        sc.evaluationReview = d.evaluationReview ? EvaluationReview.fromDto(d.evaluationReview) : undefined;
        sc.evaluationReviewId = d.evaluationReview ? d.evaluationReview.id : d.evaluationReviewId;
        return sc;
    };

    public toDto = (): ISentenceConceptDto => {
        return {
            id: this.id,
            sentence: this.sentence ? this.sentence.toDto() : undefined,
            sentenceId: this.sentence ? this.sentence.id : this.sentenceId,
            concept: this.concept ? this.concept.toDto() : undefined,
            conceptId: this.concept ? this.concept.id : this.conceptId,
            evaluationReview: this.evaluationReview ? this.evaluationReview.toDto() : undefined,
            evaluationReviewId: this.evaluationReview ? this.evaluationReview.id : this.evaluationReviewId,
        };
    }
}
import React from 'react';
import {observer} from 'mobx-react';
import {DataTable} from '../../common';
import {useStore} from '../../storeProvider';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Paper, TableContainer} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
    tableContainer: {
        maxHeight: '100%',
    },
    tablePaper: {
        position: 'absolute',
        top: 64,
        right: 0,
        bottom: 0,
        left: 0,
    }
}));

export const InstructorsTable: React.FC<{}> = observer(props => {
    const {instructorsScreenStore} = useStore();
    const classes = useStyles(props);
    return (
        <Paper className={classes.tablePaper}>
            <TableContainer className={classes.tableContainer}>
                <DataTable
                    store={instructorsScreenStore.instructorsTableStore}
                    tableProps={{
                        stickyHeader: true,
                    }}
                />
            </TableContainer>
        </Paper>
    );
});
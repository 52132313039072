import * as React from 'react';
import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {Project} from '../../model/Project';
import {InstructorsProjectCard} from './InstructorsProjectCard';
import {useStore} from '../../storeProvider';
import {ProjectRole} from '../../dtos';
import {StudentsProjectCard} from './StudentsProjectCard';
import {ProjectCardStore} from './ProjectCardStore';

export interface IProjectListProps {
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    noProjectsMessage: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: theme.spacing(2),
    }
}));

export const ProjectList: React.FC<IProjectListProps> = observer(props => {
    const classes = useStyles(props);
    const {homeScreenStore} = useStore();
    const {projectCards} = homeScreenStore;

    const onEditProject = (project: Project) => {
        homeScreenStore.goToProject(project);
    };

    const onResults = (project: Project) => {
        homeScreenStore.goToResults(project);
    };

    const onMyResults = (project: Project) => {
        homeScreenStore.goToMyResults(project);
    };

    const onStudents = (project: Project) => {
        homeScreenStore.goToStudents(project);
    };

    const onInstructors = (project: Project) => {
        homeScreenStore.goToInstructors(project);
    };

    const onAnalytics = (project: Project) => {
        homeScreenStore.goToAnalytics(project);
    };

    const onEvaluate = (project: Project) => {
        homeScreenStore.goToEvaluation(project);
    };

    const renderProjectCard = (projectCard: ProjectCardStore, index: number) => {
        const roles = projectCard.userProject.roles!;
        if (roles.includes(ProjectRole.Instructor) || roles.includes(ProjectRole.Author)) {
            return (
                <InstructorsProjectCard
                    key={`card${index}`}
                    store={projectCard}
                    onEditProject={onEditProject}
                    onResults={onResults}
                    onStudents={onStudents}
                    onInstructors={onInstructors}
                    onAnalytics={onAnalytics}
                />
            );
        } else if (roles.includes(ProjectRole.Student)) {
            return (
                <StudentsProjectCard
                    key={`card${index}`}
                    userProject={projectCard.userProject}
                    onMyResults={onMyResults}
                    onEvaluate={onEvaluate}
                />
            );
        }
        return 'Unexpected roles';
    };

    if (projectCards.length === 0) {
        return (
            <div className={classes.root}>
                <Typography className={classes.noProjectsMessage} align={'center'}>
                    You have no projects yet. Click the "+" button in the top right corner of the screen to create a new
                    one.
                </Typography>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            {projectCards.map((projectCard, i) => renderProjectCard(projectCard, i))}
        </div>
    );
});
import React from 'react';
import './App.css';
import {StoreProvider, history} from './storeProvider';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import {Route, Router, Switch} from 'react-router';
import {HomeScreen} from './screens/home/HomeScreen';
import {ResultsScreen} from './screens/projectResults/ResultsScreen';
import {StudentsScreen} from './screens/students/StudentsScreen';
import {InstructorsScreen} from './screens/instructors/InstructorsScreen';
import {AnalyticsScreen} from './screens/analytics/AnalyticsScreen';
import {StudentScreen} from './screens/students/StudentScreen';
import {ProjectScreen} from './screens/project/ProjectScreen';
import {StudentGradesScreen} from './screens/studentGrades/StudentGradesScreen';
import {LoginScreen} from './screens/auth/LoginScreen';
import {AccountScreen} from './screens/account/AccountScreen';
import {ProjectEditorScreen} from './screens/projectEditor/ProjectEditorScreen';
import {EvaluationScreen} from './screens/evaluation/EvaluationScreen';
import { MyResultsScreen } from './screens/myResults/MyResultsScreen';
import {InstructorScreen} from './screens/instructors';
import {ProjectResultsConfigScreen} from './screens/projectResultsConfig/ProjectResultsConfigScreen';
import { ProfileScreen } from './screens/profile/ProfileScreen';

const theme = createMuiTheme({
    typography: {},
});

const App: React.FC = () => {
    return (
        <StoreProvider>
            <MuiThemeProvider theme={theme}>
                <Router history={history}>
                    <Switch>
                        <Route exact path='/' component={HomeScreen}/>
                        <Route exact path='/login' component={LoginScreen}/>
                        <Route exact path='/account' component={AccountScreen}/>
                        <Route exact path='/results/:projectId?' component={ResultsScreen}/>
                        <Route exact path='/grades/:projectId/student/:studentId' component={StudentGradesScreen}/>
                        <Route exact path='/students/:projectId' component={StudentsScreen}/>
                        <Route exact path='/students/:projectId/student/:studentId?' component={StudentScreen}/>
                        <Route exact path='/instructors/:projectId' component={InstructorsScreen}/>
                        <Route exact path='/instructors/:projectId/instructor/:instructorId?' component={InstructorScreen}/>
                        <Route exact path='/analytics/:projectId' component={AnalyticsScreen}/>
                        <Route exact path='/project/:projectId?' component={ProjectScreen}/>
                        <Route exact path='/project-editor/:projectId?' component={ProjectEditorScreen}/>
                        <Route exact path='/evaluation/:projectId?' component={EvaluationScreen}/>
                        <Route exact path='/my-results/:projectId?' component={MyResultsScreen}/>
                        <Route exact path='/project-results-config' component={ProjectResultsConfigScreen}/>
                        <Route exact path='/profile' component={ProfileScreen}/>
                    </Switch>
                </Router>
            </MuiThemeProvider>
        </StoreProvider>
    );
};

export default App;

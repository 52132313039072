import React, {useEffect, useMemo} from 'react';
import {AppBar, createStyles, makeStyles, Theme, Toolbar, Typography, useMediaQuery} from '@material-ui/core';
import {observer} from 'mobx-react';
import {UserAppBarButton} from '../auth/UserAppBarButton';
import {useStore} from '../../storeProvider';
import {SnackMessage} from '../../common';
import {RouteComponentProps} from 'react-router';
import {MyResultCardList} from './MyResultCardList';
import {BackButton, Loading} from '../../common';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    flex: {
        flex: 1,
    },
    mobileNotification: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

export interface IMyResultsScreenRouteParams {
    projectId: string;
}

export interface IMyResultsScreenProps extends RouteComponentProps<IMyResultsScreenRouteParams> {
}

export const MyResultsScreen: React.FC<IMyResultsScreenProps> = observer(props => {
    const classes = useStyles(props);
    const {myResultsScreenStore: store, authStore} = useStore();
    const projectId = useMemo(() => parseInt(props.match.params.projectId, 10), [props.match.params.projectId]);

    useEffect(() => {
        if (!isNaN(projectId) && authStore.isRehydrated) {
            store.setId(projectId);
            store.fetchScreenData();
        }
    }, [store, projectId, authStore.isRehydrated]);

    const onBack = () => {
        store.goBack();
    };

    const isMobile = !useMediaQuery('(min-width:1000px)');
    const title: string[] = [];
    title.push(`My Results`);
    const team = store.team;
    if (team && !isMobile) {
        title.push(` - Team ${team.name} in project ${team.project!.name}`);
    }

    return (
        <div className={classes.root}>
            <AppBar position='static' color='primary'>
                <Toolbar>
                    <BackButton onClick={onBack}/>
                    <Typography variant='h6' color='inherit' className={classes.flex}>
                        {title.join('')}
                    </Typography>
                    <UserAppBarButton/>
                </Toolbar>
            </AppBar>
            {isMobile && team ? (
                <Typography variant={'body1'} className={classes.mobileNotification}>
                    {`Team ${team.name} in project ${team.project!.name}`}
                </Typography>
            ) : null}
            <MyResultCardList/>
            <SnackMessage snackMessageStore={store.snack}/>
            <Loading isOpen={store.isBusy}/>
        </div>
    );
});
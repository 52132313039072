import {IProjectDto, ProjectStatus} from '../dtos';
import {Team} from './Team';
import {Evaluation} from './Evaluation';
import {UserProject} from './UserProject';
import {ProjectConcept} from './ProjectConcept';

export class Project {
    public id?: number = undefined;
    public name: string;
    public description: string;
    public creationTime?: Date;
    public status?: ProjectStatus = ProjectStatus.Closed;
    public teams?: Team[];
    public evaluations?: Evaluation[];
    public userProjects?: UserProject[];
    public projectConcepts?: ProjectConcept[];

    constructor(name: string, description: string, created?: Date, status?: ProjectStatus) {
        this.name = name;
        this.description = description;
        this.creationTime = created;
        this.status = status;
    }

    public static fromDto = (d: IProjectDto): Project => {
        const p = new Project(d.name, d.description, d.creationTime, d.status);
        p.id = d.id;
        p.teams = d.teams ? d.teams.map(Team.fromDto) : undefined;
        p.evaluations = d.evaluations ? d.evaluations.map(Evaluation.fromDto) : undefined;
        p.userProjects = d.userProjects ? d.userProjects.map(UserProject.fromDto) : undefined;
        p.projectConcepts = d.projectConcepts ? d.projectConcepts.map(ProjectConcept.fromDto) : undefined;
        return p;
    };

    public toDto = (): IProjectDto => {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            creationTime: this.creationTime,
            status: this.status,
            teams: this.teams ? this.teams.map(x => x.toDto()) : undefined,
            evaluations: this.evaluations ? this.evaluations.map(x => x.toDto()) : undefined,
            userProjects: this.userProjects ? this.userProjects.map(x => x.toDto()) : undefined,
            projectConcepts: this.projectConcepts ? this.projectConcepts.map(x => x.toDto()) : undefined,
        };
    }
}
import {User} from './User';
import {Project} from './Project';
import {IUserProjectDto, ProjectRole} from '../dtos';

export class UserProject {
    public id?: number = undefined;
    public user?: User;
    public project?: Project;
    public roles?: ProjectRole[];

    public static fromDto = (d: IUserProjectDto): UserProject => {
        const u = new UserProject();
        u.id = d.id;
        u.user = d.user ? User.fromDto(d.user) : undefined;
        u.project = d.project ? Project.fromDto(d.project) : undefined;
        u.roles = d.roles;
        return u;
    };

    public toDto = (): IUserProjectDto => {
        return {
            id: this.id,
            user: this.user ? this.user.toDto() : undefined,
            project: this.project ? this.project.toDto() : undefined,
            roles: this.roles,
        };
    };

    public toString = (): string => {
        const s = [`id: ${this.id}`];
        if (this.user) {
            s.push(`user: "${this.user.login}"`);
        }
        if (this.project) {
            s.push(`project: "${this.project.name}"`);
        }
        if (this.roles && this.roles.length > 0) {
            s.push(`roles: [${this.roles.join(', ')}]`);
        }
        return s.join(', ');
    }
}
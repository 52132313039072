import * as React from 'react';
import {Button, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {getAttribute} from '../../getAttribute';
import {AnnotationTextModel} from './AnnotationTextModel';
import {observer} from 'mobx-react';
import {AnnotationPalette} from './AnnotationPalette';

export interface IConceptButtonsProps {
    annotationText?: AnnotationTextModel;
    concepts: string[];
    disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: theme.spacing(1)
    },
    button: {
        marginRight: theme.spacing(1)
    },
    grid: {}
}));

export const ConceptButtons: React.FC<IConceptButtonsProps> = observer(props => {
    const {annotationText, concepts, disabled} = props;
    const classes = useStyles(props);

    const renderConcept = (concept: string, conceptIndex: number) => {
        return (
            <Grid key={`Button${conceptIndex}`} item xs={12} sm={6} md={3}>
                <Button
                    data-concept={concept}
                    variant='contained'
                    onClick={onConceptClick}
                    className={classes.button}
                    style={AnnotationPalette.getButtonStyle(conceptIndex)}
                    fullWidth={true}
                    disabled={disabled}
                >
                    {concept}
                </Button>
            </Grid>
        );
    };

    const onConceptClick = (e: React.MouseEvent) => {
        if (disabled || !annotationText) {
            return;
        }
        const concept = getAttribute(e.target as Element, 'data-concept');
        annotationText.toggleConceptOnSelectedSentences(concept);
    };

    return (
        <div className={classes.root}>
            <Grid className={classes.grid} container spacing={1}>
                {concepts.map((concept, conceptIndex) => renderConcept(concept, conceptIndex))}
            </Grid>
        </div>
    );
});
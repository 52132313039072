import React, {useEffect, useMemo} from 'react';
import {AppBar, createStyles, makeStyles, Theme, Toolbar, Typography} from '@material-ui/core';
import {observer} from 'mobx-react';
import {UserAppBarButton} from '../auth/UserAppBarButton';
import {StudentsTable} from './StudentsTable';
import {useStore} from '../../storeProvider';
import {BackButton} from '../../common';
import {ConfirmationDialog} from '../../common';
import {TableToolbarButtons} from '../../common';
import {RouteComponentProps} from 'react-router';
import {SnackMessage} from '../../common';
import {ImportDialog} from './ImportDialog';
import {Loading} from '../../common';

const useStyles = makeStyles((theme: Theme) => createStyles({
    flex: {flex: 1},
    addStudentButton: {},
    importStudentsButton: {},
    removeStudentsButton: {},
}));

export interface IStudentsScreenRouteParams {
    projectId: string;
}

export interface IStudentsProps extends RouteComponentProps<IStudentsScreenRouteParams> {
}

export const StudentsScreen: React.FC<IStudentsProps> = observer(props => {
    const classes = useStyles(props);
    const {studentsScreenStore} = useStore();

    const projectId = useMemo(() => parseInt(props.match.params.projectId, 10), [props.match.params.projectId]);

    useEffect(() => {
        if (!isNaN(projectId)) {
            studentsScreenStore.setId(projectId);
            studentsScreenStore.fetchStudents();
        }
    }, [studentsScreenStore, projectId]);

    const onBack = () => {
        studentsScreenStore.goBack();
    };

    const onAddStudent = () => {
        studentsScreenStore.addStudent();
    };

    const onImportStudents = () => {
        studentsScreenStore.importStudents();
    };

    const onRemoveStudents = () => {
        studentsScreenStore.removeStudents();
    };

    return (
        <div>
            <AppBar position='static' color='primary'>
                <Toolbar>
                    <BackButton onClick={onBack}/>
                    <Typography variant='h6' color='inherit' className={classes.flex}>
                        Students
                    </Typography>
                    <TableToolbarButtons
                        onAdd={onAddStudent}
                        onImport={onImportStudents}
                        onRemove={onRemoveStudents}
                        canRemove={studentsScreenStore.canRemoveStudents}
                    />
                    <div className={classes.flex}/>
                    <UserAppBarButton/>
                </Toolbar>
            </AppBar>
            <StudentsTable/>
            <ConfirmationDialog store={studentsScreenStore.confirmationDialog}/>
            <SnackMessage snackMessageStore={studentsScreenStore.snackMessage}/>
            <ImportDialog store={studentsScreenStore.importDialog}/>
            <Loading isOpen={studentsScreenStore.isBusy}/>
        </div>
    );
});
import React, { useEffect, useMemo } from 'react';
import {AppBar, createStyles, makeStyles, Theme, Toolbar, Typography} from '@material-ui/core';
import {observer} from 'mobx-react';
import {UserAppBarButton} from '../auth/UserAppBarButton';
import {BackButton} from '../../common';
import {useStore} from '../../storeProvider';
import {ContributionDiagram} from './ContributionDiagram';
import {RouteComponentProps} from 'react-router';
import { when } from 'mobx';

const useStyles = makeStyles((theme: Theme) => createStyles({
    flex: {flex: 1},
}));

export interface IAnalyticsScreenRouteProps {
    projectId: string;
}

export interface IAnalyticsProps extends RouteComponentProps<IAnalyticsScreenRouteProps> {
}

export const AnalyticsScreen: React.FC<IAnalyticsProps> = observer(props => {
    const classes = useStyles(props);
    const {analyticsScreenStore} = useStore();
    const projectId = useMemo(() => parseInt(props.match.params.projectId, 10), [props.match.params.projectId]);

    useEffect(() => {
        when(() => analyticsScreenStore.rootStore.authStore.isRehydrated, () => {
            if (!isNaN(projectId)) {
                analyticsScreenStore.setProjectId(projectId);
                analyticsScreenStore.fetchScreenData();
            }
        });
    }, [analyticsScreenStore, projectId]);

    const onBack = () => {
        analyticsScreenStore.goBack();
    };

    return (
        <div>
            <AppBar position='static' color='primary'>
                <Toolbar>
                    <BackButton onClick={onBack}/>
                    <Typography variant='h6' color='inherit' className={classes.flex}>
                        Analytics
                    </Typography>
                    <UserAppBarButton/>
                </Toolbar>
            </AppBar>
            <ContributionDiagram/>
        </div>
    );
});
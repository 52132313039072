import React from 'react';
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import {ConfirmationDialogStore} from './confirmationDialogStore';
import {observer} from 'mobx-react';
import { CheckboxFieldView } from '../fields';

export interface IConfirmationDialogProps {
    store: ConfirmationDialogStore;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    flex: {flex: 1},
    dialog: {},
}));

export const ConfirmationDialog: React.FC<IConfirmationDialogProps> = observer(props => {
    const {store} = props;
    const {isOpen, close, title, contentText} = store;
    const classes = useStyles(props);

    const onClose = () => {
        close(false);
    };

    const onOk = () => {
        close(true);
    };

    const onCancel = () => {
        close(false);
    };

    return (
        <Dialog
            className={classes.dialog}
            open={isOpen}
            onClose={onClose}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {contentText}
                </Typography>
                {store.optionFields.map((f, i) => {
                    return (
                        <CheckboxFieldView key={`Option${i}`} field={f}/>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>
                    Cancel
                </Button>
                <Button onClick={onOk}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
});
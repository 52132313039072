import {SortDirection} from '@material-ui/core/TableCell';
import {action, computed, decorate, observable} from 'mobx';
import {IColumnDefinition} from './IColumnDefinition';
import {RowDefinition} from './RowDefinition';

export interface IDataTableCursor {
    columnIndex: number;
    rowIndex: number;
}

export enum SelectionMode {
    Row = 'Row',
    Cell = 'Cell',
}

export class DataTableStore<T> {
    private _columns = observable.array<IColumnDefinition<T>>();
    private _rows = observable.array<RowDefinition<T>>();
    private _cursor = observable.box<IDataTableCursor>(undefined);
    private _selectionMode = observable.box<SelectionMode>(undefined);

    public orderBy: string = '';
    public order: SortDirection = 'asc' as SortDirection;

    public constructor(columns: Array<IColumnDefinition<T>>, selectionMode: SelectionMode = SelectionMode.Row) {
        this._columns.replace(columns);
        this._selectionMode.set(selectionMode);
    }

    public sort = (columnKey: string): void => {
        this.order = (columnKey === this.orderBy && this.order === 'asc') ? 'desc' : 'asc';
        this.orderBy = columnKey;
        const column = this.orderColumn;
        if (column) {
            let newRows = this._rows.slice().sort(column.sortFunction);
            if (this.order === 'desc') {
                newRows = newRows.reverse();
            }
            this._rows.replace(newRows);
        }
        this.updateAlterations();
    };

    public get orderColumn(): IColumnDefinition<T> | undefined {
        if (this.orderBy === '') {
            return undefined;
        }
        const column = this._columns.find(c => c.key === this.orderBy);
        if (!column) {
            const availableKeys = this._columns.map(x => `"${x.key}"`).join(', ');
            throw new Error(`Unknown column. Key "${this.orderBy}" does not exist. Available keys: ${availableKeys}`);
        }
        return column;
    }

    private updateAlterations = () => {
        const column = this.orderColumn;
        if (column) {
            let currentAlteration: boolean = false;
            let currentValue: any = undefined;
            this.rows.forEach(row => {
                const value = column.getCellValue(row);
                if (value !== currentValue) {
                    currentAlteration = !currentAlteration;
                }
                currentValue = value;
                row.setAlteration(currentAlteration);
            })
        } else {
            this.rows.forEach(row => row.setAlteration(false));
        }
    };

    public get selectionMode(): SelectionMode {
        return this._selectionMode.get();
    }

    public get columns(): Array<IColumnDefinition<T>> {
        return this._columns;
    }

    public get rows(): Array<RowDefinition<T>> {
        return this._rows;
    }

    public get selectedRows(): Array<RowDefinition<T>> {
        return this._rows.filter(x => x.isSelected);
    }

    public removeSelectedRows = () => {
        const rowsToRemain = this._rows.filter(x => !x.isSelected);
        this.setRows(rowsToRemain);
    }

    public get cursor(): IDataTableCursor {
        return this._cursor.get();
    }

    public setCursor = (cursor: IDataTableCursor) => {
        const oldCursor = this.cursor;
        if (oldCursor) {
            const row = this.rows[oldCursor.rowIndex];
            // Weird thing is happening when table contents change. Old row may be missing because rows changed
            if (row) {
                row.setCursor(undefined);
            }
        }
        this._cursor.set(cursor);
        const newCursor = this.cursor;
        if (newCursor) {
            const row = this.rows[newCursor.rowIndex];
            row.setCursor(newCursor.columnIndex);
        }
    };

    public selectAll = (isSelected: boolean): void => {
        this._rows.forEach(row => row.select(isSelected));
    };

    public selectRow = (row: RowDefinition<T>, rowIndex: number, isSelected: boolean): void => {
        this._rows[rowIndex].select(isSelected);
    };

    public setRows = (rows: Array<RowDefinition<T>>): void => {
        let newRows = rows;
        if (this.orderBy != null) {
            const column = this._columns.find(c => c.key === this.orderBy);
            if (column && column.sortFunction) {
                newRows = newRows.sort(column.sortFunction);
            }
        }
        this._rows.replace(newRows);
        this.updateAlterations();
    };

    public setColumns = (columns: Array<IColumnDefinition<T>>): void => {
        this._columns.replace(columns);
    }
}

decorate(DataTableStore, {
    columns: computed,
    orderBy: observable,
    orderColumn: computed,
    order: observable,
    rows: computed,
    selectedRows: computed,
    selectAll: action,
    selectRow: action,
    setRows: action,
    cursor: computed,
    selectionMode: computed,
    removeSelectedRows: action,
});
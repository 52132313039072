import {action, computed, decorate, observable} from 'mobx';
import {SnackMessageStore, SnackType} from '../../common/components';
import {RootStore} from '../../RootStore';
import {ProfileApi} from './ProfileApi';
import {TextFieldStore} from '../../common/fields';
import {ISaveProfileRequestPayload} from '../../dtos';
import {User} from '../../model';

export class ProfileScreenStore {
    private _isBusy = observable.box<boolean>(false);
    public readonly snack: SnackMessageStore;
    public readonly login: TextFieldStore;
    public readonly email: TextFieldStore;
    public readonly password: TextFieldStore;
    private _user = observable.box<User>();

    constructor(
        public rootStore: RootStore,
        private readonly profileApi: ProfileApi,
        snack: SnackMessageStore
    ) {
        this.snack = snack;
        this.login = new TextFieldStore('Login', '');
        this.email = new TextFieldStore('Email', '');
        this.password = new TextFieldStore('Password', '');
    }

    public get isBusy(): boolean {
        return this._isBusy.get();
    }

    public fetchScreenData = async (): Promise<void> => {
        this._isBusy.set(true);
        try {
            const reply = await this.profileApi.fetchScreenData({});
            const profile = reply.profile;
            const user = profile.user;
            this._user.set(User.fromDto(user));
            this.login.setValue(user.login);
            this.email.setValue(user.email);
            this.password.setValue(user.password || '');
            this.snack.open(`Projects loaded`, SnackType.Success);
        } catch (e) {
            this.snack.open(e.message, SnackType.Error);
        } finally {
            this._isBusy.set(false);
        }
    };

    public cancel = () => {
        this.rootStore.routerStore.goBack();
    }

    public saveProfile = async () => {
        this._isBusy.set(true);
        try {
            const userDto = this._user.get().toDto();
            userDto.login = this.login.value;
            userDto.email = this.email.value;
            userDto.password = this.password.value;
            const request: ISaveProfileRequestPayload = {
                profile: {
                    user: userDto,
                }
            };
            const reply = await this.profileApi.saveProfile(request);
            this.snack.open(`Profile saved`, SnackType.Success);
            this.rootStore.routerStore.goBack(); // Going back to home screen
        } catch (e) {
            this.snack.open(`Failed to save profile. ${e.message}`, SnackType.Error);
        } finally {
            this._isBusy.set(false);
        }
    }
}

decorate(ProfileScreenStore, {
    snack: observable,
    isBusy: computed,
    fetchScreenData: action,
    saveProfile: action,
    cancel: action,
});
import {action, decorate, observable} from 'mobx';

export class BooleanFieldStore {
    public value: boolean;
    public disabled: boolean;
    public label: string;

    constructor(label: string, value: boolean) {
        this.label = label;
        this.value = value;
        this.disabled = false;
    }

    public setLabel = (label: string) => {
        this.label = label;
    };

    public setValue = (value: boolean) => {
        this.value = value;
    };

    public setDisabled = (disabled: boolean) => {
        this.disabled = disabled;
    };

    public clear = () => {
        this.value = false;
    }
}

decorate(BooleanFieldStore, {
    value: observable,
    label: observable,
    disabled: observable,
    setLabel: action,
    setValue: action,
    clear: action,
});
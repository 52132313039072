import * as _ from 'lodash';
export type Func<T, U> = (x: T) => U;
export type MapFunc<T, U> = (x: T, y: number) => U;
export type FuncAsync<T, U> = (x: T) => Promise<U>;

export type Predicate<T> = Func<T, boolean>;

export const mapBy = <T, TK, TV = T>(source: T[], keySelector: (s: T, i: number) => TK, valueSelector?: (s: T, i: number) => TV): Map<TK, TV> =>
    new Map(source.map((s, i) => [keySelector(s, i), valueSelector ? valueSelector(s, i) : s] as [TK, TV]));

export const filterMap = <TK, TV>(source: Map<TK, TV>, predicate: (k: TK, v: TV, i: number) => boolean): Map<TK, TV> =>
    new Map(Array.from(source.entries()).filter(([k, v], i) => predicate(k, v, i)));

export const groupBy = <T, TK, TV = T>(source: T[], keySelector: (s: T) => TK, valueSelector?: (s: T) => TV): Map<TK, TV[]> => {
    const result: Map<TK, TV[]> = new Map();
    source.forEach(s => {
        const key: TK = keySelector(s);
        const value: TV = valueSelector ? valueSelector(s) : s as any as TV;
        if (!result.has(key)) {
            result.set(key, [value]);
        } else {
            result.get(key)!.push(value);
        }
    });
    return result;
};

// Returns all items from a that are not present in b. Items compared by key provided by key getter.
export const except = <T, K>(a: T[], b: T[], keyGetter: Func<T, K>): T[] => {
    if (!a) {
        return [];
    }
    if (!b || b.length === 0) {
        return a;
    }
    const set = new Set<K>(b.map(keyGetter));
    return a.filter(x => !set.has(keyGetter(x)));
};

export type Match<T> = [T | undefined, T] | [T, T | undefined];

// Returns array of pairs: item from a + matching item from b.
export const match = <T, K>(a: T[], b: T[], keyGetter: Func<T, K>): Array<Match<T>> => {
    // const bByKey = mapBy(b, keyGetter);
    const matches: Map<K, Match<T>> = mapBy(a, keyGetter, x => [x, undefined] as Match<T>);
    // const matches = aByKey.map(x => [x, bByKey.get(keyGetter(x))] as [T, T]);
    b.forEach(x => {
        const key = keyGetter(x);
        const match = matches.get(key);
        if (match) {
            match[1] = x;
        } else {
            matches.set(key, [undefined, x] as Match<T>);
        }
    });
    return Array.from(matches.values());
};

export const mapValues = <TK, TV, TVN>(source: Map<TK, TV>, valueSelector: (s: TV) => TVN): Map<TK, TVN> =>
    new Map(Array.from(source.entries()).map(([k, v]) => [k, valueSelector(v)] as [TK, TVN]));

export const sortedEntries = <TV>(source: Map<number, TV>): Array<[number, TV]> =>
    Array.from(source.entries())
        .sort((a, b) => a[0] - b[0]);

export const diffMap = <TK, TV>(a: Map<TK, TV>, b: Map<TK, TV>): Map<TK, TV> => {
    return new Map(Array.from(a.entries()).filter(([k, v]) => !b.has(k)));
};

export const randomPassword = (length: number = 5): string => {
    const characters = 'ABCDEFGHJKLMNPQRTUVWXYZ123456789';
    const password = _.range(length).map(index => {
        const randomCharIndex = _.random(0, characters.length - 1, false);
        const character = characters[randomCharIndex];
        return character;
    }).join('');
    return password;
};

export const unique = <T>(source: T[]): T[] => {
    const s = new Set(source);
    const result = Array.from(s);
    return result;
};

export const uniqueBy = <T>(source: T[], fn: (x: T) => any): T[] => {
    const m = mapBy(source, fn);
    return Array.from(m.values());
};

export const flatten = <T>(source: T[][]): T[] => {
    const result: T[] = [];
    source.forEach(x => result.push(...x));
    return result;
};

export const flatMap = <T, U>(source: T[], f: MapFunc<T, U[] | undefined>): U[] => {
    const result: U[] = [];
    source.forEach((x, i) => {
        const items = f(x, i);
        if (items && items.length > 0) {
            result.push(...items);
        }
    });
    return result;
};

export const flattenValues = <K, V>(source: Map<K, V[]>): V[] => {
    const result: V[] = [];
    Array.from(source.values()).forEach(v => {
        result.push(...v);
    });
    return result;
};

export const split = <T>(source: T[], predicate: Predicate<T>): [T[], T[]] => {
    const positive: T[] = [];
    const negative: T[] = [];
    source.forEach(x => (predicate(x) ? positive : negative).push(x));
    return [positive, negative];
};

export const splitOne = <T>(source: T[], predicate: Predicate<T>): [T | undefined, T[]] => {
    const index = source.findIndex(predicate);
    if (index === -1) {
        return [undefined, source];
    }
    const theItem = source[index];
    const others = [...source.slice(0, index), ...source.slice(index + 1)];
    return [theItem, others];
};

export const equalItems = <T>(a: T[], b: T[]): boolean => {
    if (a.length !== b.length) {
        return false;
    }
    const setB = new Set(b);
    if (a.some(x => !setB.has(x))) {
        return false;
    }
    return true;
};

export const merge = <T>(a: T[], b: T[], k: Func<T, string | number>): T[] => {
    const r = mapBy(a, k);
    b.forEach(x => r.set(k(x), x));
    return Array.from(r.values());
};

export const isEmptyObject = (a: object): boolean => {
    return Object.values(a).find(x => x != null) != null;
};

export const ellipsis = (s: string, maxLength: number = 32): string => {
    if (!s) {
        return s;
    }
    if (s.length <= maxLength) {
        return s;
    }
    return s.slice(0, maxLength - 3)
};

export const logPresence = (x: any): string => {
    if (x == null) {
        return '[not present]';
    }
    return '[present]';
};

export const logIds = (x: Array<{id: number}>): string => {
    if (x == null) {
        return '[NULL]';
    }
    return `Array of ${x.length}: [${x.map(x => x.id).join(', ')}]`;
};

export const queryParameter = (values: any[]): string => {
    return `(${values.map(_ => '?').join(', ')})`;
};

export const compareArrays = <T, U>(a: T[] | undefined, b: U[] | undefined, c: (x: T, y: U) => number): number => {
    const aIsEmpty = !a || a.length === 0;
    const bIsEmpty = !b || b.length === 0;
    if (aIsEmpty) {
        if (bIsEmpty) {
            return 0;
        }
        return -1;
    }
    if (bIsEmpty) {
        return 1;
    }
    const dl = a!.length - b!.length;
    if (dl !== 0) {
        return dl;
    }
    for (let i = 0; i < a!.length; ++i) {
        const ic = c(a![i], b![i]);
        if (ic !== 0) {
            return ic;
        }
    }
    return 0;
};

export const arraysEqual = <T, U>(a: T[] | undefined, b: U[] | undefined, c: (x: T, y: U) => boolean): boolean => {
    const aIsEmpty = !a || a.length === 0;
    const bIsEmpty = !b || b.length === 0;
    if (aIsEmpty) {
        return bIsEmpty;
    }
    if (bIsEmpty) {
        return false;
    }
    for (let i = 0; i < a!.length; ++i) {
        if (!c(a![i], b![i])) {
            return false;
        }
    }
    return true;
};

export const setsEqual = <T>(a: Set<T>, b: Set<T>): boolean => {
    if (!a) {
        return !b;
    }
    if (!b) {
        return false;
    }
    const lenDiff = a.size - b.size;
    if (lenDiff !== 0) {
        return false;
    }
    return Array.from(a).every(x => b.has(x));
};

const ALMOST_0 = 0.001;

export const weightedMedian = (values: number[], weights: number[]): number => {
    if (values.length !== weights.length) {
        throw new Error(`Numbers and weights have different lengths`);
    }

    let pairs = values.map((v, i) => [v, weights[i]] as [number, number]);
    pairs.sort((a, b) => a[0] - b[0]);
    const totalWeight = weights.reduce((a, b) => a + b, 0);
    if (totalWeight === 0) {
        return 0;
    }
    const halfWeight = totalWeight * 0.5;
    let accumulatedWeight = pairs[0][1];
    let i = 0;
    while (accumulatedWeight <= halfWeight) {
        accumulatedWeight += pairs[++i][1];
    }
    accumulatedWeight -= pairs[i][1];
    if (halfWeight - accumulatedWeight < ALMOST_0) {
        return (pairs[i][0] + pairs[i - 1][0]) * 0.5;
    }
    return pairs[i][0];
};

export const median = (values: Array<number | undefined>): number | undefined => {
    values = values.filter(x => x !== undefined);
    values.sort();
    if (values.length === 0) {
        return undefined;
    }
    if (values.length % 2 ===0) {
        const m = values.length / 2;
        return (values[m]! + values[m - 1]!) * 0.5;
    } else {
        const m = (values.length - 1) / 2;
        return values[m];
    }
};

export const mean = (values: Array<number | undefined>): number | undefined => {
    values = values.filter(x => x !== undefined);
    const s = sum(values);
    if (s === undefined) {
        return undefined;
    }
    return s / values.length;
};

export const normalize = (values: Array<number | undefined>): Array<number | undefined> => {
    const nums: number[] = values.filter(x => x !== undefined) as number[];
    const sum = nums.reduce((a, b) => a + b, 0);
    if (sum === 0) {
        return values;
    }
    const m = nums.length / sum;
    return values.map(x => x === undefined ? undefined : x * m);
};

export const sum = (values: Array<number | undefined>): number => {
    return values.reduce((a: number, b: number | undefined) => b !== undefined ? a + b : a, 0);
};

export class Matrix {
    private _data: Array<number | undefined>;
    private _rowLength: number;

    public static create(rowLength: number): Matrix {
        const data = [];
        const dataLength = rowLength * rowLength;
        for (let i = 0; i < dataLength; ++ i) {
            data.push(0);
        }
        return new Matrix(data, rowLength);
    }

    // data contains flattened rows
    constructor(data: Array<number | undefined>, rowLength?: number) {
        this._data = data;
        if (!rowLength) {
            rowLength = Math.sqrt(data.length);
        }
        this._rowLength = rowLength;
    }

    public get data(): Array<number | undefined> {
        return this._data;
    }

    public get rowCount(): number {
        return this._data.length / this._rowLength;
    }

    public get columnCount(): number {
        return this._rowLength;
    }

    public get dimensions(): number[] {
        return [this.rowCount, this.columnCount];
    }

    // i: row, j: column
    private getIndex = (i: number, j: number): number => i * this._rowLength + j;

    public get = (i: number, j: number): number | undefined => {
        return this._data[this.getIndex(i, j)];
    };

    public set = (value: number, i: number, j: number): this => {
        this._data[this.getIndex(i, j)] = value;
        return this;
    };

    public getRow = (i: number): Array<number | undefined> => {
        const s = i * this._rowLength;
        return this._data.slice(s, s + this._rowLength);
    };

    public getRows = (): Array<Array<number | undefined>> => {
        const rows: Array<Array<number | undefined>> = [];
        for (let k = 0; k < this._data.length; k += this._rowLength) {
            rows.push(this._data.slice(k, k + this._rowLength));
        }
        return rows;
    };

    public getColumns = (): Array<Array<number | undefined>> => {
        const columns: Array<Array<number | undefined>> = [];
        for (let k = 0; k < this._rowLength; ++k) {
            columns.push([]);
        }
        this.forEach((x, i, j) => {
            columns[j].push(x);
        });
        return columns;
    };

    public getColumn = (j: number): Array<number | undefined> => {
        const col: Array<number | undefined> = [];
        for (let i = j; i < this._data.length; i += this._rowLength) {
            col.push(this._data[i]);
        }
        return col;
    };

    // normalise columns
    public normalise = (): this => {
        const columns = this.getColumns();
        const sums = columns.map(sum);
        // const rowCount = this.rowCount;
        const mults = columns.map((c, i) => {
            const values = c.filter(x => x !== undefined);
            return values.length > 0 ? values.length / sums[i] : undefined;
        });
        this.forEach((x, i, j, k) => {
            let v = x !== undefined ? x * mults[j]! : undefined;
            if (v !== undefined && isNaN(v)) {
                v = undefined;
            }
            this._data[k] = v;
        });
        return this;
    };

    public forEach = (f: (x: number | undefined, i: number, j: number, k: number) => void): this => {
        let i = 0;
        let j = 0;
        for (let k = 0; k < this._data.length; ++k) {
            f(this._data[k], i, j, k);
            ++j;
            if (j === this._rowLength) {
                j = 0;
                ++i;
            }
        }
        return this;
    };

    public map = (f: (x: number | undefined, i: number, j: number, k: number) => number): Matrix => {
        const data: number[] = [];
        this.forEach((x, i, j, k) => {
            data.push(f(x, i, j, k));
        });
        return new Matrix(data, this._rowLength);
    };

    public deleteDiagonal = (): this => {
        for (let k = 0; k < this._rowLength; ++k) {
            this.set(0, k, k);
        }
        return this;
    };

    public clone(): Matrix {
        return new Matrix(Array.from(this.data), this._rowLength);
    }
}

export const exceptAt = <T>(values: T[], ...indices: number[]): T[] => {
    indices.sort().push(values.length);
    let i = 0;
    const result: T[] = [];
    indices.forEach(e => {
        while (i < e) {
            result.push(values[i]);
            ++i;
        }
        ++i;
    });
    return result;
};

export const auxMatrix = (a: Matrix, w: Matrix): Matrix => {
    if (a.data.length !== w.data.length) {
        throw new Error(`Failed to calculate aux matrix, a and w are expected to be of the same size`);
    }
    if (a.data.some(x => x === undefined)) {
        throw new Error('Failed to calculate aux matrix, a contains undefined elements');
    }
    if (w.data.some(x => x === undefined)) {
        throw new Error('Failed to calculate aux matrix, w contains undefined elements');
    }
    const data: Array<number | undefined> = [];
    a.forEach((x, i, j, d) => {
        const numValues: number[] = [];
        const denomValues: number[] = [];
        const numWeights: number[] = [];
        const denomWeights: number[] = [];
        if (i !== j) {
            for (let k = 0; k < a.columnCount; ++k) {
                // if (k !== i && k !== j && i !== j) {
                if (k !== i && k !== j) {
                    const aik = a.get(i, k);
                    const ajk = a.get(j, k);
                    if (aik !== undefined && ajk !== undefined) {
                        const pairEffort = aik + ajk;
                        let numValue = aik;
                        let denomValue = ajk;
                        if (pairEffort === 0) {
                            numValue = 0;
                            denomValue = 0;
                        } else {
                            numValue /= pairEffort;
                            denomValue /= pairEffort;
                        }
                        numValues.push(numValue);
                        denomValues.push(denomValue);
                        const minWeight = Math.min(w.get(i, k)!, w.get(j, k)!)
                        // numWeights.push(w.get(i, k)!);
                        // denomWeights.push(w.get(j, k)!);
                        numWeights.push(minWeight);
                        denomWeights.push(minWeight);
                    }
                }
            }
        }
        if (numValues.length === 0) {
            data[d] = 1;
        } else {
            const denomMedian = weightedMedian(denomValues, denomWeights);
            if (denomMedian === 0) {
                data[d] = 0;
            } else {
                data[d] = weightedMedian(numValues, numWeights) / denomMedian;
            }
        }
    });
    return new Matrix(data, a.columnCount);
};

export const computeBonusesForEvaluations = (qualities: Matrix): Array<number | undefined> => {
    const MAX_QUALITY = 100; // TODO move to project settings
    return qualities.getColumns().map(row => mean(row.map(x => x || 0))).map(x => x! / MAX_QUALITY);
};

export const computeConsistency = (givenGrades: Matrix, trueGrades: Array<number | undefined>) => {
    return givenGrades.getColumns().map((column, evaluatorIndex) => {
        const meanA = mean(column) || 1; // Mean of the grades the evaluator gave to teammates. If the evaluator didn't grade a teammate then we use meanA.
        const errors = column.map((a, evaluatedIndex) => {
            a = a === undefined ? meanA : a;
            const t = trueGrades[evaluatedIndex] || 0;
            if (t === 0) {
                if (a === t) {
                    return 0;
                }
                return 1;
            }
            const error = Math.abs(a - t)/t;
            return error;
        });
        const meanError = mean(errors)!;
        return Math.max(0, 1 - meanError);
    });
};

export const compareOptionalNumbers = (a?: number, b?: number): number => {
    if (a === undefined) {
        return b === undefined ? 0 : -1;
    }
    if (b === undefined) {
        return 1;
    }
    return a - b;
};

// If a user hasn't evaluated any of the teammates then the column is filled with undefined values
// In this case we set the column to 1s
// If some of the values in a column are undefined (but not all) then the user has evaluated part of the group
// We assume that the missing evaluations are average
export const withDefaultGrades = (m: Matrix): Matrix => {
    m = m.clone();
    for (let j = 0; j < m.columnCount; ++j) {
        const column = m.getColumn(j);
        if (column.some(c => c == null)) {
            if (column.every(c => c == null)) {
                for (let i = 0; i < m.rowCount; ++i) {
                    m.set(1, i, j);
                }
            } else {
                const averageGrade = sum(column) / column.filter(c => c != null).length;
                for (let i = 0; i < m.rowCount; ++i) {
                    if (column[i] == null) {
                        m.set(averageGrade, i, j);
                    }
                }
            }
        }
    }
    return m;
}

export const withDefaultWeights = (m: Matrix): Matrix => {
    const data: Array<number | undefined> = [];
    const columns = m.getColumns();
    const defaults = columns.map(c => {
        const qualities = c.filter(x => x !== undefined);
        if (qualities.length === 0) {
            return 1; // if none of the evaluations by the user have been reviewed than all qualities are 1
        } else {
            return sum(qualities) / qualities.length; // if some of the evaluations are not yet reviewed than default is the mean of reviewed ones
        }
    });
    m.forEach((x, i, j, k) => {
        if (x === undefined) {
            data.push(defaults[j]);
        } else {
            data.push(x);
        }
    });
    return new Matrix(data, m.columnCount);
};
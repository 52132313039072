import React from 'react';
import {createStyles, Grid, Input, makeStyles, Slider, Theme, Typography} from '@material-ui/core';
import {NumericFieldStore} from '../../common';
import {observer} from 'mobx-react';

export interface ISliderWithInputProps {
    field: NumericFieldStore,
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    input: {
        width: 100
    },
}));

export const SliderWithInput: React.FC<ISliderWithInputProps> = observer(props => {
    const classes = useStyles(props);
    const {field} = props;
    const {value, label} = field;
    const onSliderChange = () => {
    };
    const onInputChange = () => {
    };
    const onInputBlur = () => {
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                <Typography>{label}</Typography>
            </Grid>
            <Grid item xs>
                <Slider
                    value={typeof value === 'number' ? value : 0}
                    onChange={onSliderChange}
                    aria-labelledby="input-slider"
                />
            </Grid>
            <Grid item>
                <Input
                    className={classes.input}
                    value={value}
                    margin="dense"
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    inputProps={{
                        step: 10,
                        min: 0,
                        max: 100,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                    }}
                />
            </Grid>
        </Grid>
    );
});
import * as React from 'react';
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    TextField,
    Theme
} from '@material-ui/core';
import {observer} from 'mobx-react';
import {ImportDialogStore} from './ImportDialogStore';

export interface IImportDialogProps {
    store: ImportDialogStore;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    dialog: {},
}));

// TODO unify with students import dialog
export const ImportDialog: React.FC<IImportDialogProps> = observer(props => {
    const {store} = props;
    const classes = useStyles(props);

    const {
        isOpen,
        text,
        isValid,
        helperText,
        canSave
    } = store;

    const onCancel = () => {
        store.cancel();
    };

    const onSave = () => {
        store.save();
    };

    const onTextChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        store.setText(e.target.value);
    };

    const onImportTextKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Tab' && !e.shiftKey && !e.altKey && !e.ctrlKey) {
            const newText = [store.text, '\t'].join('');
            store.setText(newText);
            e.preventDefault();
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onCancel}
            className={classes.dialog}
            fullWidth={true}
        >
            <DialogTitle>Import</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Copy paste from Excel to the field below
                </DialogContentText>
                <TextField
                    multiline={true}
                    rowsMax={10}
                    value={text}
                    onChange={onTextChange}
                    id='importText'
                    fullWidth={true}
                    helperText={helperText}
                    error={!isValid}
                    inputProps={{
                        onKeyDown: onImportTextKeyDown
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color='primary' data-automation='Cancel'>
                    Cancel
                </Button>
                <Button
                    data-automation='Save'
                    disabled={!canSave}
                    onClick={onSave}
                    color='primary'
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
});
import React from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    createStyles,
    IconButton,
    makeStyles,
    Theme
} from '@material-ui/core';
import {observer} from 'mobx-react';
import {Star as StarIcon} from '@material-ui/icons';
import {AnnotationTextArea} from '../../../common';
import {useStore} from '../../../storeProvider';
import {ConceptButtons} from '../../../common';
import {SliderFieldView} from '../../../common';
import CloseIcon from '@material-ui/icons/ArrowDownward';

export interface IReviewCardProps {
    isMobile: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
    },
    cardActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    qualitySlider: {
        paddingRight: theme.spacing(2),
    },
    closeButton: {},
    eraseAnnotations: {
        marginRight: theme.spacing(1),
    },
    annotationContainer: {
        maxHeight: 300,
        overflow: 'auto',
    }
}));

export const ReviewCard: React.FC<IReviewCardProps> = observer(props => {
    const {resultsStore} = useStore();
    const {detailedViewStore} = resultsStore;
    const {evaluation, evaluationTextModel} = detailedViewStore;

    const evaluator = evaluation ? evaluation.evaluator!.user!.name : 'Unknown';
    const evaluated = evaluation ? evaluation.evaluated!.user!.name : 'Unknown';
    const grade = evaluation ? String(evaluation.grade) : 'No grade';
    const subheader = `by ${evaluator}`;
    const classes = useStyles(props);

    const concepts = resultsStore.projectConcepts.map(c => c.concept!.name!);

    const onClose = () => {
        detailedViewStore.close();
    };

    const onEraseAnnotations = () => {
        detailedViewStore.eraseAnnotations();
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                title={evaluated}
                subheader={subheader}
                avatar={
                    <Chip
                        icon={<StarIcon/>}
                        label={String(grade)}
                    />
                }
                action={
                    <div className={classes.cardActions}>
                        <SliderFieldView
                            field={detailedViewStore.quality}
                            className={classes.qualitySlider}
                        />
                        <Button
                            className={classes.eraseAnnotations}
                            variant='outlined'
                            onClick={onEraseAnnotations}
                        >
                            Erase Annotations
                        </Button>
                        {props.isMobile ? null : (
                            <IconButton
                                onClick={onClose}
                                className={classes.closeButton}
                            >
                                <CloseIcon/>
                            </IconButton>
                        )}
                    </div>
                }
            >
            </CardHeader>
            <CardContent>
                <div className={classes.annotationContainer}>
                    <AnnotationTextArea
                        annotationText={evaluationTextModel}
                        concepts={concepts}
                        disabled={false}
                    />
                </div>
                <ConceptButtons
                    annotationText={evaluationTextModel}
                    concepts={concepts}
                    disabled={false}
                />
            </CardContent>
        </Card>
    );
});
import {action, computed, decorate, observable} from 'mobx';
import {Student} from '../../model/Student';
import {studentsCsvParser} from './studentsCsvParser';
import {help} from 'typescript-logging';

export interface ImportDialogResult {
    isOk: boolean;
    students: Student[];
}

export class ImportDialogStore {
    private _isOpen = observable.box<boolean>(false);
    private _text = observable.box<string>('');
    private _helperText = observable.box<string>('');
    private _isValid = observable.box<boolean>(true);
    private _students = observable.array<Student>();

    private _promise?: Promise<ImportDialogResult>;
    private _resolve?: (x: ImportDialogResult) => void;

    public get isOpen(): boolean {
        return this._isOpen.get();
    }

    public get isValid(): boolean {
        return this._isValid.get();
    }

    public get helperText(): string {
        return this._helperText.get();
    }

    public get text(): string {
        return this._text.get();
    }

    public get canSave(): boolean {
        return this.isValid && this._students && this._students.length > 0;
    }

    public open = async (): Promise<ImportDialogResult> => {
        if (this.isOpen) {
            return this._promise!;
        }
        this._promise = new Promise(resolve => {
            this._resolve = resolve;
        });
        this._isOpen.set(true);
        return this._promise;
    };

    public setText = (text: string) => {
        this._text.set(text);
        try {
            const [students, errors] = studentsCsvParser(text);
            this._isValid.set(true);
            const helperText: string[] = [];
            helperText.push(`${students.length} students successfully parsed. `);
            helperText.push(`Press Save to add them to the project.`);
            if (errors.length > 0) {
                helperText.push(`Warnings:`);
                errors.forEach(error => helperText.push(error));
            }
            this._helperText.set(helperText.join('\n'));
            this._students.replace(students);
        } catch (e) {
            this._isValid.set(false);
            this._helperText.set(e.message);
            this._students.replace([]);
        }
    };

    public cancel = () => {
        if (!this.isOpen) {
            return;
        }
        this._resolve!({
            isOk: false,
            students: [],
        });
        this._resolve = undefined;
        this._promise = undefined;
        this._isOpen.set(false);
    };

    public save = () => {
        if (!this.isOpen) {
            return;
        }
        this._resolve!({
            isOk: true,
            students: this._students.toJS(),
        });
        this._resolve = undefined;
        this._promise = undefined;
        this._isOpen.set(false);
    };
}

decorate(ImportDialogStore, {
    isOpen: computed,
    cancel: action,
    canSave: computed,
    helperText: computed,
    isValid: computed,
    text: computed,
    open: action,
    save: action,
    setText: action,
});
import * as React from 'react';
import * as _ from 'lodash';
import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import {getAttribute} from '../../getAttribute';
import clsx from 'clsx';
import {AnnotationTextModel, Sentence} from './AnnotationTextModel';
import {observer} from 'mobx-react-lite';
import {AnnotationPalette} from './AnnotationPalette';

export interface IAnnotationTextAreaProps {
    annotationText: AnnotationTextModel;
    concepts: string[]; // all concept options
    disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    wordContainer: {
        paddingTop: 2,
        paddingBottom: 2,
        cursor: 'pointer'
    },
    editableWordContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        cursor: 'pointer'
    },
    word: {
        paddingRight: '.25em',
        userSelect: 'none',
    },
    editableWord: {
        cursor: 'pointer'
    },
    selectedSentence: {
        backgroundColor: theme.palette.grey.A100
    }
}));

export const AnnotationTextArea: React.FC<IAnnotationTextAreaProps> = observer(props => {
    const {annotationText} = props;
    const {sentences} = annotationText;
    const classes = useStyles(props);

    const onSentenceClick: React.MouseEventHandler<HTMLDivElement> = (e: React.MouseEvent) => {
        if (props.disabled) {
            return;
        }
        const sentenceIndexString = getAttribute(e.target as Element, 'sentence-index');
        const sentenceIndex = parseInt(sentenceIndexString, 10);
        props.annotationText.toggleSelection(sentenceIndex);
    };

    const renderSentence = (sentence: Sentence, sentenceIndex: number) => {
        const {disabled} = props;
        const {text, isSelected, concepts} = sentence;
        const wordClassName = clsx(classes.word, {
            [classes.editableWord]: !disabled,
        });
        const containerClassNames = clsx({
            [classes.selectedSentence]: isSelected,
            [classes.editableWordContainer]: !disabled,
            [classes.wordContainer]: disabled,
        });
        const words = _.split(text, /\s/);
        const style = AnnotationPalette.getWordStyle(concepts, props.concepts);
        const sentenceProps = disabled ? {} : {onClick: onSentenceClick};
        return (
            <React.Fragment key={`Sentence${sentenceIndex}`}>
                {words.map((word, wordIndex) => (
                    <div
                        key={`${sentenceIndex}_${wordIndex}`}
                        sentence-index={`${sentenceIndex}`}
                        className={containerClassNames}
                        {...sentenceProps}
                    >
                        <Typography
                            key={`S${sentenceIndex}W${wordIndex}`}
                            className={wordClassName}
                            style={style}
                        >
                            {word}
                        </Typography>
                    </div>
                ))}
            </React.Fragment>
        );
    };

    return (
        <div className={classes.root}>
            {sentences.map((sentence, sentenceIndex) => renderSentence(sentence, sentenceIndex))}
        </div>
    );
});

import React from 'react';
import {createStyles, IconButton, makeStyles, Theme} from '@material-ui/core';
import {
    ArrowBack as BackIcon,
    CloudDownload as DownloadIcon,
    Settings as SettingsIcon,
    SvgIconComponent
} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => createStyles({
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
}));

export interface IAppBarButtonProps {
    icon: SvgIconComponent;
    onClick?: () => void;
}

export const AppBarButton: React.FC<IAppBarButtonProps> = props => {
    const classes = useStyles(props);
    const Icon = props.icon;
    return (
        <IconButton
            className={classes.menuButton}
            color='inherit'
            aria-label='Menu'
            onClick={props.onClick}
        >
            <Icon/>
        </IconButton>
    );
};

export interface ISpecificAppBarButtonProps {
    onClick?: () => void;
}

export const SettingsButton: React.FC<ISpecificAppBarButtonProps> = props =>
    <AppBarButton
        icon={SettingsIcon}
        onClick={props.onClick}
    />;

export const BackButton: React.FC<ISpecificAppBarButtonProps> = props =>
    <AppBarButton
        icon={BackIcon}
        onClick={props.onClick}
    />;

export const DownloadButton: React.FC<ISpecificAppBarButtonProps> = props =>
    <AppBarButton
        icon={DownloadIcon}
        onClick={props.onClick}
    />;
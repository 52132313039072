import {ApiFactory} from '../../common';
import {
    IFindUserRequestPayload,
    IFindUserReplyPayload,
    IInstructorsRequestPayload,
    IInstructorsReplyPayload,
    IAddInstructorsRequestPayload,
    IAddInstructorsReplyPayload, IRemoveInstructorsRequestPayload, IRemoveInstructorsReplyPayload
} from '../../dtos';
import {Urls} from '../../shared';

export class InstructorsApi {
    constructor(private readonly apiFactory: ApiFactory) {
    }

    public findUser = this.apiFactory.createApiFunc<IFindUserRequestPayload, IFindUserReplyPayload>(Urls.findUser);
    public fetchInstructors = this.apiFactory.createApiFunc<IInstructorsRequestPayload, IInstructorsReplyPayload>(Urls.fetchInstructors);
    public addInstructors = this.apiFactory.createApiFunc<IAddInstructorsRequestPayload, IAddInstructorsReplyPayload>(Urls.addInstructors);
    public removeInstructors = this.apiFactory.createApiFunc<IRemoveInstructorsRequestPayload, IRemoveInstructorsReplyPayload>(Urls.removeInstructors);
}
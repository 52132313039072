import {action, computed, decorate, observable} from 'mobx';
import {RootStore} from '../../RootStore';
import {SnackMessageStore, SnackType} from '../../common/components';
import {AnalyticsApi} from './AnalyticsApi';
import {IAnalyticsDataReplyPayload} from '../../dtos';

export class AnalyticsScreenStore {
    private _data = observable.box<IAnalyticsDataReplyPayload>();
    private _isBusy = observable.box<boolean>(false);
    private _projectId = observable.box<number | undefined>(undefined);

    public constructor(
        public rootStore: RootStore,
        private readonly api: AnalyticsApi,
        public readonly snack: SnackMessageStore) {
    }

    public get isBusy(): boolean {
        return this._isBusy.get();
    }

    public get projectId(): number | undefined {
        return this._projectId.get();
    }

    public setProjectId = (projectId: number | undefined) => {
        this._projectId.set(projectId);
    }

    public get data(): IAnalyticsDataReplyPayload {
        return this._data.get();
    }

    public goBack = () => {
        this.rootStore.routerStore.goBack();
    }

    public fetchScreenData = async (): Promise<void> => {
        this._isBusy.set(true);
        const projectId = this.projectId;
        if (projectId === undefined) {
            return;
        }
        try {
            const reply = await this.api.fetchScreenData({projectId});
            this._data.set(reply);
            this.snack.open(`Analytics loaded`, SnackType.Success);
        } catch (e) {
            this.snack.open(`Failed to load analytics ${e.message}`, SnackType.Error);
        } finally {
            this._isBusy.set(false);
        }
    }
}

decorate(AnalyticsScreenStore, {
    data: computed,
    isBusy: computed,
    projectId: computed,
    setProjectId: action,
    goBack: action,
});
import {action, computed, decorate, observable} from 'mobx';
import React from 'react';

export class RowDefinition<T> {
    public readonly id: string;
    private readonly _data = observable.box<T>();
    private readonly _isSelected = observable.box<boolean>(false);
    private readonly _alteration = observable.box<boolean>(false);
    private readonly _cursor = observable.box<number | undefined>(undefined);
    public style?: React.CSSProperties;

    constructor(id: string, data: T) {
        this.id = id;
        this._data.set(data);
    }

    public get data(): T {
        return this._data.get();
    }

    public setData = (data: T) => {
        this._data.set(data);
    }

    public get isSelected(): boolean {
        return this._isSelected.get();
    }

    public get cursor(): number | undefined {
        return this._cursor.get();
    }

    public get alteration(): boolean {
        return this._alteration.get();
    }

    public select = (isSelected: boolean) => {
        this._isSelected.set(isSelected);
    };

    public setAlteration = (alteration: boolean) => {
        this._alteration.set(alteration);
    };

    public setCursor = (columnIndex: number | undefined) => {
        this._cursor.set(columnIndex);
    }

    public toString = () => {
        const s = [`id: ${this.id}`];
        const data = this.data;
        if (data) {
            s.push(`data: ${String(data)}`);
        }
        return s.join(', ');
    }
}

decorate(RowDefinition, {
    data: computed,
    setData: action,
    isSelected: computed,
    cursor: computed,
    select: action,
});
import React, {useEffect, useMemo} from 'react';
import {AppBar, Button, createStyles, makeStyles, Theme, Toolbar, Typography, useMediaQuery} from '@material-ui/core';
import {observer} from 'mobx-react';
import {UserAppBarButton} from '../auth';
import {useStore} from '../../storeProvider';
import {BackButton, Loading, SnackMessage} from '../../common';
import {RouteComponentProps} from 'react-router';
import {EvaluationCardList} from './EvaluationCardsList';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    flex: {
        flex: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    mobileNotification: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

export interface IEvaluationScreenRouteParams {
    projectId: string;
}

export interface IEvaluationScreenProps extends RouteComponentProps<IEvaluationScreenRouteParams> {
}

export const EvaluationScreen: React.FC<IEvaluationScreenProps> = observer(props => {
    const classes = useStyles(props);
    const {evaluationScreenStore, authStore} = useStore();
    const projectId = useMemo(() => parseInt(props.match.params.projectId, 10), [props.match.params.projectId]);

    useEffect(() => {
        if (!isNaN(projectId) && authStore.isRehydrated) {
            evaluationScreenStore.setId(projectId);
            evaluationScreenStore.fetchScreenData();
        }
    }, [authStore.isRehydrated, evaluationScreenStore, projectId]);

    const onSubmit = () => {
        evaluationScreenStore.submit();
    };

    const onBack = () => {
        evaluationScreenStore.goBack();
    };

    const isMobile = !useMediaQuery('(min-width:1000px)');
    const team = evaluationScreenStore.team;
    const title: string[] = [];
    title.push(`Evaluation`);
    if (team && !isMobile) {
        title.push(` - Team ${team.name} in project ${team.project!.name}`);
    }

    return (
        <div className={classes.root}>
            <AppBar position='static' color='primary'>
                <Toolbar>
                    <BackButton onClick={onBack}/>
                    <Typography variant='h6' color='inherit' className={classes.flex}>
                        {title.join('')}
                    </Typography>
                    <UserAppBarButton/>
                </Toolbar>
            </AppBar>
            {isMobile && team ? (
                <Typography variant={'body1'} className={classes.mobileNotification}>
                    {`Team ${team.name} in project ${team.project!.name}`}
                </Typography>
            ) : null}
            <EvaluationCardList/>
            <div className={classes.footer}>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={onSubmit}
                    disabled={evaluationScreenStore.isReadonly}
                >
                    Submit
                </Button>
            </div>
            <SnackMessage snackMessageStore={evaluationScreenStore.snack}/>
            <Loading isOpen={evaluationScreenStore.isBusy}/>
        </div>
    );
});